import { useQuery } from '@apollo/react-hooks'
import { PROJECT_QUERY } from './queries'

type uuid = string

const useProjectData = (projectId: uuid) => {
  const { data: queryData, loading, error } = useQuery(PROJECT_QUERY, {
    variables: { projectId }
  })

  const project = queryData?.projectById ?? null

  return { data: { project }, loading, error }
}

export default useProjectData
