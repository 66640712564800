import gql from 'graphql-tag'

const WEBHOOK_FIELDS_FRAGMENT = gql`
  fragment webhookFields on Webhook {
    id
    event
    projectId
    cycleId
    payloadUrl
    secret
    isActive
  }
`

export const CREATE_WEBHOOK_MUTATION = gql`
  mutation CreateWebhook(
    $projectId: UUID!
    $event: String!
    $cycleId: String
    $payloadUrl: String!
    $secret: String
  ) {
    createWebhook(
      input: {
        webhook: {
          projectId: $projectId
          event: $event
          cycleId: $cycleId
          payloadUrl: $payloadUrl
          secret: $secret
        }
      }
    ) {
      webhook {
        ...webhookFields
      }
    }
  }
  ${WEBHOOK_FIELDS_FRAGMENT}
`

export const EDIT_WEBHOOK_MUTATION = gql`
  mutation EditWebhook(
    $id: UUID!
    $projectId: UUID!
    $event: String!
    $cycleId: String
    $payloadUrl: String!
    $secret: String
  ) {
    updateWebhookById(
      input: {
        id: $id
        patch: {
          projectId: $projectId
          event: $event
          cycleId: $cycleId
          payloadUrl: $payloadUrl
          secret: $secret
        }
      }
    ) {
      webhook {
        ...webhookFields
      }
    }
  }
  ${WEBHOOK_FIELDS_FRAGMENT}
`

export const WEBHOOK_DETAILS_QUERY = gql`
  query WebhookDetails($webhookId: UUID!) {
    webhookById(id: $webhookId) {
      ...webhookFields
      webhookDeliveries(orderBy: CREATED_AT_DESC, first: 10) {
        nodes {
          event
          attemptNumber
          latestStatus
          updatedAt
        }
      }
    }
  }
  ${WEBHOOK_FIELDS_FRAGMENT}
`

export const WEBHOOK_LISTING_QUERY = gql`
  query WebhookListing($projectId: UUID!) {
    webhooks(filter: { projectId: { equalTo: $projectId } }) {
      nodes {
        ...webhookFields
        webhookDeliveries(orderBy: CREATED_AT_DESC, first: 1) {
          nodes {
            latestStatus
          }
        }
      }
    }
  }
  ${WEBHOOK_FIELDS_FRAGMENT}
`

export const DELETE_WEBHOOK_MUTATION = gql`
  mutation DeleteWebhook($id: UUID!) {
    deleteWebhookById(input: { id: $id }) {
      clientMutationId
    }
  }
`
