import { createContext } from 'react'

import { FlashMessage } from './index'

interface Context {
  getFlashMessage: () => FlashMessage
  setFlashMessage: (arg1: string, arg2?: string) => void
  clearFlashMessage: () => void
}

const FlashContext = createContext({} as Context)

export default FlashContext
