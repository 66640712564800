import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { useFlash } from 'common/flash'
import { useLocation, useParams } from 'common/location'
import { confirmAction } from 'common/utils'
import { updateQueryAndFetch } from 'common/route-utils'
import { offsetForPage, PAGE_SIZE } from 'common/pagination'

import DocumentList from '../components/document-list'
import { PROJECT_QUERY } from '../../queries'
import { DOCUMENTS_LISTING_QUERY, DELETE_DOCUMENT_MUTATION } from '../queries'

const buildVariables = query => {
  const page = Number(query.page || 1)

  return {
    offset: offsetForPage(page),
    ...(query && {
      categoryId: query.documentCategory,
      isUsingCategoryId: !!query.documentCategory,
      annotationId: query.annotationCategory,
      isUsingAnnotationId: !!query.annotationCategory
    })
  }
}

const ListingPage = ({ project, assignees, navigate }) => {
  const params = useParams()
  const location = useLocation()

  const { setFlashMessage } = useFlash()

  const { data, error, networkStatus } = useQuery(DOCUMENTS_LISTING_QUERY, {
    variables: {
      datasetId: project.projectToDatasets.nodes[0].dataset.id,
      first: PAGE_SIZE,
      ...buildVariables(params)
    }
  })

  const refetchQueries = [
    {
      query: DOCUMENTS_LISTING_QUERY,
      variables: {
        datasetId: project.projectToDatasets.nodes[0].dataset.id,
        first: PAGE_SIZE,
        ...buildVariables(params)
      }
    },
    { query: PROJECT_QUERY, variables: { projectId: project.id } }
  ]

  const [deleteDocument] = useMutation(DELETE_DOCUMENT_MUTATION, { refetchQueries })

  if (error) {
    return <div>error...</div>
  }

  // networkStatus=1 indicates initial load, using this instead of `loading` to prevent flicker when paging/filtering
  if (networkStatus === 1) {
    return <div>loading...</div>
  }

  const page = Number(params.page || 1)
  const documents = data.documents.nodes
  const documentCount = data.documents.totalCount

  const handleDeleteDocument = document => {
    confirmAction(
      'Are you sure you want to delete this document? This will delete any annotations/classifications for the document.'
    )(async () => {
      await deleteDocument({ variables: { documentId: document.id } })
      setFlashMessage(`Document (${document.externalId}) was deleted successfully.`)
    })
  }

  return (
    <DocumentList
      project={project}
      query={params}
      onPageChange={page => {
        const query = { ...params, page }
        updateQueryAndFetch(location, navigate, query)
      }}
      refetchQueries={refetchQueries}
      onFiltersChange={filters => {
        const query = filters || {}
        updateQueryAndFetch(location, navigate, query)
      }}
      onDeleteDocument={handleDeleteDocument}
      metadata={{
        currentPage: page,
        totalPages: Math.ceil(documentCount / PAGE_SIZE),
        totalCount: documentCount
      }}
      documents={documents}
    />
  )
}

export default ListingPage
