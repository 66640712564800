import React from 'react'

const Ellipsize = ({ maxLength, value }) => {
  // NOTE: this relies on react-tooltip being instantiated, which it is in label-listing.tsx

  if (value.length <= maxLength) {
    return value
  }

  let trimmed = value.substr(0, maxLength)
  if (trimmed.lastIndexOf(' ') !== -1) {
    trimmed = trimmed.substr(0, Math.min(trimmed.length, trimmed.lastIndexOf(' ')))
  }

  return (
    <span data-tip={value}>
      {trimmed}
      ...
    </span>
  )
}

export default Ellipsize
