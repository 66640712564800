import uuid from 'uuid/v4'

// NOTE: these 3 functions are closely coupled

export interface TransformedContents {
  id: string
  type: any
  value: string
}

// a transformed representation of the annotation that our annotator component finds easier
type uuid = string
export interface ComponentAnnotation {
  id: uuid
  categoryId: uuid // nullable if pending annotation
  selectedText: string
  selector: {
    elementId: string
    offset: number
    length: number
  }
}

export interface ApiAnnotation {
  categoryId: string
  selectedText: string
  start: number
  end: number
}



export const transformContents = contents =>
  contents.split('\n').map((fragment, id) => ({
    id: `${id}`,
    value: fragment
  }))

export const transformApiAnnotationToComponentAnnotation = (document, annotation: ApiAnnotation) : ComponentAnnotation => {
  const chunks = document.split('\n')
  let offset = annotation.start
  let elementId = 0
  chunks.find(c => {
    if (offset < c.length) { return true }
    offset -= (c.length + 1) // + 1 is because we've lost newline chars by splitting with them
    elementId += 1
    return false // not actually used, just in place to silence linter
  })

  return {
    id: uuid(), // used to identify the annotation to remove
    categoryId: annotation.categoryId,
    selectedText: annotation.selectedText,
    selector: {
      elementId: elementId.toString(),
      offset,
      length: annotation.end - annotation.start
    }
  }
}

export const transformComponentAnnotationToApiAnnotation = (document, annotation: ComponentAnnotation) : ApiAnnotation => {
  const chunks = document.split('\n')
  let start = 0
  const elementId = Number(annotation.selector.elementId)
  for (let i = 0; i < elementId; i++) {
    start += chunks[i].length + 1 // + 1 is because we've lost newline chars by splitting with them
  }
  start += annotation.selector.offset
  return {
    start,
    end: start + annotation.selector.length,
    categoryId: annotation.categoryId,
    selectedText: annotation.selectedText
  }
}
