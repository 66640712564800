import React, { useState, useRef } from 'react'

import { FlashMessage } from './index'
import FlashContext from './context'

const emptyFlashMessage: FlashMessage = { message: null, type: null }

const FlashProvider = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, forceRerender] = useState({})
  const flashMessage = useRef(emptyFlashMessage)

  const setFlashMessage = (message: string, type = 'success') => {
    flashMessage.current = { message, type }
    forceRerender({})
  }

  const clearFlashMessage = () => {
    flashMessage.current = emptyFlashMessage
  }

  const getFlashMessage = () => {
    const message = flashMessage.current
    clearFlashMessage()
    return message
  }

  return (
    <FlashContext.Provider
      {...props}
      value={{
        getFlashMessage,
        setFlashMessage,
        clearFlashMessage
      }}
    />
  )
}

export default FlashProvider
