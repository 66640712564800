import React, { FC, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { ProductType, Plan } from 'common/types'
import { FlashSuccess, FlashInfo } from 'common/flash'
import { CURRENT_USER_PROFILE_QUERY } from 'common/profile'

interface CheckoutCompleteBannerProps {
  plan: Plan
  checkoutSessionId: string
}

const SYNC_CHECKOUT_SESSION_MUTATION = gql`
  mutation SyncCheckoutSessionMutation($checkoutSessionId: String!) {
    syncStripeCheckoutSession(input: { checkoutSessionId: $checkoutSessionId }) {
      checkoutSessionId
    }
  }
`

const CheckoutCompleteBanner: FC<CheckoutCompleteBannerProps> = ({
  plan,
  checkoutSessionId
}) => {
  const [triggerPlanManualRefresh] = useMutation(SYNC_CHECKOUT_SESSION_MUTATION, {
    variables: { checkoutSessionId },
    refetchQueries: [{ query: CURRENT_USER_PROFILE_QUERY }]
  })

  const handlePlanResync = async () => {
    await triggerPlanManualRefresh()
  }

  useEffect(() => {
    if (plan.planType === ProductType.TRIAL) {
      handlePlanResync()
    }
    // eslint-disable-next-line
  }, [plan.planType, checkoutSessionId])

  return (
    <div className='mb4'>
      {plan.planType === ProductType.TRIAL ? (
        <FlashInfo>
          <p className='mt0'>
            There was an error during account sync, attempting to re-sync your account
            with our payment provider...
          </p>
          <p className='mb0 b'>
            Do not attempt to re-submit payment, as this notification indicates you were
            charged successfully.
          </p>
          <p>
            If the problem persists, please contact support at{' '}
            <a className='link underline pointer blue' href='mailto:support@markers.ai'>
              support@markers.ai
            </a>{' '}
            and we'll respond ASAP.
          </p>
        </FlashInfo>
      ) : (
        <FlashSuccess>
          Thank you for subscribing! You are now subscribed to the{' '}
          <strong>{plan.planType.toLowerCase()}</strong> plan billed{' '}
          <strong>{plan.billingCycle.toLowerCase()}</strong>.
        </FlashSuccess>
      )}
    </div>
  )
}

export default CheckoutCompleteBanner
