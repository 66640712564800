import React, { useContext, createContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { isPast } from 'date-fns'
import gql from 'graphql-tag'

import { Plan } from 'common/types'

export const CURRENT_USER_PROFILE_QUERY = gql`
  query CurrentUserProfile {
    currentUserProfile {
      nodeId
      id
      name
      email
      lastLogin
      permissions
    }
    currentTenantPlan {
      planType
      billingCycle
      expiry
      planQuota
      autoRenew
    }
  }
`

export const useProfileService = () => {
  const { data, refetch } = useContext(ProfileContext)

  const isPlanExpired = data.plan ? isPast(data.plan.expiry) : false

  return {
    isPlanExpired,
    profile: data.profile,
    plan: data.plan as Plan,
    reloadProfile: refetch
  }
}

type ProfileState = { plan: any; profile: any }

interface Context {
  data: ProfileState
  refetch: any
}

const ProfileContext = createContext({} as Context)

export const ProfileProvider = props => {
  // NOTE: this should only be used inside an authenticated tree
  const { data, loading, error, refetch } = useQuery(CURRENT_USER_PROFILE_QUERY)

  if (loading) return <div>loading...</div>
  if (error) return <div>error loading profile</div>

  const { currentTenantPlan: plan, currentUserProfile: profile } = data
  const profileData = {
    profile,
    plan: {
      ...plan,
      expiry: new Date(plan.expiry)
    }
  }

  return <ProfileContext.Provider {...props} value={{ data: profileData, refetch }} />
}
