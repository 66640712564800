import React from 'react'
import 'twin.macro'
import { Form, Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { Button, ButtonContainer } from 'common/components'
import { FreeTextField, ReactSelectField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

interface AddAnnotationFormProps {
  categories: any
  onSubmit(arg1: any): any
}

const AddAnnotationForm = ({ categories, onSubmit }: AddAnnotationFormProps) => {
  const [isNotesVisible, setIsNotesVisible] = React.useState(false)

  return (
    <Formik
      initialValues={{ category: null, notes: '' }}
      onSubmit={buildSubmitHandler(onSubmit, {
        reshape: ({ category, notes }) => ({ id: category.id, notes }),
        unmountsAfterSubmit: true
      })}
    >
      {({ handleChange, setFieldValue, touched, values, isSubmitting }) => (
        <Form>
          <ReactSelectField
            name='category'
            placeholder='Select category'
            value={values.category}
            onChange={category => setFieldValue('category', category)}
            data-cy='add-annotation--field'
            selectProps={{
              autoFocus: true,
              getOptionLabel: opt => opt.name,
              filterOption: ({ data }, str) => data.name && data.name.includes(str)
            }}
            containerProps={{
              'data-cy': 'add-annotation--select'
            }}
            options={categories}
          />

          {isNotesVisible && (
            <div tw='mt-4'>
              <FreeTextField
                name='notes'
                label='Notes'
                tw='mt-4'
                onChange={handleChange}
                touched={touched.notes}
                value={values.notes}
                isOptional={true}
              />
            </div>
          )}

          <ButtonContainer>
            {isNotesVisible ? (
              <Button variant='tertiary-gray' onClick={() => setIsNotesVisible(false)}>
                Hide notes
              </Button>
            ) : (
              <Button variant='tertiary-gray' onClick={() => setIsNotesVisible(true)}>
                Add notes
              </Button>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Add annotation'}
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

interface AddAnnotationPopupProps {
  categories: any
  onSave(arg1: any): any
  onCancel(arg1: any): any
  style: React.CSSProperties
}

const AddAnnotationPopup = React.forwardRef(
  (
    { categories, onSave, onCancel, style }: AddAnnotationPopupProps,
    ref: React.Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      style={style}
      data-cy='add-annotation'
      tw='bg-white p-3 w-96 whitespace-nowrap mt-2 border border-gray-300 shadow'
      onMouseUp={e => e.stopPropagation()}
    >
      <div tw='flex flex-row justify-between mb-4'>
        <h4 tw='mt-0 flex-1 font-bold'>Create annotation</h4>

        <div tw='cursor-pointer flex-none' onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} size='lg' />
        </div>
      </div>
      <AddAnnotationForm categories={categories} onSubmit={onSave} />
    </div>
  )
)

export default AddAnnotationPopup
