import React from 'react'
import tw from 'twin.macro'
import chroma from 'chroma-js'

import { Button, ButtonContainer } from 'common/components'

const H3 = tw.h3`
  font-bold border-b border-black mb-4 mt-4
`

const AnnotationInstructions = ({ project, onClose: handleClose }) => (
  <>
    <H3 tw='mt-0'>Annotation instructions</H3>
    <div dangerouslySetInnerHTML={{ __html: project.instructions }} />

    <H3 tw='border-b-0'>Document labels</H3>
    <dl>
      {project.documentCategories.nodes.map((category, index) => (
        <React.Fragment key={index}>
          <dt tw='font-bold inline-block'>{category.name}</dt>
          <dd tw='pl-3 mb-2'>{category.description}</dd>
        </React.Fragment>
      ))}
    </dl>

    <H3 tw='border-b-0'>Annotation labels</H3>
    <dl>
      {project.fragmentCategories.nodes.map((category, index) => (
        <React.Fragment key={index}>
          <dt
            tw='font-bold inline-block py-1 px-2'
            style={{ backgroundColor: chroma(category.properties.color).brighten(0.2) }}
          >
            {category.name}
          </dt>
          <dd tw='mb-2'>{category.description}</dd>
        </React.Fragment>
      ))}
    </dl>

    <ButtonContainer>
      <Button variant='secondary-gray' onClick={handleClose}>
        Close
      </Button>
    </ButtonContainer>
  </>
)

export default AnnotationInstructions
