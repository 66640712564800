import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { useAuth } from 'auth'
import { Button, ButtonContainer, LinkButton, Tag } from 'common/components'

import DataTable, {
  OnSelectionChangeArgs,
  DataTableRef
} from 'common/components/data-table'
import DocumentListingFilters from './document-listing-filters'
import AssignDocumentsModal from './assign-documents-modal'
import {
  DELETE_INCOMPLETE_TASKS_MUTATION,
  DELETE_ALL_INCOMPLETE_TASKS_MUTATION
} from '../queries'
import { ASSIGN_DOCUMENTS_MUTATION } from '../../queries'
import ControlBar from '../../components/control-bar'

const DocumentAssignee = ({ assignee: { name } }) => {
  const className = name === 'inactive' ? 'bg-washed-yellow' : 'bg-lighterest-blue'
  return (
    <Tag className={className}>
      {name !== 'inactive' ? name : 'Inactive Team Member'}
    </Tag>
  )
}

interface Document {
  tasks: any
  labels: any
  externalId: string
}

const DocumentListing = ({
  project,
  query,
  documents,
  metadata,
  onDeleteDocument,
  onPageChange,
  onFiltersChange: handleFiltersChange,
  refetchQueries = []
}) => {
  const { isAdmin } = useAuth()

  const [
    isAssignDocumentsModalVisible,
    setIsAssignDocumentsModalVisible
  ] = React.useState(false)

  const projectId = project.id

  const [assignDocuments] = useMutation(ASSIGN_DOCUMENTS_MUTATION, {
    variables: { projectId },
    refetchQueries
  })

  const [selectionState, setSelectionState] = React.useState<OnSelectionChangeArgs>({
    state: 'none',
    selectedRowIds: []
  })
  const handleSelectionChange = (selectionState: OnSelectionChangeArgs) => {
    setSelectionState(selectionState)
  }

  const totalNumberSelected =
    selectionState.state === 'all'
      ? metadata.totalCount
      : selectionState.selectedRowIds.length

  const handleSubmitAssignModal = async values => {
    const { assignees, assignmentType } = values
    const assigneeIds =
      assignmentType === 'specific' ? assignees.map(a => a.value) : null

    const variables =
      selectionState.state === 'all'
        ? {
            projectId,
            assigneeIds
          }
        : {
            projectId,
            assigneeIds,
            documentIds: selectionState.selectedRowIds
          }

    await assignDocuments({ variables })
    setIsAssignDocumentsModalVisible(false)
    deselectAllDocuments()
  }

  const [deleteAllIncompleteTasks] = useMutation(DELETE_ALL_INCOMPLETE_TASKS_MUTATION, {
    variables: { projectId },
    refetchQueries
  })
  const [deleteIncompleteTasks] = useMutation(DELETE_INCOMPLETE_TASKS_MUTATION, {
    refetchQueries
  })

  const tableRef = React.useRef<DataTableRef>(null)
  const deselectAllDocuments = () => tableRef.current.toggleAllRowsSelected(false)

  const handleDeleteTasks = async () => {
    const { selectedRowIds } = selectionState
    if (selectionState.state === 'all') {
      await deleteAllIncompleteTasks()
    } else {
      await deleteIncompleteTasks({ variables: { documentIds: selectedRowIds } })
    }
    deselectAllDocuments()
  }

  return (
    <>
      <AssignDocumentsModal
        isVisible={isAssignDocumentsModalVisible}
        onRequestClose={() => setIsAssignDocumentsModalVisible(false)}
        totalNumberSelected={totalNumberSelected}
        onSubmit={handleSubmitAssignModal}
      />

      <ControlBar
        buttons={() =>
          isAdmin() && (
            <ButtonContainer left noMargin>
              <Button
                variant='secondary-red'
                disabled={totalNumberSelected === 0}
                onClick={handleDeleteTasks}
              >
                Delete tasks
              </Button>

              <Button
                variant='secondary-blue'
                disabled={totalNumberSelected === 0}
                onClick={() => setIsAssignDocumentsModalVisible(true)}
              >
                Create tasks
              </Button>

              <LinkButton variant='secondary-blue' to='upload'>
                Upload documents
              </LinkButton>
            </ButtonContainer>
          )
        }
        filters={() => (
          <DocumentListingFilters
            query={query}
            onChange={handleFiltersChange}
            documentCategories={project.documentCategories}
            annotationCategories={project.annotationCategories}
          />
        )}
      />

      <DataTable
        data={documents as Document[]}
        columns={[
          {
            Header: 'External ID',
            accessor: (doc: Document) => doc.externalId
          },
          {
            Header: 'Task Assignees',
            accessor: (row: Document) =>
              row.tasks.nodes.map(({ assignee }, index) => (
                <DocumentAssignee key={index} assignee={assignee} />
              ))
          },
          {
            Header: '# of Labels',
            accessor: (doc: Document) => doc.labels.totalCount
          }
        ]}
        ref={tableRef}
        isSelectable
        onSelectionChange={handleSelectionChange}
        metadata={metadata}
        rowActions={
          isAdmin()
            ? [
                {
                  label: 'Remove',
                  handler: onDeleteDocument
                }
              ]
            : []
        }
        fetchData={onPageChange}
      />
    </>
  )
}

export default DocumentListing
