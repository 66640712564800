class AuthService {
  // NOTE: this class is a singleton providing auth info to apollo client and
  // should not be called outside of useAuth hook or apollo client setup

  authToken = null
  afterLogoutHook?: Function = null

  constructor () {
    this.authToken = window.localStorage.getItem('authToken')
  }

  isAuthenticated = () => !!this.authToken

  getAuthToken = () => this.authToken

  login = authToken => {
    this.authToken = authToken
    window.localStorage.setItem('authToken', authToken)
  }

  logout = apolloClient => {
    apolloClient.resetStore()
    this.authToken = null
    window.localStorage.removeItem('authToken')
    if (this.afterLogoutHook) {
      this.afterLogoutHook()
    }
  }
}

export const authInstance = new AuthService()
