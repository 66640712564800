import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { RouteComponentProps } from '@reach/router'

import { useAuth } from 'auth'
import { DisplayOnlyField } from 'common/components/form'

import ProjectSettingsForm from '../components/details-form'
import { UPDATE_PROJECT_DETAILS_MUTATION, PROJECT_QUERY } from '../../queries'
import { PageHeader } from 'common/components'

interface IProjectDetailsPage extends RouteComponentProps {
  project: any
}

const ProjectDetailsPage = ({ project, navigate }: IProjectDetailsPage) => {
  const { isAdmin } = useAuth()

  const [updateProject] = useMutation(UPDATE_PROJECT_DETAILS_MUTATION, {
    refetchQueries: [{ query: PROJECT_QUERY, variables: { projectId: project.id } }]
  })

  const handleUpdateDetails = async values => {
    const variables = { ...values, projectId: project.id }
    const { overlap, strategy, ...rest } = variables
    await updateProject({
      variables: {
        ...rest,
        settings: { overlap, strategy }
      }
    })
    navigate(`/projects/${project.id}`, {
      state: { flash: 'Project updated successfully.' }
    })
  }

  return (
    <>
      <PageHeader title='Details' />

      {isAdmin() ? (
        <ProjectSettingsForm project={project} onSubmit={handleUpdateDetails} />
      ) : (
        <>
          <DisplayOnlyField label='Project name' value={project.name} />

          <DisplayOnlyField label='Description' value={project.description} />

          <DisplayOnlyField
            label='Annotation instructions'
            html={true}
            value={project.instructions}
          />
        </>
      )}
    </>
  )
}

export default ProjectDetailsPage
