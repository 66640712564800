import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const FilterTag = ({ name, value, onRemove }) => {
  const [isRemoveHovered, setIsRemoveHovered] = React.useState(false)
  return (
    <span className={classNames(['dib f6 pa1', isRemoveHovered && 'bg-washed-yellow'])}>
      <span className='gray ttu f7'>{name}</span>{' '}
      <span className='b black'>{value}</span>
      <span
        className='ml2 pointer'
        onClick={onRemove}
        onMouseOver={() => setIsRemoveHovered(true)}
        onMouseOut={() => setIsRemoveHovered(false)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </span>
    </span>
  )
}

export default FilterTag
