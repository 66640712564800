import React, { FC } from 'react'
import classNames from 'classnames'

interface Props {
  title?: string
  actions?: any
  className?: string
}

const Tile: FC<Props> = ({
  title,
  actions: Actions = null,
  className = '',
  children
}) => {
  return (
    <div className={classNames(['ba bw1 b--light-gray bg-off-white pa3', className])}>
      {(title || Actions) && (
        <div className='flex flex-row justify-between items-baseline'>
          <h3 className='mt0'>{title}</h3>
          {Actions && (
            <div>
              <Actions />
            </div>
          )}
        </div>
      )}
      {children}
    </div>
  )
}

export default Tile
