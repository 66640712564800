import { buildTier3SubnavAndRoutes } from 'common/route-utils'

import { ProductType } from 'common/types'

import Overview from './team-overview'
import Billing from './team-billing'
import CancelSubscription from './cancel-subscription'

const TeamSettingsPage = buildTier3SubnavAndRoutes([
  { name: 'Overview', url: '/', exact: true, component: Overview },
  { name: 'Billing', url: 'billing', component: Billing },
  {
    name: 'Cancel Subscription',
    url: 'billing/cancel',
    component: CancelSubscription,
    excludeFromNav: true,
    check: ({ plan }) => plan.planType !== ProductType.TRIAL
  }
])

export default TeamSettingsPage
