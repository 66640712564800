import React, { useState, FC } from 'react'
import 'twin.macro'
import { distanceInWordsToNow } from 'date-fns'
import format from 'date-fns/format'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Plan, ProductType, BillingCycle } from 'common/types'
import { Button } from 'common/components'

import PlanSelectionForm from '../components/plan-selection-form'

const CREATE_CHECKOUT_SESSION_MUTATION = gql`
  mutation CreateCheckoutSession(
    $selectedProduct: PlanTypeEnum!
    $billingCycle: BillingCycleEnum!
  ) {
    createCheckoutSession(
      input: { selectedProduct: $selectedProduct, billingCycle: $billingCycle }
    ) {
      checkoutSessionId
    }
  }
`

interface NotSubscribedBillingPageProps {
  plan: Plan
  plans: object
}

const NotSubscribedBillingPage: FC<NotSubscribedBillingPageProps> = ({
  plan,
  plans
}) => {
  const [createCheckout] = useMutation(CREATE_CHECKOUT_SESSION_MUTATION)
  const [isSubmitting, setSubmitting] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<ProductType>(ProductType.TEAM)
  const [billingCycle, setBillingCycle] = useState<BillingCycle>(BillingCycle.YEARLY)

  const Stripe = (window as any).Stripe
  const stripe = Stripe
    ? Stripe(process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY)
    : null

  const handleSubscribeClick = async () => {
    setSubmitting(true)
    try {
      const {
        data: { createCheckoutSession }
      } = await createCheckout({
        variables: { selectedProduct: selectedPlan, billingCycle }
      })
      const { error } = stripe.redirectToCheckout({
        sessionId: createCheckoutSession.checkoutSessionId
      })
      if (error) {
        const err = new Error(
          'An unknown error occured. Please contact support@markers.ai'
        )
        // @ts-ignore
        err.originalError = error
        throw err
      }
    } catch (err) {
      console.error(err)
      // NOTE: timeout is necessary to prevent auto-dismissal of alert
      setTimeout(() => alert(err.message), 200)

      // TODO-2: report to rollbar
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className='trial'>
      <p>
        You are currently on the <strong>trial</strong> plan. Your trial will end on{' '}
        {format(plan.expiry, 'MMMM D, YYYY')} (in {distanceInWordsToNow(plan.expiry)}).
      </p>
      <div className='w-60 mt4 bg-near-white pa3 center'>
        <h3 className='mt0 tc'>Subscribe</h3>
        <PlanSelectionForm
          selectedPlan={selectedPlan}
          onPlanSelect={setSelectedPlan}
          billingFrequency={billingCycle}
          onFrequencySelect={setBillingCycle}
          plans={plans}
        />
        <Button tw='w-full' onClick={handleSubscribeClick} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Checkout'}
        </Button>
      </div>

      <p className='mt4'>
        <strong>Looking for on-prem, private cloud, SSO, or higher limits?</strong>{' '}
        <a className='link-primary' href='malito:sales@markers.ai'>
          Contact us
        </a>{' '}
        and we're happy to talk about your team's needs.
      </p>
    </div>
  )
}

export default NotSubscribedBillingPage
