import React, { FC } from 'react'
import { Link } from '@reach/router'
import classNames from 'classnames'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

interface SubnavItemProps {
  exact: boolean
  last?: boolean
  vertical?: boolean
  to: string
}

const SubnavItem: FC<SubnavItemProps> = ({
  exact,
  last = false,
  vertical = false,
  ...props
}) => {
  const sharedClasses = [
    'db mid-gray pv2 no-underline pointer',
    vertical && 'underline-hover'
  ]
  const activeClasses = vertical ? 'b' : 'b bb bw2 b--primary-color'
  const liClasses = ['nowrap self-baseline', vertical || last ? 'mr0' : 'mr4']

  return (
    <li className={classNames(liClasses)}>
      <Link
        getProps={({ isCurrent, isPartiallyCurrent }) => ({
          className: classNames([
            ...sharedClasses,
            (exact ? isCurrent : isPartiallyCurrent) && activeClasses
          ]),
          style: (exact ? isCurrent : isPartiallyCurrent) ? { color: '#000' } : {}
        })}
        {...props}
      />
    </li>
  )
}

/* class SubnavGroup extends React.Component {
 *   state = { isHovered: false }
 *   _timeout = null
 *
 *   handleMouseEnter = () => {
 *     clearTimeout(this._timeout)
 *     this.setState({ isHovered: true })
 *   }
 *
 *   handleMouseLeave = () => {
 *     this._timeout = setTimeout(() => this.setState({ isHovered: false }), 100)
 *   }
 *
 *   render () {
 *     // eslint-disable-next-line
 *     const { last = false, name, location, children } = this.props
 *     const sharedClasses = ['db mid-gray pv2 no-underline pointer']
 *     const isActive = true // location.pathname.includes(url) // TODO: fix
 *     const activeClasses = [...sharedClasses, 'b bb bw2']
 *     const liClasses = ['nowrap self-baseline relative', last ? 'mr0' : 'mr4']
 *     const { isHovered } = this.state
 *
 *     return (
 *       <li
 *         className={classNames(liClasses)}
 *         onMouseEnter={this.handleMouseEnter}
 *         onMouseLeave={this.handleMouseLeave}
 *       >
 *         <div className={classNames(isActive ? activeClasses : sharedClasses)}>
 *           {name}
 *           <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faCaretDown} />
 *         </div>
 *
 *         {isHovered && (
 *           <div
 *             className='bg-white pv3 ph3 mt2 nowrap ba b--silver'
 *             style={{ position: 'absolute', top: '100%', right: '0' }}
 *             onMouseUp={e => e.stopPropagation()}
 *           >
 *             <ul className='list ml0 pl0'>{children}</ul>
 *           </div>
 *         )}
 *       </li>
 *     )
 *   }
 * } */

class Subnav extends React.Component {
  static Item = SubnavItem
  // static Group = SubnavGroup

  render () {
    return (
      <ul
        className='list pl0 flex flex-row flex-nowrap items-baseline mb0'
        style={{ marginBottom: -1 }}
        {...this.props}
      />
    )
  }
}

export default Subnav
