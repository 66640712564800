import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useQuery } from '@apollo/react-hooks'

import { Button, ButtonContainer, PageHeader } from 'common/components'
import ModalDialog from 'common/components/modal-dialog'
import { RadioGroup, RadioOption, ReactSelectField } from 'common/components/form'
import { PROJECT_MEMBERS_QUERY } from 'users/queries'
import { buildSubmitHandler } from 'common/form-helpers'

export const PickAssigneeOptions = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  assigneeOptions
}) => {
  return (
    <>
      <RadioOption
        name='assignmentType'
        value='equal'
        title='Distribute equally amongst all team members'
        description="We'll attempt to evenly distribute tasks across your team, taking into account how many incomplete tasks your workers already have."
        isSelected={values.assignmentType === 'equal'}
        onChange={handleChange}
      />

      <RadioOption
        name='assignmentType'
        value='specific'
        title='Assign documents to specific user(s)'
        isSelected={values.assignmentType === 'specific'}
        onChange={handleChange}
      >
        <ReactSelectField
          containerProps={{ className: 'mt3' }}
          selectWrapper={props => (
            <div
              {...props}
              onClick={e => {
                // this wrapper is a hack around an event bubbling issue with react-select
                // see https://github.com/JedWatson/react-select/issues/532 for details
                if (values.assignmentType === 'specific') {
                  e.preventDefault()
                }
              }}
            />
          )}
          name='assignees'
          onChange={assignees => setFieldValue('assignees', assignees)}
          value={values.assignees}
          error={errors.assignees}
          touched={touched.assignees}
          options={assigneeOptions}
          selectProps={{
            isMulti: true
          }}
          disabled={values.assignmentType !== 'specific'}
          placeholder='Select an assignee...'
        />
      </RadioOption>
    </>
  )
}

const AssignDocumentsModalContent = ({ totalNumberSelected, onSubmit }) => {
  const { data, loading, error } = useQuery(PROJECT_MEMBERS_QUERY)

  if (loading) {
    return <div>loading team members...</div>
  }
  if (error) {
    return <div>error loading team members</div>
  }

  const assigneeOptions = data.users.nodes.map(u => ({ value: u.id, label: u.name }))

  return (
    <>
      <PageHeader title={`Assign ${totalNumberSelected} documents`} />
      <Formik
        initialValues={{ assignmentType: 'equal', assignees: [] }}
        validationSchema={yup.object().shape({
          assignmentType: yup.string().required(),
          assignees: yup.array().when('assignmentType', {
            is: 'specific',
            then: yup.array().required('You must select at least one assignee.')
          })
        })}
        onSubmit={buildSubmitHandler(onSubmit)}
      >
        {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => (
          <Form>
            <RadioGroup>
              <p className='lh-copy'>
                Assigning these documents will create labeling tasks for your team. If a
                user has already labeled a document in this set, they will not be
                assigned a new task for that document. Please select how you'd like
                these tasks to be divided.
              </p>

              <PickAssigneeOptions
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                errors={errors ?? {}}
                touched={touched}
                assigneeOptions={assigneeOptions}
              />
            </RadioGroup>

            <ButtonContainer>
              <Button type='submit' disabled={isSubmitting}>
                Create tasks
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </>
  )
}

const AssignDocumentsModal = ({ isVisible, onRequestClose, ...restProps }) => {
  return (
    <ModalDialog
      isOpen={isVisible}
      onRequestClose={onRequestClose}
      modalStyle={{ overflow: 'visible' }}
    >
      {/*
      // @ts-ignore */}
      <AssignDocumentsModalContent {...restProps} />
    </ModalDialog>
  )
}

export default AssignDocumentsModal
