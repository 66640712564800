import { buildTier3SubnavAndRoutes } from 'common/route-utils'

import ProjectDetailsPage from './pages/details'
import ProjectClasses from '../pages/project-categories'
import APIKeys from './pages/api-keys'
import Webhooks from './webhooks'

const ProjectSettingsPage = buildTier3SubnavAndRoutes([
  { name: 'Details', url: '/', exact: true, component: ProjectDetailsPage },
  { name: 'Classes', url: 'classes', component: ProjectClasses },
  { name: 'API keys', url: 'api-keys', component: APIKeys },
  { name: 'Webhooks', url: 'webhooks/*', component: Webhooks }
])

export default ProjectSettingsPage
