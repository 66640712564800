import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useQuery } from '@apollo/react-hooks'

import { Button, ButtonContainer, PageHeader } from 'common/components'
import Datetime from 'common/components/datetime'
import { RadioGroup, RadioOption } from 'common/components/form'
import ModalDialog from 'common/components/modal-dialog'
import { buildSubmitHandler } from 'common/form-helpers'

import { PickAssigneeOptions } from '../documents/components/assign-documents-modal'
import { PROJECT_MEMBERS_QUERY } from 'users/queries'

const CreateValidationTasksModal = ({
  isVisible,
  onRequestClose,
  totalNumberSelected,
  latestCycle,
  onSubmit
}) => {
  const { data, loading } = useQuery(PROJECT_MEMBERS_QUERY)

  if (loading) {
    return <div>loading...</div>
  }

  const assigneeOptions = data.users.nodes.map(u => ({ value: u.id, label: u.name }))

  return (
    <ModalDialog
      isOpen={isVisible}
      onRequestClose={onRequestClose}
      modalStyle={{ height: 630 }}
    >
      <PageHeader title={`Create ${totalNumberSelected} validation tasks`} />

      <Formik
        initialValues={{
          cycle: 'latest',
          assignmentType: 'equal',
          assignee: ''
        }}
        validationSchema={yup.object().shape({
          cycle: yup.string().required(),
          assignee: yup.object().when('assignmentType', {
            is: 'specific',
            then: yup.object().required('An assignee is required.')
          })
        })}
        onSubmit={buildSubmitHandler(onSubmit)}
      >
        {({ values, errors = {}, touched, handleChange, setFieldValue }) => (
          <Form>
            <RadioGroup label={'Add tasks to cycle'}>
              <RadioOption
                name='cycle'
                value='latest'
                title='Latest cycle'
                isSelected={values.cycle === 'latest'}
                description={() => (
                  <>
                    id: {latestCycle.id}, created:{' '}
                    <Datetime human datetime={latestCycle.createdAt} />
                  </>
                )}
                onChange={handleChange}
              />

              <RadioOption
                name='cycle'
                value='new'
                title='Create new cycle'
                isSelected={values.cycle === 'new'}
                onChange={handleChange}
              />
            </RadioGroup>

            <RadioGroup label={'Assignees'}>
              <PickAssigneeOptions
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                assigneeOptions={assigneeOptions}
              />
            </RadioGroup>

            <ButtonContainer>
              <Button type='submit'>Create tasks</Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </ModalDialog>
  )
}

export default CreateValidationTasksModal
