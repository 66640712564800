import { useApolloClient } from '@apollo/react-hooks'
import { RouteComponentProps } from '@reach/router'

import { useAuth } from 'auth'

const LogoutPage = ({ navigate }: RouteComponentProps) => {
  const { logout } = useAuth()
  const client = useApolloClient()

  logout(client)
  navigate('/', { state: { flash: 'You have been logged out.' }, replace: true })
  return null
}

export default LogoutPage
