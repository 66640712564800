import React, { FC, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { RouteComponentProps } from '@reach/router'
import gql from 'graphql-tag'

import { CURRENT_USER_PROFILE_QUERY } from 'common/profile'
import Callout from 'common/components/callout'
import Datetime from 'common/components/datetime'
import { Button, PageHeader } from 'common/components'

import { useProfileService } from 'common/profile'

const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscription($feedback: String!) {
    cancelSubscription(input: { feedback: $feedback }) {
      result
    }
  }
`

const CancelSubscriptionPage: FC<RouteComponentProps> = ({ navigate }) => {
  const { plan } = useProfileService()
  const [feedback, setFeedback] = useState('')
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_PROFILE_QUERY }]
  })

  const handleCancelSubscription = async () => {
    await cancelSubscription({ variables: { feedback } })
    navigate('..', {
      state: { flash: 'Your subscription has been canceled successfully.' }
    })
  }

  return (
    <div className='lh-copy'>
      <PageHeader title='Cancel subscription' />

      <p>
        Canceling your subscription stops all future billing. You will still have access
        to all functionality through the end of your current billing cycle,{' '}
        <strong>
          <Datetime datetime={plan.expiry} />
        </strong>
        .
      </p>
      <p>
        After that date, your account will be scheduled for cleanup and all data will be
        removed 7 days later. If you need your data deleted sooner, please{' '}
        <a className='link-primary' href='support@markers.ai'>
          contact support
        </a>
        .
      </p>

      <Callout className='w-75 mb4'>
        <label>
          <div className='mb3'>
            <strong>We'd love to hear your feedback / reasons for canceling</strong>
          </div>
          <textarea
            className='w-100 h4'
            onChange={e => setFeedback(e.target.value)}
            value={feedback}
          />
        </label>
      </Callout>

      <Button onClick={handleCancelSubscription} variant='primary-red'>
        Cancel subscription
      </Button>
    </div>
  )
}

export default CancelSubscriptionPage
