import React, { FC } from 'react'
import gql from 'graphql-tag'
import { Formik, Form } from 'formik'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { Plan, ProductType, PlanQuota } from 'common/types'
import { useFlash } from 'common/flash'
import { useProfileService } from 'common/profile'
import { Button, ButtonContainer, Link, PageHeader } from 'common/components'
import { InputField } from 'common/components/form'
import FormGroup from 'common/components/form-group'
import Datetime from 'common/components/datetime'

import ProgressMeter from 'common/components/progress-meter'
import { buildSubmitHandler } from 'common/form-helpers'

const QUOTA_USAGE_QUERY = gql`
  query QuotaUsageQuery {
    currentTenant {
      name
    }
    documents {
      totalCount
    }
    adminUsers: users(filter: { permissions: { contains: { role: "admin" } } }) {
      totalCount
    }
    annotators: users(filter: { permissions: { contains: { role: "member" } } }) {
      totalCount
    }
    labels {
      totalCount
    }
  }
`

const UPDATE_TEAM_NAME_MUTATION = gql`
  mutation UpdateTeamNameMutation($name: String!) {
    updateCurrentTenantName(input: { name: $name }) {
      tenant {
        name
      }
    }
  }
`

const buildQuotas = (quotaUsage, quotaLimits: PlanQuota) => {
  const { adminUsers, annotators, documents, labels } = quotaUsage
  return {
    admin: { used: adminUsers.totalCount, allowed: quotaLimits.admin },
    members: { used: annotators.totalCount, allowed: quotaLimits.members },
    documents: { used: documents.totalCount, allowed: quotaLimits.documents },
    labels: { used: labels.totalCount, allowed: quotaLimits.labels }
  }
}

const format = (x: number) => new Intl.NumberFormat('en-US').format(x)

interface PlanDetailsAndBillingFreqProps {
  plan: Plan
}

const PlanDetailsAndBillingFreq: FC<PlanDetailsAndBillingFreqProps> = ({ plan }) => {
  const { planType, billingCycle, expiry, planQuota } = plan

  const quotaText = `which allows up to ${format(
    planQuota.documents
  )} documents and ${format(planQuota.labels)} labels. You can provision up to ${
    planQuota.admin
  } admins and ${planQuota.members} annotators.`

  return (
    <p className='lh-copy mt0'>
      {planType === ProductType.TRIAL ? (
        <>
          You are on the <strong>trial</strong> plan (expiring{' '}
          <Datetime datetime={expiry} />
          ), {quotaText}
        </>
      ) : (
        <>
          You are on the <strong>{planType.toLowerCase()}</strong> plan billed{' '}
          <strong>{billingCycle.toLowerCase()}</strong> (renewing{' '}
          <Datetime datetime={expiry} />
          ), {quotaText}
        </>
      )}
    </p>
  )
}

const Overview = () => {
  const { setFlashMessage } = useFlash()
  const { data, loading, error } = useQuery(QUOTA_USAGE_QUERY)
  const [updateTeamName] = useMutation(UPDATE_TEAM_NAME_MUTATION, {
    refetchQueries: [{ query: QUOTA_USAGE_QUERY }]
  })

  const { plan } = useProfileService()

  if (loading || !plan) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error</div>
  }

  const quotas = buildQuotas(data, plan.planQuota)

  const handleUpdate = async (values) => {
    await updateTeamName({ variables: { name: values.teamName } })
    setFlashMessage('Team name updated successfully.')
  }

  return (
    <>
      <PageHeader title='Overview' />

      <Formik
        initialValues={{ teamName: data.currentTenant.name }}
        onSubmit={buildSubmitHandler(handleUpdate)}
      >
        {({ values, errors, touched, handleChange, dirty, isSubmitting }) => (
          <Form>
            <FormGroup title='Basic information'>
              <InputField
                label='Team name'
                name='teamName'
                onChange={handleChange}
                touched={touched.teamName}
                value={values.teamName}
                error={errors.teamName}
                helperText='This is used for emails and notifications sent for your projects.'
              />
              <ButtonContainer>
                <Button type='submit' disabled={isSubmitting || !dirty}>
                  {isSubmitting ? 'Saving...' : 'Save'}
                </Button>
              </ButtonContainer>
            </FormGroup>
          </Form>
        )}
      </Formik>

      <FormGroup
        title='Plan & quotas'
        description={
          <>
            To manage your plan, visit the <Link to='./billing'>billing page</Link>.
          </>
        }
        noBorder
      >
        <PlanDetailsAndBillingFreq plan={plan} />

        <ProgressMeter
          complete={quotas.documents.used}
          total={quotas.documents.allowed}
          title='Documents'
          textSummary={
            <>
              {format(quotas.documents.used)} used -{' '}
              {format(quotas.documents.allowed - quotas.documents.used)} remaining
            </>
          }
        />
        <ProgressMeter
          complete={quotas.labels.used}
          total={quotas.labels.allowed}
          title='Labels'
          textSummary={
            <>
              {format(quotas.labels.used)} used -{' '}
              {format(quotas.labels.allowed - quotas.labels.used)} remaining
            </>
          }
        />
        <div className='flex'>
          <div className='flex-1 mr2'>
            <ProgressMeter
              complete={quotas.admin.used}
              total={quotas.admin.allowed}
              title='Admins'
              textSummary={
                <>
                  {format(quotas.admin.used)} out of {format(quotas.admin.allowed)}{' '}
                  seats used
                </>
              }
            />
          </div>
          <div className='flex-1 ml2'>
            <ProgressMeter
              complete={quotas.members.used}
              total={quotas.members.allowed}
              title='Labelers'
              textSummary={
                <>
                  {format(quotas.members.used)} out of {format(quotas.members.allowed)}{' '}
                  seats used
                </>
              }
            />
          </div>
        </div>
      </FormGroup>
    </>
  )
}

export default Overview
