import gql from 'graphql-tag'

export const CATEGORY_TYPES = {
  DOCUMENT: 'DOCUMENT',
  FRAGMENT: 'FRAGMENT'
}

export const CATEGORY_FIELDS_FRAGMENT = gql`
  fragment documentCategoryFields on Category {
    id
    name
    description
  }

  fragment annotationCategoryFields on Category {
    id
    name
    description
    properties
  }
`

export const PROJECT_AND_DATASETS_FRAGMENT = gql`
  fragment projectInfoAndDatasets on Project {
    id
    name
    description
    instructions
    settings
    projectToDatasets {
      nodes {
        dataset {
          id
          name
          description
          documentCount
        }
      }
    }
  }
`

export const PROJECTS_QUERY = gql`
  query {
    projects {
      nodes {
        id
        name
        projectToDatasets {
          nodes {
            dataset {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const PROJECT_SUMMARY_QUERY = gql`
  query ProjectSummaryQuery(
    $projectId: UUID!
    $cycleId: String
    $cycleFilter: CycleFilter
  ) {
    projectById(id: $projectId) {
      labelStats(cycleId: $cycleId) {
        total
      }
      taskStats(cycleId: $cycleId) {
        complete
        skipped
        incomplete
        total
      }
      documentStats(cycleId: $cycleId) {
        labeled
        unassigned
        total
      }
      documentCategories: categories(condition: { type: DOCUMENT }) {
        nodes {
          id
          name
          count: documentLabelCount(cycleId: $cycleId)
        }
      }
      annotationCategories: categories(condition: { type: FRAGMENT }) {
        nodes {
          id
          name
          count: annotationLabelCount(cycleId: $cycleId)
        }
      }
      cycles(orderBy: CREATED_AT_ASC, filter: $cycleFilter) {
        nodes {
          id
          createdAt
          documentCount
          labels(filter: { taskAction: { equalTo: "create" } }) {
            totalCount
          }
          tasks {
            totalCount
          }
          incompleteTasks: tasks(filter: { status: { equalTo: INCOMPLETE } }) {
            totalCount
          }
          labelCount
        }
      }
    }
  }
`

export const PROJECT_QUERY = gql`
  query ProjectDetailsQuery($projectId: UUID!) {
    projectById(id: $projectId) {
      ...projectInfoAndDatasets
      documentCategories: categories(condition: { type: DOCUMENT }) {
        nodes {
          ...documentCategoryFields
        }
      }
      annotationCategories: categories(condition: { type: FRAGMENT }) {
        nodes {
          ...annotationCategoryFields
        }
      }
      teamMembers {
        nodes {
          id
          name
        }
      }
      cycles(orderBy: CREATED_AT_ASC) {
        nodes {
          id
          createdAt
          labels(filter: { taskAction: { equalTo: "create" } }) {
            totalCount
          }
          labelCount
        }
      }
    }
  }
  ${CATEGORY_FIELDS_FRAGMENT}
  ${PROJECT_AND_DATASETS_FRAGMENT}
`

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $categoryId: UUID!
    $name: String
    $description: String
    $properties: JSON
  ) {
    updateCategoryById(
      input: {
        id: $categoryId
        patch: { name: $name, description: $description, properties: $properties }
      }
    ) {
      category {
        nodeId
        id
        name
        description
        properties
      }
    }
  }
`

export const CREATE_CATEGORY = gql`
  mutation CreateCategory(
    $projectId: UUID!
    $type: CategoryType!
    $name: String!
    $description: String
    $properties: JSON
  ) {
    createCategory(
      input: {
        category: {
          projectId: $projectId
          type: $type
          name: $name
          description: $description
          properties: $properties
        }
      }
    ) {
      category {
        nodeId
        id
        projectId
        type
        name
        description
        properties
      }
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: UUID!) {
    deleteCategoryById(input: { id: $categoryId }) {
      deletedCategoryId
    }
  }
`

export const CREATE_PROJECT_MUTATION = gql`
  mutation CreateProject($name: String!, $description: String, $settings: JSON) {
    createProject(
      input: { name: $name, description: $description, settings: $settings }
    ) {
      project {
        id
        name
        description
        settings
      }
    }
  }
`

export const API_KEYS_QUERY = gql`
  query ApiKeys($projectId: UUID!) {
    apiKeys(
      filter: { projectId: { equalTo: $projectId }, isActive: { equalTo: true } }
    ) {
      nodes {
        nodeId
        key
        user {
          id
          name
        }
        isActive
        lastUsedAt
        createdAt
      }
    }
  }
`

export const GENERATE_API_KEY_MUTATION = gql`
  mutation GenerateApiKey($projectId: UUID!, $userId: UUID!) {
    generateApiKeyForCurrentTenant(input: { projectId: $projectId, userId: $userId }) {
      apiKey {
        nodeId
        projectId
        user {
          name
        }
        key
        isActive
        lastUsedAt
      }
    }
  }
`

export const REVOKE_API_KEY_MUTATION = gql`
  mutation RevokeApiKey($existingKey: UUID!) {
    revokeApiKey(input: { existingKey: $existingKey }) {
      apiKey {
        nodeId
        key
        isActive
        lastUsedAt
      }
    }
  }
`

export const UPDATE_PROJECT_DETAILS_MUTATION = gql`
  mutation UpdateProjectMutation(
    $projectId: UUID!
    $name: String
    $description: String
    $instructions: String
    $settings: JSON
  ) {
    updateProjectById(
      input: {
        id: $projectId
        patch: {
          name: $name
          description: $description
          instructions: $instructions
          settings: $settings
        }
      }
    ) {
      project {
        nodeId
        id
        name
        description
        instructions
      }
    }
  }
`

const LABEL_FIELDS_FRAGMENT = gql`
  fragment labelFields on Label {
    id
    cycleId
    creator {
      id
      name
    }
    document {
      id
      externalId
    }
    reviews: labels {
      totalCount
      nodes {
        id
        contents
        creator {
          id
          name
        }
        cycleId
        createdAt
      }
    }
    reviewTasks: tasks(
      filter: { action: { equalTo: REVIEW } }
      orderBy: CREATED_AT_DESC
    ) {
      totalCount
      nodes {
        assignee {
          name
        }
      }
    }
    contents
    source
    createdAt
  }
`

export const LABELS_BY_PROJECT_QUERY = gql`
  query LabelsByProjectQuery(
    $projectId: UUID!
    $filter: LabelFilter
    $first: Int
    $offset: Int
  ) {
    projectById(id: $projectId) {
      labels(filter: $filter, first: $first, offset: $offset, orderBy: CREATED_AT_ASC) {
        totalCount
        nodes {
          ...labelFields
        }
      }
    }
  }
  ${LABEL_FIELDS_FRAGMENT}
`

export const LATEST_CYCLE_QUERY = gql`
  query LatestCycleQuery($projectId: UUID!) {
    cycles(
      filter: { projectId: { equalTo: $projectId } }
      orderBy: CREATED_AT_DESC
      first: 1
    ) {
      nodes {
        id
        settings
        isComplete
        createdAt
      }
    }
  }
`

export const CREATE_CYCLE_MUTATION = gql`
  mutation CreateCycleMutation(
    $id: String!
    $projectId: UUID!
    $creatorId: UUID!
    $settings: JSON
  ) {
    createCycle(
      input: {
        cycle: {
          id: $id
          projectId: $projectId
          creatorId: $creatorId
          settings: $settings
        }
      }
    ) {
      cycle {
        id
        projectId
        creatorId
        settings
        createdAt
      }
    }
  }
`

export const ASSIGN_DOCUMENTS_MUTATION = gql`
  mutation AssignDocuments(
    $projectId: UUID!
    $cycleId: String
    $assigneeIds: [UUID]
    $documentIds: [UUID]
    $labelIds: [UUID]
  ) {
    assignDocuments(
      input: {
        projectId: $projectId
        cycleId: $cycleId
        assigneeIds: $assigneeIds
        documentIds: $documentIds
        labelIds: $labelIds
      }
    ) {
      createdTasks {
        id
        assigneeId
        documentId
        externalLabelId
      }
    }
  }
`

export const DELETE_LABELS_BY_IDS_MUTATION = gql`
  mutation DeleteLabelsById($labelIds: [UUID!]!) {
    deleteLabelsByIds(input: { labelIds: $labelIds }) {
      clientMutationId
    }
  }
`
