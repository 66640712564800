import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'

import {
  FreeTextField,
  InputField,
  RadioGroup,
  RadioOption
} from 'common/components/form'
import { Button, ButtonContainer } from 'common/components'
import FormGroup from 'common/components/form-group'
import { buildSubmitHandler } from 'common/form-helpers'

const ProjectDetailsForm = ({ project, onSubmit }) => (
  <Formik
    initialValues={{
      name: project.name,
      description: project.description || '',
      instructions: project.instructions || '',
      // overlap: project.settings.overlap,
      strategy: project.settings.strategy
    }}
    validationSchema={projectFormSchema}
    onSubmit={buildSubmitHandler(onSubmit)}
  >
    {({ values, errors = {}, touched, handleChange, isSubmitting }) => (
      <Form>
        <FormGroup
          title='Basic information'
          description='This information will be visible to all members of the project.'
        >
          <InputField
            label='Project name'
            name='name'
            touched={touched.name}
            value={values.name}
            error={errors.name}
            onChange={handleChange}
            helperText="We'll use this for the project URL."
          />

          <FreeTextField
            label='Description'
            name='description'
            touched={touched.description}
            value={values.description}
            error={errors.description}
            onChange={handleChange}
          />

          <FreeTextField
            label='Annotation instructions'
            name='instructions'
            touched={touched.instructions}
            value={values.instructions}
            error={errors.instructions}
            onChange={handleChange}
            helperText='These instructions will be available to your team while they annotate documents. HTML is allowed.'
          />
        </FormGroup>

        <FormGroup
          title='Task selection'
          description='This option specifies how tasks are chosen for your workers.'
          noBorder
        >
          {/* <InputField
            label='Task overlap'
            name='overlap'
            touched={touched.overlap}
            value={values.overlap}
            error={errors.overlap}
            onChange={handleChange}
            helperText='The probability that a document will be assigned to multiple team members. E.g. 0 = each document is assigned to just one individual, 1 = every document is assigned to every individual. Useful for QA purposes. We recommend a value around 0.2.'
          /> */}

          <RadioGroup label={'Sampling strategy'}>
            <p className='lh-copy'>
              All tasks have a priority metric (i.e., weight), ranging from 0 to 1
              (default = 0.5). Priority is used when selecting a worker's next task,
              depending on the strategy selected here.
            </p>
            <p className='lh-copy'>
              If you just want a simple random sampling strategy and aren't using
              weights, there is no difference between these two methods.
            </p>

            <RadioOption
              name='strategy'
              value='stratified'
              title='Weighted (stratified) sampling'
              description='Weighted sampling uses task priority to probabilistically select the next task. For example, if you have three tasks of priorities 0.8, 0.2, and 0.2, the first task has ~67% (0.8 / 1.2) odds of being selected first.'
              isSelected={values.strategy === 'stratified'}
              onChange={handleChange}
            />

            <RadioOption
              name='strategy'
              value='ranked'
              title='Tiered (ranked) sampling'
              description='Tiered sampling uses task priority as a selection ranking (i.e., higher priority tasks are exhausted before any lower priority tasks are selected). For example, if you have three tasks with priorities 0.4, 0.4, and 0.3, the strategy will randomly select one of the two higher-priority tasks first, resulting in remaining tasks of priorities 0.4 and 0.3. The next task selected will be the second 0.4 priority task, then the task with priority 0.3 will be selected last.'
              isSelected={values.strategy === 'ranked'}
              onChange={handleChange}
            />
          </RadioGroup>
        </FormGroup>

        <ButtonContainer>
          <Button type='submit' disabled={isSubmitting}>
            Update project
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

const projectFormSchema = yup.object().shape({
  name: yup.string().required('A project name is required.')
  // overlap: yup.number().min(0).max(1).required('An overlap value is required. If you do not want overlap, use 0 (zero).')
})

export default ProjectDetailsForm
