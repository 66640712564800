import React, { FC } from 'react'
import makeAsyncScriptLoader from 'react-async-script'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'

import { useParams } from 'common/location'
import { ProductType, BillingCycle } from 'common/types'
import { useProfileService } from 'common/profile'
import { PageHeader } from 'common/components'

import PaymentUpdateSuccessBanner from '../components/payment-update-success-banner'
import CheckoutCompleteBanner from '../components/checkout-complete-banner'
import SubscribedBillingPage from './subscribed-billing-page'
import NotSubscribedBillingPage from './not-subscribed-billing-page'

const PLANS_AND_PRICING_QUERY = gql`
  query PlansAndPricingQuery {
    billingPlans {
      plans
    }
  }
`

const BillingPage: FC = () => {
  const { plan } = useProfileService()
  const params = useParams()
  const { data, loading, error } = useQuery(PLANS_AND_PRICING_QUERY)

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error loading plan and pricing data</div>
  }

  const { checkoutSessionId, updateCheckoutSessionId } = params

  const isTrial = plan.planType === ProductType.TRIAL

  const { billingPlans } = data
  const plans = {
    [ProductType.INDIE]: {
      [BillingCycle.MONTHLY]: billingPlans.plans.find(
        p => p.billingCycle === 'monthly' && p.planType === 'indie'
      ),
      [BillingCycle.YEARLY]: billingPlans.plans.find(
        p => p.billingCycle === 'yearly' && p.planType === 'indie'
      )
    },
    [ProductType.TEAM]: {
      [BillingCycle.MONTHLY]: billingPlans.plans.find(
        p => p.billingCycle === 'monthly' && p.planType === 'team'
      ),
      [BillingCycle.YEARLY]: billingPlans.plans.find(
        p => p.billingCycle === 'yearly' && p.planType === 'team'
      )
    }
  }

  return (
    <div className='lh-copy'>
      <PageHeader title='Billing' />

      {checkoutSessionId && (
        <CheckoutCompleteBanner plan={plan} checkoutSessionId={checkoutSessionId as string} />
      )}
      {updateCheckoutSessionId && <PaymentUpdateSuccessBanner />}

      {isTrial ? (
        !checkoutSessionId && <NotSubscribedBillingPage plans={plans} plan={plan} />
      ) : (
        <SubscribedBillingPage plans={plans} plan={plan} />
      )}
    </div>
  )
}

const ConnectedBillingPage = makeAsyncScriptLoader('https://js.stripe.com/v3/')(
  BillingPage
)

export default ConnectedBillingPage
