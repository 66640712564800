import tw, { TwStyle, styled } from 'twin.macro'

export type ButtonVariant =
  | `primary-blue`
  | `primary-red`
  | `primary-gray`
  | `secondary-red`
  | `secondary-blue`
  | `secondary-gray`
  | `tertiary-red`
  | `tertiary-blue`
  | `tertiary-gray`
export interface SharedButtonProps {
  variant?: ButtonVariant
  wide?: boolean
}

const primary = tw`py-2 text-white`
const secondary = tw`py-2 border`
const tertiary = tw`p-0`
export const sharedButtonStyles: Record<ButtonVariant, TwStyle[]> = {
  'primary-blue': [primary, tw`bg-blue-600 hover:bg-blue-700`],
  'primary-red': [primary, tw`bg-red-600 hover:bg-red-700`],
  'primary-gray': [primary, tw`bg-gray-500 hover:bg-gray-600`],
  'secondary-blue': [
    secondary,
    tw`text-blue-600 border-blue-600 hover:border-blue-800 hover:bg-blue-50`
  ],
  'secondary-red': [
    secondary,
    tw`text-red-600 border-red-600 hover:border-red-800 hover:bg-red-50`
  ],
  'secondary-gray': [
    secondary,
    tw`text-gray-500 border-gray-500 hover:border-gray-700 hover:bg-gray-50`
  ],
  'tertiary-blue': [tertiary, tw`text-blue-600 hover:text-blue-800 underline`],
  'tertiary-red': [tertiary, tw`text-red-600 hover:text-red-800 underline`],
  'tertiary-gray': [tertiary, tw`text-gray-500 hover:text-gray-700 underline`]
}

const Button = styled.button.attrs<SharedButtonProps>(
  ({ variant = 'primary-blue', disabled = false, type = 'button', wide = false }) => ({
    variant,
    disabled,
    type,
    wide
  })
)<SharedButtonProps>`
    ${tw`transition-colors rounded`}
    ${({ disabled }) =>
      disabled ? tw`cursor-not-allowed opacity-60` : tw`cursor-pointer`}
    ${({ wide }) => (wide ? tw`px-8` : tw`px-4`)}
    ${({ variant }) => sharedButtonStyles[variant]}
  `

export default Button
