import React from 'react'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'

import UserForm from '../components/user-form'

const USER_QUERY = gql`
  query UserDetails($userId: UUID!) {
    userById(id: $userId) {
      nodeId
      id
      name
      email
      permissions
      lastLogin
    }
    adminUsers: users(filter: { permissions: { contains: { role: "admin" } } }) {
      totalCount
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation SaveUser($id: UUID!, $name: String, $email: String, $permissions: JSON) {
    updateUserById(
      input: {
        id: $id
        patch: { name: $name, email: $email, permissions: $permissions }
      }
    ) {
      user {
        nodeId
        id
        name
        email
        permissions
      }
    }
  }
`

const ExistingUserPage = ({ navigate, userId }) => {
  const { loading, error, data } = useQuery(USER_QUERY, { variables: { userId } })
  const [updateUser] = useMutation(UPDATE_USER_MUTATION)

  if (loading) return <div>loading...</div>
  if (error) return <div>error: {error.message}</div>

  const handleUpdateUser = async ({ variables }) => {
    const result = await updateUser({ variables })

    const { data } = result
    const {
      updateUserById: { user }
    } = data
    const flash = `User ${user.email} was updated successfully.`
    navigate('/team/users', { state: { flash } })
  }

  const user = data.userById
  const adminCount = data.adminUsers.totalCount

  if (!user) {
    navigate('/team/users', { state: { error: 'Could not find a user with that id.' } })
    return null
  }

  return (
    <UserForm
      user={user}
      adminCount={adminCount}
      isEditing={true}
      onSubmit={handleUpdateUser}
    />
  )
}

export default ExistingUserPage
