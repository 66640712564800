import React from 'react'

const round = (number, precision = 0) => {
  const x = 10 ** precision
  return Math.round(number * x) / x
}

export const Percentage = ({ count, total }) =>
  total !== 0 ? <>{round((count / total) * 100, 1)}%</> : <>&mdash;</>

const CompletionPercentage = ({ count, total, includeCounts = true }) => (
  <>
    <Percentage count={count} total={total} />

    {includeCounts && (
      <>
        {' '}
        <small className='silver'>
          ({count}/{total})
        </small>
      </>
    )}
  </>
)

export default CompletionPercentage
