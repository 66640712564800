import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faTimesCircle,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons'
import { faMarker } from '@fortawesome/free-solid-svg-icons'

const SmallLabel = ({ icon, label, color }) => (
  <span className='dib pa1 f7 ba br2 nowrap' style={{ color, borderColor: color }}>
    <FontAwesomeIcon icon={icon} className='mr1' style={{ color }} /> {label}
  </span>
)

const ReviewStatusBadge = ({ reviews, reviewTasks }) => {
  let reviewStatus, tooltipText
  if (reviews.totalCount === 0) {
    if (reviewTasks.totalCount === 0) {
      reviewStatus = 'unassigned'
      tooltipText = 'Not assigned for review'
    } else {
      reviewStatus = 'pending'
      tooltipText = `Awaiting review from ${reviewTasks.nodes[0].assignee.name}`
    }
  } else {
    const latestReview = reviews.nodes[reviews.nodes.length - 1] // TODO-2: handle multiple review labels on same doc
    reviewStatus = latestReview.contents.result
    tooltipText = {
      accept: `Accepted by ${latestReview.creator.name} in cycle ${latestReview.cycleId}`,
      modify: `Modified by ${latestReview.creator.name} in cycle ${latestReview.cycleId}`,
      reject: `Rejected by ${latestReview.creator.name} in cycle ${latestReview.cycleId}`
    }[reviewStatus]
  }

  return (
    <span data-tip={tooltipText}>
      {
        {
          accept: <SmallLabel icon={faCheckCircle} color='#1dc788' label='accepted' />,
          modify: <SmallLabel icon={faMarker} color='#1dc788' label='modified' />,
          reject: <SmallLabel icon={faTimesCircle} color='#ff6158' label='rejected' />,
          unassigned: (
            <SmallLabel icon={faQuestionCircle} color='#aaa' label='not reviewed' />
          ),
          pending: (
            <SmallLabel icon={faQuestionCircle} color='#aaa' label='pending review' />
          )
        }[reviewStatus]
      }
    </span>
  )
}

export default ReviewStatusBadge
