import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { RouteComponentProps } from '@reach/router'

import { useAuth } from 'auth'
import { Link } from 'common/components'

import LoginForm from '../components/login-form'
import { LOGIN_MUTATION } from '../queries'

const LoginPage = ({ navigate }: RouteComponentProps) => {
  const { login } = useAuth()
  const [performLogin] = useMutation(LOGIN_MUTATION)
  const [loginStatus, setLoginStatus] = React.useState({})

  const handleLogin = async variables => {
    setLoginStatus({})
    try {
      const {
        data: {
          authenticate: { jwtToken: authToken }
        }
      } = await performLogin({ variables })

      if (authToken) {
        login(authToken)
        navigate('/', {
          state: { flash: 'You have successfully logged in!' },
          replace: true
        })
      }
    } catch (err) {
      console.log(err)
      setLoginStatus({ error: 'Invalid username or password.' })
    }
  }

  return (
    <>
      <LoginForm onSubmit={handleLogin} status={loginStatus} />

      <p className='mt4'>
        Have an invite code? Head over to{' '}
        <Link to='/register'>the registration page</Link>!
      </p>
    </>
  )
}

export default LoginPage
