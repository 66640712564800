import React, { FC } from 'react'
import classNames from 'classnames'

import { useLocation } from 'common/location'
import useFlash from './use-flash'

const sharedClasses = 'mb3 pa3 bl bw3 lh-copy'

export const FlashMessage: FC<any> = ({ className, ...props }) => (
  <div className={classNames([sharedClasses, className])} {...props} />
)

export const FlashInfo: FC<any> = props => (
  <FlashMessage className='bg-washed-yellow b--light-yellow' {...props} />
)

export const FlashSuccess: FC<any> = props => (
  <FlashMessage className='bg-washed-green b--light-green' {...props} />
)

export const FlashError: FC<any> = props => (
  <FlashMessage className='bg-washed-red b--light-red' {...props} />
)

export const FlashMessages = () => {
  const { getFlashMessage } = useFlash()
  const location = useLocation()

  const locationSuccessMessage = location?.state?.flash
  const locationErrorMessage = location?.state?.error

  const flashMessage = getFlashMessage()
  const { message: instanceMessage, type } = flashMessage
  const isError = type === 'error' || Boolean(locationErrorMessage)

  const message = instanceMessage || locationSuccessMessage || locationErrorMessage
  const FlashComponent = isError ? FlashError : FlashSuccess

  return message ? <FlashComponent>{message}</FlashComponent> : null
}
