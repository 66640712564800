import React from 'react'

import { RadioOption } from 'common/components/form'

import { Config, TaskAction } from '../types'

import TaskControlsContainer from './task-controls-container'

interface IClassificationControls {
  action: TaskAction
  config: Config
  onChange(unknown): unknown
  values: any
}

const ClassificationControls = ({
  action,
  config,
  onChange: handleChange,
  values
}: IClassificationControls) => {
  return (
    <TaskControlsContainer
      label={
        action === TaskAction.Review
          ? 'Please accept or reject the provided label.'
          : 'Please select a document class.'
      }
    >
      {config.documentCategories.map(({ id, name }) => (
        <RadioOption
          key={id}
          name='documentClass'
          title={name}
          value={id}
          isSelected={values.documentClass === id}
          onChange={handleChange}
          disabled={action === TaskAction.Review && values.documentClass !== id}
        />
      ))}
    </TaskControlsContainer>
  )
}

export default ClassificationControls
