import React from 'react'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: React.RefObject<any>) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type='checkbox' ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export default IndeterminateCheckbox
