import React, { useMemo, FC } from 'react'
import 'twin.macro'
import { assign } from 'xstate'
import { useMachine } from '@xstate/react'

import AnnotatedElement from './annotated-element'
import { ELEMENT_MAP } from './utils'
import {
  transformContents,
  transformApiAnnotationToComponentAnnotation,
  transformComponentAnnotationToApiAnnotation,
  TransformedContents,
  ComponentAnnotation,
  ApiAnnotation
} from './transforms'
import { annotatorMachine, parseSelection } from './machines'

interface AnnotatorProps {
  annotations: ApiAnnotation[]
  categories: any[]
  onAddAnnotation?: Function
  onRemoveAnnotation?: Function
  readOnly?: boolean
}

const Annotator: FC<AnnotatorProps> = ({
  annotations: apiAnnotations = [],
  categories,
  onAddAnnotation,
  onRemoveAnnotation,
  readOnly,
  children
}) => {
  const [state, send] = useMachine(
    annotatorMachine.withConfig({
      actions: {
        setPendingAnnotation: assign({ pendingAnnotation: () => parseSelection() })
      }
    })
  )
  const { pendingAnnotation } = state.context

  const annotations = useMemo(
    () =>
      apiAnnotations.map(a => transformApiAnnotationToComponentAnnotation(children, a)),
    [apiAnnotations, children]
  )

  const handleRemoveAnnotation = (annotation: ComponentAnnotation) => {
    const toRemove = transformComponentAnnotationToApiAnnotation(children, annotation)
    onRemoveAnnotation(toRemove)
  }

  const renderContent = (contents: string | TransformedContents[]) => {
    const allAnnotations = pendingAnnotation
      ? annotations.concat(pendingAnnotation)
      : annotations

    return typeof contents === 'string'
      ? contents
      : contents.map(({ type, id, value }, key) => {
          const annotationsForElement = allAnnotations.filter(
            a => a.selector.elementId === id
          )

          const Tag = ELEMENT_MAP[type] || ELEMENT_MAP['unknown']

          return annotationsForElement.length > 0 ? (
            <AnnotatedElement
              key={id}
              id={id}
              type={type}
              value={value}
              annotations={annotationsForElement}
              onRemoveAnnotation={handleRemoveAnnotation}
              onCancelAnnotation={() => send('reset')}
              onAddAnnotation={addAnnotation}
              categories={categories}
              readOnly={readOnly}
            />
          ) : (
            <Tag key={id} id={id}>
              {renderContent(value)}
            </Tag>
          )
        })
  }

  const addAnnotation = ({ id: categoryId, notes }) => {
    onAddAnnotation({
      ...transformComponentAnnotationToApiAnnotation(children, pendingAnnotation),
      categoryId,
      notes
    })
    send('reset')
  }

  const transformedContents = transformContents(children)

  return (
    <>
      <div
        id='documentEl'
        tw='leading-relaxed'
        onMouseUp={() => send('mouseup')}
        onMouseMove={() => send('mousemove')}
        onMouseDown={() => send('mousedown')}
        data-cy='annotator'
      >
        {renderContent(transformedContents)}
      </div>
      <div id='popup-portal' />
    </>
  )
}

export default Annotator
