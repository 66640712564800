import React, { useRef } from 'react'
import uuid from 'uuid/v4'
import { useQuery, useMutation } from '@apollo/react-hooks'
import ReactTooltip from 'react-tooltip'
import isEmpty from 'lodash/isEmpty'

import { useAuth } from 'auth'
import { useLocation, useParams } from 'common/location'
import DataTable, {
  OnSelectionChangeArgs,
  DataTableRef
} from 'common/components/data-table'
import { updateQueryAndFetch } from 'common/route-utils'
import { Button, ButtonContainer, Link, Tag } from 'common/components'
import Datetime from 'common/components/datetime'
import { offsetForPage, PAGE_SIZE } from 'common/pagination'
import Ellipsize from 'common/components/ellipsize'

import ControlBar from '../components/control-bar'
import LabelListingFilters from '../components/label-listing-filters'

import {
  LABELS_BY_PROJECT_QUERY,
  CREATE_CYCLE_MUTATION,
  LATEST_CYCLE_QUERY,
  ASSIGN_DOCUMENTS_MUTATION,
  DELETE_LABELS_BY_IDS_MUTATION
} from '../queries'
import CreateValidationTasksModal from '../components/create-validation-tasks-modal'
import ReviewStatusBadge from '../components/review-status-badge'

import { IFilterParam } from '../components/filters'

// TODO-3: extract
const generateCycleId = () => uuid().slice(0, 8)

interface FilterVariables {
  taskAction: IFilterParam
  cycleId?: IFilterParam
  source?: IFilterParam
  contents?: IFilterParam
  creatorId?: IFilterParam
}

type Label = any // TODO-3: type

const buildVariables = params => {
  const page = Number(params.page || 1)
  const offset = offsetForPage(page)

  let filter: FilterVariables = { taskAction: { equalTo: 'create' } } // we only show CREATE tasks on this screen, so this is hardcoded

  if (params.cycle) {
    filter.cycleId = { equalTo: params.cycle }
  }

  if (params.source) {
    if (params.source.toLowerCase() === 'external') {
      filter.source = { equalTo: 'EXTERNAL' }
    } else {
      filter.source = { equalTo: 'INTERNAL' }
      filter.creatorId = { equalTo: params.source }
    }
  }

  if (params.documentCategory) {
    filter.contents = { contains: { categoryId: params.documentCategory } }
  }

  if (params.annotationCategory) {
    filter.contents = {
      contains: { annotations: [{ categoryId: params.annotationCategory }] }
    }
  }

  return isEmpty(filter) ? { offset } : { offset, filter }
}

// TODO-2: resolve pagination and selectable table row duplication between here and document-list.tsx

interface LabelListingProps {
  project: any
  navigate(any): any
}

const LabelListing = ({ project, navigate }: LabelListingProps) => {
  const location = useLocation()
  const params = useParams()

  const { getUserId, isAdmin } = useAuth()
  const labelsByProjectVariables = {
    projectId: project.id,
    first: PAGE_SIZE,
    ...buildVariables(params)
  }

  const { data, loading } = useQuery(LABELS_BY_PROJECT_QUERY, {
    variables: labelsByProjectVariables
  })

  const { data: cycleData, loading: cycleLoading } = useQuery(LATEST_CYCLE_QUERY, {
    variables: { projectId: project.id }
  })

  const [createCycle] = useMutation(CREATE_CYCLE_MUTATION)
  const [assignDocuments] = useMutation(ASSIGN_DOCUMENTS_MUTATION, {
    variables: { projectId: project.id },
    refetchQueries: [
      { query: LABELS_BY_PROJECT_QUERY, variables: labelsByProjectVariables }
    ]
  }) // NOTE: mutation is defined as postgraphile plugin

  const [deleteLabelsByIds] = useMutation(DELETE_LABELS_BY_IDS_MUTATION, {
    refetchQueries: [
      { query: LABELS_BY_PROJECT_QUERY, variables: labelsByProjectVariables }
    ]
  })

  const tableRef = useRef<DataTableRef>(null)
  const deselectAllRows = () => tableRef.current.toggleAllRowsSelected(false)

  const [selectionState, setSelectionState] = React.useState<OnSelectionChangeArgs>({
    state: 'none',
    selectedRowIds: []
  })

  const [isCreateTaskModalVisible, setCreateTaskModalVisible] = React.useState(false)

  if (loading || cycleLoading) {
    return <div>loading...</div>
  }

  const page = Number(params.page || 1)

  const labelCount = data.projectById.labels.totalCount
  const labels = data.projectById.labels.nodes as Label[]

  const metadata = {
    currentPage: page,
    totalPages: Math.ceil(labelCount / PAGE_SIZE),
    totalCount: labelCount
  }

  const handlePageChange = page => {
    const nextParams = { ...params, page }
    updateQueryAndFetch(location, navigate, nextParams)
  }

  const handleSelectionChange = (selectionState: OnSelectionChangeArgs) => {
    setSelectionState(selectionState)
  }

  const totalNumberSelected =
    selectionState.state === 'all'
      ? metadata.totalCount
      : selectionState.selectedRowIds.length

  const handleDeleteSelectedLabels = async () => {
    await deleteLabelsByIds({ variables: { labelIds: selectionState.selectedRowIds } })
    deselectAllRows()
  }

  const latestCycle = cycleData.cycles.nodes[0]

  const classNameForId = id => {
    const cat = project.documentCategories.nodes.find(c => c.id === id)
    return cat ? cat.name : null
  }
  const annotationCategoryForId = id => {
    return project.annotationCategories.nodes.find(c => c.id === id)
  }

  const handleCreateValidationTasks = async values => {
    const { assignee, assignmentType, cycle: cycleType } = values
    const assigneeId = assignmentType === 'specific' ? assignee.value : null

    let cycle
    if (cycleType === 'new') {
      const variables = {
        id: generateCycleId(),
        projectId: project.id,
        creatorId: getUserId(),
        settings: { default_task_action: 'review' }
      }
      const res = await createCycle({ variables })
      cycle = res.data.createCycle.cycle
    } else {
      // then cycleType === 'latest'
      cycle = latestCycle
    }

    // TODO-2: handle areAllMatchingSelected case, ie, pass in documentCategory and annotationCategory
    const variables = {
      projectId: project.id,
      assigneeId,
      labelIds: selectionState.selectedRowIds,
      cycleId: cycle.id
    }

    await assignDocuments({ variables })
    setCreateTaskModalVisible(false)
    deselectAllRows()
    setCreateTaskModalVisible(false)
  }

  const renderCreatorCell = (creator, { source }) => {
    if (source === 'EXTERNAL') {
      return <>external</>
    } else {
      return <>{creator.name}</>
    }
  }

  const cycleFilterOptions = project.cycles.nodes.map(c => ({
    id: c.id,
    name: c.id
  }))

  const sourceFilterOptions = project.teamMembers.nodes
    .map(u => ({
      id: u.id,
      name: u.name
    }))
    .concat({ id: 'external', name: 'EXTERNAL' })

  const documentCategories = project.documentCategories.nodes.map(c => ({
    id: c.id,
    name: c.name
  }))

  const annotationCategories = project.annotationCategories.nodes.map(c => ({
    id: c.id,
    name: c.name
  }))

  return (
    <>
      <ReactTooltip effect='solid' className='mw6' />

      <CreateValidationTasksModal
        isVisible={isCreateTaskModalVisible}
        onRequestClose={() => setCreateTaskModalVisible(false)}
        latestCycle={latestCycle}
        totalNumberSelected={totalNumberSelected}
        onSubmit={handleCreateValidationTasks}
      />

      <ControlBar
        buttons={() =>
          isAdmin() && (
            <ButtonContainer left noMargin>
              <Button
                variant='secondary-blue'
                disabled={selectionState.selectedRowIds.length === 0}
                onClick={() => setCreateTaskModalVisible(true)}
              >
                Create review tasks ({totalNumberSelected})
              </Button>

              <Button
                variant='secondary-red'
                disabled={selectionState.selectedRowIds.length === 0}
                onClick={() => handleDeleteSelectedLabels()}
              >
                Delete labels ({totalNumberSelected})
              </Button>
            </ButtonContainer>
          )
        }
        filters={() => (
          <LabelListingFilters
            cycles={cycleFilterOptions}
            sources={sourceFilterOptions}
            documentCategories={documentCategories}
            annotationCategories={annotationCategories}
            onChange={filters => {
              const nextParams = filters || {}
              updateQueryAndFetch(location, navigate, nextParams)
            }}
          />
        )}
      />

      <DataTable
        data={labels}
        columns={[
          {
            Header: 'Cycle ID',
            accessor: 'cycleId'
          },
          {
            Header: 'Document ID',
            accessor: ({ document }: Label) => (
              <Link to={`../documents/${document.id}`} className='black'>
                {document.externalId}
              </Link>
            )
          },
          {
            Header: 'Source',
            accessor: ({ creator, source }: Label) => {
              return renderCreatorCell(creator, { source })
            }
          },
          {
            Header: 'Status', // TODO-3: only show status col if there are external labels?
            accessor: ({ source, reviews, reviewTasks }: Label) =>
              source === 'EXTERNAL' ? (
                <ReviewStatusBadge reviews={reviews} reviewTasks={reviewTasks} />
              ) : null
          },
          {
            Header: 'Labels',
            accessor: ({ contents }: Label) => (
              <>
                {contents.categoryId ? (
                  <Tag className='bg-lighterest-blue' style={{ marginBottom: 5 }}>
                    {classNameForId(contents.categoryId)}
                  </Tag>
                ) : null}

                {contents.annotations && (
                  <div style={{ marginBottom: -5 }}>
                    {contents.annotations.map((annotation, index) => {
                      const annotationCategory = annotationCategoryForId(
                        annotation.categoryId
                      )
                      return (
                        <Tag
                          key={index}
                          className='inline-flex flex-row'
                          style={{
                            backgroundColor: annotationCategory.properties.color,
                            marginBottom: 5
                          }}
                        >
                          <span className='pr2 pv1 flex-1 flex items-center'>
                            <Ellipsize maxLength={20} value={annotation.selectedText} />
                          </span>
                          <span className='f7 dib pa1 b flex-0 flex items-center bg-white'>
                            {annotationCategory.name}
                          </span>
                        </Tag>
                      )
                    })}
                  </div>
                )}
              </>
            )
          },
          {
            Header: 'Created',
            accessor: ({ createdAt }: Label) => (
              <span className='nowrap'>
                <Datetime human datetime={createdAt} />
              </span>
            )
          }
        ]}
        isSelectable
        onSelectionChange={handleSelectionChange}
        metadata={metadata}
        fetchData={handlePageChange}
      />
    </>
  )
}

export default LabelListing
