import React from 'react'

import { useAuth } from 'auth'
import { useFlash } from 'common/flash'
import { LinkButton } from 'common/components'
import { confirmAction, cleanGraphQLErrorMessage } from 'common/utils'
import Datetime from 'common/components/datetime'
import DataTable from 'common/components/data-table'

import { USERS_QUERY, DELETE_TEAM_MEMBER_MUTATION } from '../queries'
import { useQuery, useMutation } from '@apollo/react-hooks'

type User = any

const UsersPage = ({ navigate }) => {
  const { isAdmin } = useAuth()
  const { setFlashMessage } = useFlash()

  const { loading, error, data } = useQuery(USERS_QUERY)
  const [deleteUser] = useMutation(DELETE_TEAM_MEMBER_MUTATION, {
    refetchQueries: [{ query: USERS_QUERY }]
  })

  if (loading) return <div>loading...</div>
  if (error) return <div>error: {error.message}</div>

  const handleDeleteUser = async ({ variables }) => {
    try {
      await deleteUser({ variables })
      setFlashMessage('User successfully deleted.')
    } catch (err) {
      setFlashMessage(cleanGraphQLErrorMessage(err.message), 'error')
    }
  }

  const users = data.users.nodes as User[]

  const handleDeleteClick = ({ id }) =>
    confirmAction('Are you sure you want to delete this user?')(() =>
      handleDeleteUser({ variables: { id } })
    )
  const handleEditClick = user => navigate(`./${user.id}`)

  return (
    <>
      <DataTable
        data={users}
        columns={[
          {
            Header: 'Name',
            accessor: 'name'
          },
          {
            Header: 'Email',
            accessor: 'email'
          },
          {
            Header: 'Role',
            accessor: ({ permissions }: User) => permissions.role
          },
          {
            Header: 'Last login',
            accessor: ({ lastLogin }: User) =>
              lastLogin ? <Datetime human datetime={lastLogin} /> : 'never'
          }
        ]}
        rowActions={
          isAdmin()
            ? [
                { label: 'Edit', handler: handleEditClick },
                { label: 'Delete', handler: handleDeleteClick }
              ]
            : []
        }
      />

      {isAdmin() && (
        <div className='mt4 tr'>
          <LinkButton to='../invitations/new'>Invite new user to team</LinkButton>
        </div>
      )}
    </>
  )
}

export default UsersPage
