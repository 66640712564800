import isEmpty from 'lodash/isEmpty'

const isClassificationTask = task => task.type.includes('classification')
const isFragmentTask = task => task.type.includes('fragment')

const validateTask = (task, categoryId, annotations) => {
  if (
    isClassificationTask(task) &&
    isEmpty(categoryId) &&
    isFragmentTask(task) &&
    isEmpty(annotations)
  ) {
    return [false, 'You must select a document class or label some fragments.']
  } else if (
    isClassificationTask(task) &&
    !isFragmentTask(task) &&
    isEmpty(categoryId)
  ) {
    return [false, 'You must select a document class.']
  } else if (
    !isClassificationTask(task) &&
    isFragmentTask(task) &&
    isEmpty(annotations)
  ) {
    return [false, 'You must label at least one document fragment.']
  }

  return [true, null]
}

export default validateTask
