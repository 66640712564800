import React, { useState, FC } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { useAuth } from 'auth'
import { Button, ButtonContainer, PageHeader } from 'common/components'
import DataTable from 'common/components/data-table'
import Datetime from 'common/components/datetime'
import CopyToClipboard from 'common/components/copy-to-clipboard'
import { confirmAction, cleanGraphQLErrorMessage } from 'common/utils'
import { FlashError } from 'common/flash'

import ProjectCodeSnippet from '../../components/project-code-snippet'

import {
  API_KEYS_QUERY,
  GENERATE_API_KEY_MUTATION,
  REVOKE_API_KEY_MUTATION
} from '../../queries'

type ApiKey = any

interface ApiKeyManagementProps {
  apiKeys: ApiKey[]
  generateApiKey: () => void
  revokeApiKey: (args: any) => void
}

const ApiKeyManagement: FC<ApiKeyManagementProps> = ({
  apiKeys,
  generateApiKey,
  revokeApiKey
}) => {
  const { isAdmin } = useAuth()

  const [error, setError] = useState(null)

  const handleRevokeKey = async apiKey => {
    const { key: existingKey } = apiKey
    setError(null)
    try {
      revokeApiKey({ variables: { existingKey } })
    } catch (error) {
      setError(cleanGraphQLErrorMessage(error.message))
    }
  }

  const handleGenerateApiKey = async () => {
    setError(null)
    try {
      await generateApiKey()
    } catch (error) {
      setError(cleanGraphQLErrorMessage(error.message))
    }
  }

  return (
    <div className='mt4'>
      {error && <FlashError>{error}</FlashError>}
      <p>You may create one API key per project, per user.</p>

      <DataTable
        data={apiKeys}
        columns={[
          {
            Header: 'Key',
            accessor: ({ key }: ApiKey) => (
              <div className='flex flex-row items-center'>
                <pre className='f6 dib ma0'>{key}</pre>
                <CopyToClipboard value={key} />
              </div>
            )
          },
          {
            Header: 'Status',
            accessor: ({ isActive }: ApiKey) => (isActive ? 'active' : 'revoked')
          },
          {
            Header: 'User',
            accessor: ({ user }: ApiKey) => <div>{user && user.name}</div>
          },
          {
            Header: 'Last used',
            accessor: ({ lastUsedAt }: ApiKey) =>
              lastUsedAt ? <Datetime human datetime={lastUsedAt} /> : 'never'
          }
        ]}
        rowActions={
          isAdmin()
            ? [
                {
                  label: 'Revoke',
                  handler: key =>
                    confirmAction('Are you sure you want to revoke this API key?')(() =>
                      handleRevokeKey(key)
                    ),
                  check: (key: ApiKey) => key.isActive
                }
              ]
            : []
        }
      />

      <ButtonContainer>
        <Button onClick={handleGenerateApiKey}>Generate new API key</Button>
      </ButtonContainer>
    </div>
  )
}

interface Props extends ApiKeyManagementProps {
  project: { id: string }
}

const ApiKeys: FC<Props> = ({ project, ...props }) => {
  const projectId = project.id
  const { isAdmin, getUserId } = useAuth()
  const { data, loading, error } = useQuery(API_KEYS_QUERY, {
    variables: { projectId: project.id }
  })

  const refetchQueries = [{ query: API_KEYS_QUERY, variables: { projectId } }]
  const [generateApiKey] = useMutation(GENERATE_API_KEY_MUTATION, {
    variables: { projectId, userId: getUserId() },
    refetchQueries
  })

  const [revokeApiKey] = useMutation(REVOKE_API_KEY_MUTATION, {
    variables: { projectId, userId: getUserId() },
    refetchQueries
  })

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error...</div>
  }

  const apiKeys = data.apiKeys.nodes

  const currentUserApiKeys = apiKeys.filter(k => k.user.id === getUserId())

  return (
    <>
      <PageHeader title='API keys' />

      {isAdmin() ? (
        <ApiKeyManagement
          {...props}
          apiKeys={apiKeys}
          generateApiKey={generateApiKey}
          revokeApiKey={revokeApiKey}
        />
      ) : (
        <FlashError>
          You must be a team admin to generate API keys and export labels.
        </FlashError>
      )}

      {currentUserApiKeys.length > 0 && (
        <>
          <PageHeader title='Bootstrap snippet' className='mt4' />
          <ProjectCodeSnippet
            apiKey={currentUserApiKeys[0].key}
            projectId={projectId}
          />
        </>
      )}
    </>
  )
}

export default ApiKeys
