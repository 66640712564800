import React from 'react'
import classNames from 'classnames'

interface FiltersDropdownProps {
  onClickOutside(): void
}

const makeFiltersDropdown = (Component, { size = 'sm' }: any = {}) =>
  class FiltersDropdown extends React.Component<FiltersDropdownProps> {
    handleClickOutside = () => this.props.onClickOutside()

    render () {
      const widthClass = size === 'md' ? 'w7' : 'w6'
      return (
        <div
          className={classNames([
            'bg-white br2 mt2 nowrap ba pa2 b--moon-gray shadow-3 lh-copy',
            widthClass
          ])}
        >
          <Component {...this.props} />
        </div>
      )
    }
  }

export default makeFiltersDropdown
