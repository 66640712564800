import React from 'react'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

import UserForm from '../components/user-form'
import { USERS_QUERY, ACTIVE_INVITATIONS_QUERY } from '../queries'

const INVITE_USER_MUTATION = gql`
  mutation InviteUserToTeam($input: InviteUserToTeamInput!) {
    inviteUserToTeam(input: $input) {
      clientMutationId
    }
  }
`

const NewUserPage = ({ navigate }) => {
  const [inviteUser] = useMutation(INVITE_USER_MUTATION, {
    refetchQueries: [{ query: USERS_QUERY }, { query: ACTIVE_INVITATIONS_QUERY }]
  })

  const handleInviteUser = async ({ variables }) => {
    await inviteUser({ variables })
    const flash =
      "User was invited successfully. We'll send you an email when they accept your invite."
    navigate('..', { state: { flash } })
  }

  return (
    <UserForm
      user={null}
      adminCount={0}
      isEditing={false}
      onSubmit={handleInviteUser}
    />
  )
}

export default NewUserPage
