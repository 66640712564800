
export enum TaskAction {
  Create = 'Create',
  Review = 'Review'
  // View = 'View'
}

export interface Project {
  name: string
  documentCategories: { nodes: DocumentCategory[] }
  fragmentCategories: { nodes: FragmentCategory[] }
}

export interface DocumentCategory {
  id: string
  name: string
  description: string
}

export interface FragmentCategory extends DocumentCategory {
  properties: { color: string }
}

export type Document = any

export interface Config {
  taskDefaults?: Partial<Task>
  fragmentCategories: FragmentCategory[]
  documentCategories: DocumentCategory[]
}

export type TaskType = 'classification' | 'fragment'

export interface Task {
  action: TaskAction,
  type: TaskType[]
  externalLabel?: any
  label?: any
}

export interface Metadata {
  progress: {
    complete: number,
    skipped: number,
    total: number
  }
}
