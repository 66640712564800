import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'

import { AppContainer } from 'react-hot-loader'

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  )
}

// removed so that JSON downloads don't get intercepted by client, see https://github.com/ReactTraining/react-router/issues/3109#issuecomment-408109557
// registerServiceWorker()

render(App)

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept('./app', () => {
    render(App)
  })
}
