import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { useAuth } from 'auth'
import { PageHeader } from 'common/components'
import Tile from 'common/components/tile'
import { confirmAction } from 'common/utils'
import DataTable, { RowAction } from 'common/components/data-table'
import {
  PROJECT_QUERY,
  CATEGORY_TYPES,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY
} from '../queries'

import DocumentCategoryForm from '../components/document-category-form'
import AnnotationCategoryForm from '../components/annotation-category-form'
import Swatch from '../components/swatch'

interface DocumentCategory {
  id: string
  name: string
  description: string
}

interface EntityCategory {
  id: string
  name: string
  description: string
  properties: {
    color: string
  }
}

const ProjectCategories = ({ project, removeCategory }) => {
  const projectId = project.id

  const { isAdmin } = useAuth()

  const [createCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [{ query: PROJECT_QUERY, variables: { projectId } }]
  })

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    refetchQueries: [{ query: PROJECT_QUERY, variables: { projectId } }]
  })

  const handleRemoveDocumentCategory = async ({ id: categoryId }) => {
    confirmAction('Are you sure you want to delete this category?')(() =>
      removeCategory({ variables: { categoryId } })
    )
  }

  const handleRemoveAnnotationCategory = async ({ id: categoryId }) => {
    confirmAction('Are you sure you want to delete this category?')(() =>
      removeCategory({ variables: { categoryId } })
    )
  }

  // TODO-3: show percentage of documents for each category in category table?
  const documentCategories = project.documentCategories.nodes as DocumentCategory[]
  const annotationCategories = project.annotationCategories.nodes as EntityCategory[]
  const canEditProject = isAdmin()

  const documentCategoryActions: RowAction<DocumentCategory>[] = canEditProject
    ? [
        {
          label: 'Edit',
          modalComponent: (data, handleCloseModal) => (
            <DocumentCategoryForm
              data={data}
              onSubmit={async ({ id: categoryId, ...rest }) => {
                await updateCategory({ variables: { categoryId, ...rest } })
                handleCloseModal()
              }}
              onCancel={handleCloseModal}
            />
          )
        },
        {
          label: 'Remove',
          handler: handleRemoveDocumentCategory
        }
      ]
    : []

  const documentCategoryTableActions = canEditProject
    ? [
        {
          label: 'Add document class',
          modalComponent: (_, closeModal) => (
            <DocumentCategoryForm
              onSubmit={async values => {
                await createCategory({
                  variables: {
                    projectId,
                    type: CATEGORY_TYPES.DOCUMENT,
                    ...values
                  }
                })
                closeModal()
              }}
              onCancel={closeModal}
            />
          )
        }
      ]
    : []

  const annotationCategoryActions: RowAction<EntityCategory>[] = canEditProject
    ? [
        {
          label: 'Edit',
          modalComponent: (data, handleCloseModal) => (
            <AnnotationCategoryForm
              data={data}
              onSubmit={async ({ id: categoryId, color, ...rest }) => {
                await updateCategory({
                  variables: {
                    categoryId,
                    properties: { color },
                    ...rest
                  }
                })
                handleCloseModal()
              }}
              onCancel={handleCloseModal}
            />
          )
        },
        {
          label: 'Remove',
          handler: handleRemoveAnnotationCategory
        }
      ]
    : []

  const annotationCategoryTableActions = canEditProject
    ? [
        {
          label: 'Add annotation class',
          modalComponent: (_, closeModal) => {
            const handleCreateCategory = async values => {
              await createCategory(values)
              closeModal()
            }

            return (
              <AnnotationCategoryForm
                onSubmit={({ color, ...rest }) =>
                  handleCreateCategory({
                    variables: {
                      projectId,
                      type: CATEGORY_TYPES.FRAGMENT,
                      properties: { color },
                      ...rest
                    }
                  })
                }
                onCancel={closeModal}
              />
            )
          }
        }
      ]
    : []

  return (
    <>
      <PageHeader title='Classes' />

      <Tile title='Document classes' className='mb4'>
        <DataTable
          data={documentCategories}
          columns={[{ Header: 'Name', accessor: 'name' }]}
          rowActions={documentCategoryActions}
          tableActions={documentCategoryTableActions}
        />
      </Tile>

      <Tile title='Annotation classes'>
        <DataTable
          data={annotationCategories}
          columns={[
            { Header: 'Name', accessor: 'name' },
            {
              Header: 'Color',
              id: 'properties',
              accessor: ({ properties }: EntityCategory) => (
                <Swatch color={properties.color} />
              )
            }
          ]}
          rowActions={annotationCategoryActions}
          tableActions={annotationCategoryTableActions}
        />
      </Tile>
    </>
  )
}

const ConnectedProjectCategories = props => {
  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [
      { query: PROJECT_QUERY, variables: { projectId: props.project.id } }
    ]
  })

  return <ProjectCategories {...props} removeCategory={deleteCategory} />
}

export default ConnectedProjectCategories
