import React from 'react'
import { Formik, Form } from 'formik'
import onClickOutside from 'react-onclickoutside'

import { Button, ButtonContainer } from 'common/components'
import { ReactSelectField } from 'common/components/form'

import {
  FilterControls,
  makeFiltersDropdown,
  useFilters
} from '../../components/filters'
import { buildSubmitHandler } from 'common/form-helpers'

const FiltersForm = props => (
  <Formik
    initialValues={{
      documentCategory: props.activeDocumentCategory,
      annotationCategory: props.activeAnnotationCategory
    }}
    onSubmit={buildSubmitHandler(props.onSaveFilters)}
    enableReinitialize={true}
  >
    {({ values, setFieldValue, isSubmitting }) => (
      <Form>
        <div className='cf'>
          <div className='fl w-50 pa2'>
            <ReactSelectField
              label='Document categories'
              name='documentCategory'
              placeholder='All document categories'
              value={values.documentCategory}
              onChange={category => setFieldValue('documentCategory', category)}
              options={props.documentCategories}
              selectProps={{
                isClearable: true,
                getOptionLabel: opt => opt.name,
                getOptionValue: opt => opt.id
              }}
              containerProps={{ className: 'mb2' }}
            />
          </div>
          <div className='fl w-50 pa2'>
            <ReactSelectField
              label='Annotation categories'
              name='annotationCategory'
              placeholder='All annotation categories'
              value={values.annotationCategory}
              onChange={category => setFieldValue('annotationCategory', category)}
              options={props.annotationCategories}
              selectProps={{
                isClearable: true,
                getOptionLabel: opt => opt.name,
                getOptionValue: opt => opt.id
              }}
              containerProps={{ className: 'mb2' }}
            />
          </div>
        </div>
        <ButtonContainer>
          <Button
            onClick={props.onClearFilters}
            disabled={isSubmitting}
            variant='secondary-red'
          >
            Clear filters
          </Button>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Applying filters...' : 'Apply filters'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

const DocumentFiltersDropdown = onClickOutside(
  makeFiltersDropdown(FiltersForm, { size: 'md' })
)

interface DocumentListingFiltersProps {
  query: any
  onChange(any): any
  documentCategories: any
  annotationCategories: any
}

const DocumentListingFilters = ({
  onChange,
  documentCategories,
  annotationCategories,
  query
}: DocumentListingFiltersProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const options = {
    documentCategory: documentCategories.nodes,
    annotationCategory: annotationCategories.nodes
  }

  const labelMap = { documentCategory: 'Category', annotationCategory: 'Category' }

  const { currentFilters, removeFilter, clearFilters } = useFilters(
    options,
    onChange,
    query
  )

  const handleSaveFilters = values => {
    const { annotationCategory, documentCategory } = values
    const { id: documentCategoryId } = documentCategory || { id: undefined }
    const { id: annotationCategoryId } = annotationCategory || { id: undefined }
    onChange({
      documentCategory: documentCategoryId,
      annotationCategory: annotationCategoryId
    })
    setIsOpen(false)
  }

  const handleClearFilters = () => {
    clearFilters()
    setIsOpen(false)
  }

  return (
    <FilterControls
      isOpen={isOpen}
      onOpenModal={() => setIsOpen(true)}
      currentFilters={currentFilters}
      onRemoveFilter={removeFilter}
      labelMap={labelMap}
      filtersModal={() => (
        <DocumentFiltersDropdown
          onClickOutside={() => setIsOpen(false)}
          onClearFilters={handleClearFilters}
          onSaveFilters={handleSaveFilters}
          documentCategories={documentCategories.nodes}
          activeDocumentCategory={currentFilters.documentCategory}
          annotationCategories={annotationCategories.nodes}
          activeAnnotationCategory={currentFilters.annotationCategory}
        />
      )}
    />
  )
}

export default DocumentListingFilters
