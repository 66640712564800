import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { Badge, Code, DataTable, PageHeader } from 'common/components'
import Datetime from 'common/components/datetime'

import WebhookForm from '../components/form'
import {
  EDIT_WEBHOOK_MUTATION,
  WEBHOOK_DETAILS_QUERY,
  WEBHOOK_LISTING_QUERY
} from '../queries'

interface WebhookDelivery {
  event: string
  status: string
  updatedAt: Date
}

const WebhookDeliveriesTable = ({ webhook }) => {
  return (
    <>
      <DataTable
        data={webhook.webhookDeliveries.nodes}
        columns={[
          {
            Header: 'Event',
            accessor: ({ event }: WebhookDelivery) => <Code>{event}</Code>
          },
          { Header: 'Attempt #', accessor: 'attemptNumber' },
          {
            Header: 'Status',
            accessor: ({ status: s }: WebhookDelivery) => {
              const status = parseInt(s)
              const type = status >= 200 && status < 300 ? 'success' : 'error'
              return <Badge type={type}>{status}</Badge>
            }
          },
          {
            Header: 'Sent',
            accessor: ({ updatedAt }: WebhookDelivery) => (
              <Datetime human datetime={updatedAt} />
            )
          }
        ]}
      />
    </>
  )
}

interface IEditWebhookPage extends RouteComponentProps {
  webhookId: string
  project: any
}

const EditWebhookPage = (props: IEditWebhookPage) => {
  const { webhookId, project, navigate } = props

  const { data, error, loading } = useQuery(WEBHOOK_DETAILS_QUERY, {
    variables: { webhookId }
  })
  const [editWebhook] = useMutation(EDIT_WEBHOOK_MUTATION, {
    refetchQueries: [
      { query: WEBHOOK_LISTING_QUERY, variables: { projectId: project.id } }
    ]
  })

  if (loading) {
    return <>loading...</>
  }
  if (error) {
    return <>error loading webhook</>
  }

  const { webhookById: webhook } = data

  const handleSaveWebhook = async values => {
    await editWebhook({
      variables: { ...values, id: webhookId, projectId: project.id }
    })
    navigate('..', { state: { flash: 'Webhook was successfully updated.' } })
  }

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error</div>
  }

  return (
    <>
      <PageHeader title='Editing webhook' />
      <WebhookForm webhook={webhook} onSubmit={handleSaveWebhook} />

      <PageHeader title='Recent deliveries' className='mt4' />
      <WebhookDeliveriesTable webhook={webhook} />
    </>
  )
}

export default EditWebhookPage
