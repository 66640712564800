import get from 'lodash/get'

const useFilters = (options, onChange, query) => {
  const currentFilters: any = Object.keys(options).reduce((acc, k) => {
    const activeCategory = options[k].find(c => c.id === query[k]) || null // null is necessary to clear out ReactSelect

    return { ...acc, [k]: activeCategory }
  }, {})

  const removeFilter = filterType => {
    const existingFilters = Object.keys(options).reduce(
      (acc, k) => ({
        ...acc,
        [k]: get(currentFilters[k], 'id')
      }),
      {}
    )

    if (options[filterType]) {
      delete existingFilters[filterType]
    }

    onChange(existingFilters)
  }

  const clearFilters = () => {
    onChange(null)
  }

  return {
    currentFilters,
    removeFilter,
    clearFilters
  }
}

export default useFilters
