import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import onClickOutside from 'react-onclickoutside'

import { Button, ButtonContainer } from 'common/components'
import { ReactSelectField } from 'common/components/form'

import { useParams } from 'common/location'
import { FilterControls, makeFiltersDropdown, useFilters } from './filters'
import { buildSubmitHandler } from 'common/form-helpers'

const LabelFiltersForm = props => (
  <Formik
    initialValues={{
      cycle: props.activeCycle,
      source: props.activeSource,
      documentCategory: props.activeDocumentCategory,
      annotationCategory: props.activeAnnotationCategory
    }}
    onSubmit={buildSubmitHandler(props.onSaveFilters)}
    enableReinitialize={true}
  >
    {({ values, setFieldValue, isSubmitting }) => (
      <Form>
        <div className='cf'>
          <div className='fl w-50 pa2'>
            <ReactSelectField
              label='Cycle'
              name='cycle'
              placeholder='All cycles'
              value={values.cycle}
              onChange={val => setFieldValue('cycle', val)}
              options={props.cycles}
              selectProps={{
                isClearable: true,
                getOptionLabel: opt => opt.name,
                getOptionValue: opt => opt.id
              }}
              containerProps={{ className: 'mb2' }}
            />
          </div>
          <div className='fl w-50 pa2'>
            <ReactSelectField
              label='Source'
              name='source'
              placeholder='All creators / sources'
              value={values.source}
              onChange={val => setFieldValue('source', val)}
              options={props.sources}
              selectProps={{
                isClearable: true,
                getOptionLabel: opt => opt.name,
                getOptionValue: opt => opt.id
              }}
              containerProps={{ className: 'mb2' }}
            />
          </div>
        </div>

        <div className='cf'>
          <div className='fl w-50 pa2'>
            <ReactSelectField
              label='Document class'
              name='documentCategory'
              placeholder='All document classes'
              value={values.documentCategory}
              onChange={val => setFieldValue('documentCategory', val)}
              options={props.documentCategories}
              selectProps={{
                isClearable: true,
                getOptionLabel: opt => opt.name,
                getOptionValue: opt => opt.id
              }}
              containerProps={{ className: 'mb2' }}
            />
          </div>
          <div className='fl w-50 pa2'>
            <ReactSelectField
              label='Annotation class'
              name='annotationCategory'
              placeholder='All annotation classes'
              value={values.annotationCategory}
              onChange={val => setFieldValue('annotationCategory', val)}
              options={props.annotationCategories}
              selectProps={{
                isClearable: true,
                getOptionLabel: opt => opt.name,
                getOptionValue: opt => opt.id
              }}
              containerProps={{ className: 'mb2' }}
            />
          </div>
        </div>

        <ButtonContainer>
          <Button
            onClick={props.onClearFilters}
            disabled={isSubmitting}
            variant='secondary-red'
          >
            Clear filters
          </Button>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Applying filters...' : 'Apply filters'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

interface LabelListingFiltersProps {
  onChange(any): any
  cycles: any
  sources: any
  documentCategories: any
  annotationCategories: any
}

const LabelFiltersDropdown = onClickOutside(
  makeFiltersDropdown(LabelFiltersForm, { size: 'md' })
)

const LabelListingFilters = ({
  onChange,
  cycles,
  sources,
  documentCategories,
  annotationCategories
}: LabelListingFiltersProps) => {
  const params = useParams()
  const [isOpen, setIsOpen] = useState(false)

  const options = {
    cycle: cycles,
    source: sources,
    documentCategory: documentCategories,
    annotationCategory: annotationCategories
  }

  const labelMap = {
    cycle: 'Cycle',
    source: 'Source',
    documentCategory: 'Category',
    annotationCategory: 'Category'
  }

  const { currentFilters, removeFilter, clearFilters } = useFilters(
    options,
    onChange,
    params
  )

  const handleSaveFilters = ({
    cycle,
    source,
    documentCategory,
    annotationCategory
  }) => {
    let filters: any = {}
    if (cycle) {
      filters.cycle = cycle.id
    }
    if (source) {
      filters.source = source.id
    }
    if (documentCategory) {
      filters.documentCategory = documentCategory.id
    }
    if (annotationCategory) {
      filters.annotationCategory = annotationCategory.id
    }

    onChange(filters)
    setIsOpen(false)
  }

  const handleClearFilters = () => {
    clearFilters()
    setIsOpen(false)
  }

  return (
    <FilterControls
      isOpen={isOpen}
      onOpenModal={() => setIsOpen(true)}
      currentFilters={currentFilters}
      onRemoveFilter={removeFilter}
      labelMap={labelMap}
      filtersModal={() => (
        <LabelFiltersDropdown
          onClickOutside={() => setIsOpen(false)}
          onClearFilters={handleClearFilters}
          onSaveFilters={handleSaveFilters}
          cycles={cycles}
          activeCycle={currentFilters.cycle}
          sources={sources}
          activeSource={currentFilters.source}
          documentCategories={documentCategories}
          activeDocumentCategory={currentFilters.documentCategory}
          annotationCategories={annotationCategories}
          activeAnnotationCategory={currentFilters.annotationCategory}
        />
      )}
    />
  )
}

export default LabelListingFilters
