import React, { FC } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import isEmpty from 'lodash/isEmpty'

import { Button, ButtonContainer } from 'common/components'
import { InputField, FreeTextField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

interface Props {
  data?: any
  onSubmit: any
  onCancel: any
}

const DocumentCategoryForm: FC<Props> = ({ data = {}, onSubmit, onCancel }) => (
  <Formik
    initialValues={{
      name: data.name || '',
      description: data.description || ''
    }}
    validationSchema={yup.object().shape({
      name: yup.string().required('Name is required.')
    })}
    onSubmit={buildSubmitHandler(onSubmit, { reshape: (values) => ({ ...values, id: data.id }) })}
  >
    {({ handleChange, touched, values, errors = {}, isSubmitting }) => (
      <Form>
        <h3 className='mt0'>{isEmpty(data) ? 'Add' : 'Edit'} document category</h3>

        <InputField
          label='Name'
          name='name'
          value={values.name}
          onChange={handleChange}
          touched={touched.name}
          error={errors.name}
        />

        <FreeTextField
          label='Description'
          name='description'
          value={values.description}
          onChange={handleChange}
          touched={touched.description}
          error={errors.description}
        />

        <ButtonContainer>
          <Button variant='secondary-red' onClick={onCancel}>
            Cancel
          </Button>

          <Button type='submit' disabled={isSubmitting}>
            Save document category
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

export default DocumentCategoryForm
