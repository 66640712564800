import React from 'react'
import tw from 'twin.macro'

import { Percentage } from 'project/components/completion-percentage'
import ProgressBar from 'common/components/progress-bar'

const ProgressMeter = ({
  compact = false,
  textSummary = <React.Fragment />,
  title = '',
  complete,
  total
}) => {
  const renderSummary = () => (
    <div tw='flex flex-row items-baseline'>
      <p tw='flex-1'>{textSummary}</p>
      {compact && (
        <div tw='flex-none font-bold text-right'>
          <Percentage count={complete} total={total} />
        </div>
      )}
    </div>
  )

  return (
    <div tw='p-2 border border-gray-200 bg-gray-50 text-sm' css={compact && tw`mb-4`}>
      {!compact ? (
        <div tw='flex flex-row justify-between'>
          <div tw='flex-1'>
            <h6 tw='uppercase text-gray-500'>{title}</h6>
          </div>
          <div tw='flex-none ml-4 text-right font-bold'>
            <Percentage count={complete} total={total} />
          </div>
        </div>
      ) : (
        renderSummary()
      )}
      <div tw='my-2'>
        <ProgressBar percent={(complete / total) * 100} />
      </div>
      {!compact && renderSummary()}
    </div>
  )
}

export default ProgressMeter
