import React from 'react'
import classNames from 'classnames'

const SlabStatsItem = ({ name, children }) => (
  <dl className='dib mr4 mv0'>
    <dd className='f3 b ml0 mb1'>{children}</dd>
    <dd className='f5 ml0'>{name}</dd>
  </dl>
)

class SlabStats extends React.Component<any> {
  static Item = SlabStatsItem

  render () {
    const { title = '', last = false, children, className } = this.props
    return (
      <div className={classNames(['mr4', !last && 'br b--moon-gray', className])}>
        {title && <h4 className='f5 ttu mt0 mb1 mr4'>{title}</h4>}
        <div className='inline-flex flex-row'>{children}</div>
      </div>
    )
  }
}

export default SlabStats
