interface Permissions {
  role: 'admin' | 'member'
}

export const getRoleStringFromPermissions = (permissions: Permissions) => {
  const roles = {
    admin: 'Administrator',
    member: 'Team Member'
  }
  return roles[permissions.role]
}
