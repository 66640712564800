import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { cleanGraphQLErrorMessage } from 'common/utils'
import { Button, ButtonContainer } from 'common/components'
import { InputField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

const passwordFormSchema = yup.object().shape({
  currentPassword: yup.string().required('You must verify your existing password.'),
  newPassword: yup
    .string()
    .required('You must enter a password.')
    .min(8, 'Your password must be at least 8 characters.'),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match.')
})

const ChangePasswordForm = ({ onSubmit, onClose }) => {
  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', verifyPassword: '' }}
      validationSchema={passwordFormSchema}
      onSubmit={buildSubmitHandler(onSubmit, {
        reshape: ({ currentPassword, newPassword }) => ({
          variables: { currentPassword, newPassword }
        }),
        errors: (err) => ({ currentPassword: cleanGraphQLErrorMessage(err.message) })
      })}
    >
      {({ handleChange, touched, values, errors = {}, isSubmitting }) => (
        <Form>
          <h3 className='mt0 bb'>Change Password</h3>
          <InputField
            label='Current Password'
            name='currentPassword'
            type='password'
            onChange={handleChange}
            touched={touched.currentPassword}
            value={values.currentPassword}
            error={errors.currentPassword}
            containerProps={{ className: 'mb3' }}
          />
          <InputField
            label='New Password'
            name='newPassword'
            type='password'
            onChange={handleChange}
            touched={touched.newPassword}
            value={values.newPassword}
            error={errors.newPassword}
            containerProps={{ className: 'mb3' }}
            helperText='Min length of 8 characters.'
          />
          <InputField
            label='Verify Password'
            name='verifyPassword'
            type='password'
            onChange={handleChange}
            touched={touched.verifyPassword}
            value={values.verifyPassword}
            error={errors.verifyPassword}
            containerProps={{ className: 'mb3' }}
          />
          <ButtonContainer>
            <Button variant='secondary-gray' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save Password'}
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($currentPassword: String!, $newPassword: String!) {
    changePassword(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      clientMutationId
    }
  }
`

const ConnectedChangePasswordForm = props => {
  const { onPasswordChange, onClose } = props
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION)

  const handleChangePassword = async values => {
    await changePassword(values)
    onPasswordChange('Your password was successfully changed.')
    onClose()
  }

  return <ChangePasswordForm {...props} onSubmit={handleChangePassword} />
}

export default ConnectedChangePasswordForm
