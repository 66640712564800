import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const FileList = ({ files }) => (
  <div className='bg-white pa3 f5'>
    {files.length > 0 ? (
      <div>
        <h4 className='mt0'>Uploading {files.length} files</h4>
        <ul className='mt3 list pa0'>
          {files.map((file, key) => (
            <li className='pa3 ba b--moon-gray mb2 flex items-center' key={key}>
              <span className='flex-4'>{file.name}</span>
              <span className='flex-1 tr'>
                <div className='f3 mr3'>
                  {file.isUploading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      inverse
                      className='green-color'
                    />
                  )}
                </div>
              </span>
            </li>
          ))}
        </ul>
      </div>
    ) : (
      <div>No files selected for upload</div>
    )}
  </div>
)

export default FileList
