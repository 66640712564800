import React from 'react'
import { Router } from '@reach/router'

import DocumentListing from './pages/listing'
import UploadPage from './pages/upload'
import DocumentPage from './pages/document'

const DocumentRoutes = ({ project, auth }) => (
  <Router primary={false}>
    {/*
    // @ts-ignore */}
    <DocumentListing exact path='/' project={project} auth={auth} />
    <UploadPage exact path='upload' project={project} auth={auth} />
    {/*
    // @ts-ignore */}
    <DocumentPage path=':documentId' project={project} auth={auth} />
  </Router>
)

export default DocumentRoutes
