import tw, { styled } from 'twin.macro'
// TODO: create wrapper that by default align-rights buttons and adds spacing between, convert everywhere needed

// classes supplied to <Button> through app
// {
//   "mr3": 9,
//   "fr": 7,
//   "mv2": 6,
//   "mt3": 5,
//   "ph4": 4,
//   "w-100": 3,
//   "mr2": 2,
//   "mt2": 2,
//   "inverted": 2,
//   "ttu": 2,
//   "underline-hover": 2,
//   "ph2": 2,
//   "bn": 2,
//   "f6": 2,
//   "w4": 1,
//   "flex-0": 1,
//   "v2": 1
// }

interface Props {
  left?: boolean
  center?: boolean
  expand?: boolean
  noMargin?: boolean
}

const ButtonContainer = styled.div<Props>`
  ${({ expand = false, center = false, left = false }) =>
    expand
      ? tw`justify-between`
      : center
      ? tw`justify-center`
      : left
      ? tw`justify-start`
      : tw`justify-end`}
  ${({ noMargin }) => !noMargin && tw`mt-4`}
  ${tw`flex flex-row`}
  > * {
    ${tw`mr-4`}
  }
  > *:last-child {
    ${tw`mr-0`}
  }
`

export default ButtonContainer
