import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard as RCopyToClipboard } from 'react-copy-to-clipboard'

const CopyToClipboard = ({ value, visibleText = false }) => {
  const [isCopied, setIsCopied] = React.useState(false)

  const timeoutRef = React.useRef(null)
  React.useEffect(() => () => clearTimeout(timeoutRef.current), [])

  const handleCopied = () => {
    setIsCopied(true)

    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => setIsCopied(false), 1500)
  }

  return (
    // @ts-ignore
    <span className='ml3 nowrap' alt='Copy to clipboard'>
      <RCopyToClipboard text={value} onCopy={handleCopied}>
        <span className='pointer'>
          <FontAwesomeIcon icon={faCopy} className='mr2' />
          {visibleText ? (
            <span>{isCopied ? 'copied' : 'copy to clipboard'}</span>
          ) : (
            <span className={isCopied ? 'fade-in' : 'fade-out'}>copied</span>
          )}
        </span>
      </RCopyToClipboard>
    </span>
  )
}
export default CopyToClipboard
