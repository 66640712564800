import React, { useState } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { useProfileService, CURRENT_USER_PROFILE_QUERY } from 'common/profile'
import ModalDialog from 'common/components/modal-dialog'
import { Button, ButtonContainer } from 'common/components'
import { Field, InputField } from 'common/components/form'
import FormGroup from 'common/components/form-group'
import { FlashSuccess } from 'common/flash'

import ChangePasswordForm from './change-password-form'
import { buildSubmitHandler } from 'common/form-helpers'

const ProfileForm = ({ onSubmit, profile, formFlash }) => {
  const [isPasswordFormOpen, setIsPasswordFormOpen] = useState(false)
  const [passwordMessage, setPasswordMessage] = useState(null)

  return (
    <>
      <Formik
        initialValues={{
          email: profile.email,
          name: profile.name
        }}
        validationSchema={profileFormSchema}
        onSubmit={buildSubmitHandler(onSubmit, { shouldResetForm: true })}
      >
        {({ handleChange, touched, values, errors = {}, isSubmitting }) => (
          <Form>
            {formFlash && <FlashSuccess>{formFlash}</FlashSuccess>}

            <FormGroup
              title='Profile'
              description='This information is public and available to all other members of your team.'
            >
              <InputField
                label='Name'
                name='name'
                onChange={handleChange}
                touched={touched.name}
                value={values.name}
                error={errors.name}
                containerProps={{ className: 'mb3' }}
              />
            </FormGroup>

            <FormGroup title='Account' noBorder>
              <InputField
                label='Email'
                name='email'
                onChange={handleChange}
                touched={touched.email}
                value={values.email}
                error={errors.email}
                containerProps={{ className: 'mb3' }}
              />

              <Field label={'Password'}>
                {passwordMessage && <FlashSuccess>{passwordMessage}</FlashSuccess>}
                <Button
                  variant='secondary-blue'
                  onClick={() => setIsPasswordFormOpen(true)}
                >
                  Change Password
                </Button>
              </Field>

              <p>
                Need to delete your account?{' '}
                <a className='link-primary' href='mailto:support@markers.ai'>
                  Contact us
                </a>{' '}
                and we'll be happy to help.
              </p>
            </FormGroup>

            <ButtonContainer>
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? 'Saving...' : 'Save changes'}
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
      <ModalDialog
        isOpen={isPasswordFormOpen}
        onRequestClose={() => setIsPasswordFormOpen(false)}
      >
        <ChangePasswordForm
          onClose={() => setIsPasswordFormOpen(false)}
          onPasswordChange={msg => setPasswordMessage(msg)}
        />
      </ModalDialog>
    </>
  )
}

const profileFormSchema = yup.object().shape({
  name: yup.string(),
  email: yup.string().email("This doesn't look like a valid email address.")
})

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: UUID!, $email: String, $name: String) {
    updateUserById(input: { id: $id, patch: { email: $email, name: $name } }) {
      user {
        nodeId
        id
        name
        email
      }
    }
  }
`

const ProfilePage = () => {
  const [formFlash, setFormFlash] = useState(null)
  const { profile, reloadProfile } = useProfileService()
  const [updateUser] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [{ query: CURRENT_USER_PROFILE_QUERY }]
  })

  if (!profile) {
    return null
  }

  const handleSaveProfile = async variables => {
    await updateUser({ variables })
    await reloadProfile()
    setFormFlash('Your profile was updated successfully.')
  }

  return (
    <ProfileForm
      profile={profile}
      formFlash={formFlash}
      onSubmit={values => handleSaveProfile({ id: profile.id, ...values })}
    />
  )
}

export default ProfilePage
