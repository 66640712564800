import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'

import { authInstance } from 'auth/service'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors && process.env.NODE_ENV !== 'production') {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)

    // NOTE: postgraphile jwt errors are either 401 or 403
    //       https://github.com/graphile/postgraphile/blob/676b3525522c5025a31158e7364d90fe06ef0011/src/postgraphile/withPostGraphileContext.ts#L291-L296
    // @ts-ignore
    if (networkError.statusCode === 401) {
      authInstance.logout(client)
    }
  }
})

const authLink = setContext((_, { headers }) => {
  if (authInstance.isAuthenticated()) {
    const token = authInstance.getAuthToken()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  } else {
    return { headers }
  }
})

const uploadLink = createUploadLink({ uri: '/graphql' })

const cache = new InMemoryCache({
  // @ts-ignore
  dataIdFromObject: object => object.nodeId || null
})

export const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(uploadLink),
  cache
})
