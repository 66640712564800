import React, { useContext, createContext } from 'react'
import queryString from 'query-string'
import { Location, WindowLocation } from '@reach/router'

interface Context {
  location: WindowLocation
}

const LocationContext = createContext({} as Context)

export const LocationHookProvider = props => {
  // NOTE: this is a temporary hack until conversnion to react-router v6 (which provides useLocation out of the box)

  return (
    <Location>
      {({ location }) => <LocationContext.Provider value={{ location }} {...props} />}
    </Location>
  )
}

export const useLocation = () => {
  const { location } = useContext(LocationContext)
  return location
}

const parseQueryParams = location => queryString.parse(location?.search ?? '')

export const useParams = () => {
  const location = useLocation()
  const params = parseQueryParams(location)

  return params
}
