import React from 'react'
import classNames from 'classnames'

import { ProductType, BillingCycle } from 'common/types'
import { RadioGroup, RadioOption } from 'common/components/form'

const FrequencyButton = ({ isActive, onClick, children }) => {
  const classes = classNames([
    'flex-0 pv2 ph3 mr4 br2 ba bw1 pointer outline-0 center',
    isActive
      ? 'bg-lighterest-blue b b--light-blue'
      : 'bg-off-white gray b b--near-white hover-bg-washed-blue'
  ])
  return (
    <button onClick={onClick} className={classes}>
      {children}
    </button>
  )
}

const PlanTitle = ({ name, priceText }) => (
  <div className='flex flex-row'>
    <div className='flex-1 b'>{name}</div>
    <div className='flex-0'>{priceText}</div>
  </div>
)

interface PlanSelectionFormProps {
  billingFrequency: BillingCycle
  onFrequencySelect: (arg1: BillingCycle) => void
  selectedPlan: ProductType
  onPlanSelect: (arg1: ProductType) => void
  plans: object
}

const PlanSelectionForm = ({
  billingFrequency,
  onFrequencySelect,
  selectedPlan,
  onPlanSelect,
  plans
}: PlanSelectionFormProps) => {
  const indieMonthly = plans[ProductType.INDIE][BillingCycle.MONTHLY]
  const indieYearly = plans[ProductType.INDIE][BillingCycle.YEARLY]
  const teamMonthly = plans[ProductType.TEAM][BillingCycle.MONTHLY]
  const teamYearly = plans[ProductType.TEAM][BillingCycle.YEARLY]

  const indiePriceText =
    billingFrequency === BillingCycle.YEARLY
      ? `$${indieYearly.price}/yr`
      : `$${indieMonthly.price}/mo`
  const teamPriceText =
    billingFrequency === BillingCycle.YEARLY
      ? `$${teamYearly.price}/yr`
      : `$${teamMonthly.price}/mo`

  return (
    <>
      <div className='flex justify-center mb3'>
        <div className='tc mr3'>
          <FrequencyButton
            isActive={billingFrequency === BillingCycle.MONTHLY}
            onClick={() => onFrequencySelect(BillingCycle.MONTHLY)}
          >
            monthly
          </FrequencyButton>
        </div>
        <div className='tc ml3'>
          <FrequencyButton
            isActive={billingFrequency === BillingCycle.YEARLY}
            onClick={() => onFrequencySelect(BillingCycle.YEARLY)}
          >
            yearly
          </FrequencyButton>
          <p className='mb0 mt1 f6'>2 months free</p>
        </div>
      </div>

      <RadioGroup>
        <RadioOption
          title={() => <PlanTitle name='Indie' priceText={indiePriceText} />}
          name='plan'
          description='1 data scientist, 3 annotators. Up to 50k documents and 250k labels.'
          value={'indie'}
          isSelected={selectedPlan === ProductType.INDIE}
          onChange={() => onPlanSelect(ProductType.INDIE)}
        />
        <RadioOption
          title={() => <PlanTitle name='Team' priceText={teamPriceText} />}
          name='plan'
          description='3 data scientists, 10 annotators. Up to 500k documents and 5 million labels.'
          value={'team'}
          isSelected={selectedPlan === ProductType.TEAM}
          onChange={() => onPlanSelect(ProductType.TEAM)}
        />
      </RadioGroup>
    </>
  )
}

export default PlanSelectionForm
