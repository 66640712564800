import React, { FC } from 'react'
import { Router, RouteComponentProps } from '@reach/router'

import AnnotatorPage from 'annotator'
import ProjectRoute from './'
import ProjectListing from './pages/projects'

const ProjectRoutes: FC<RouteComponentProps> = () => (
  <Router primary={false}>
    <ProjectListing path='/' />
    <AnnotatorPage path='/:projectId/annotate' />
    <ProjectRoute path='/:projectId/*' />
  </Router>
)

export default ProjectRoutes
