import React from 'react'
import { Router, Redirect } from '@reach/router'
import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'

import { useAuth } from 'auth'
import { useProfileService } from 'common/profile'
import Subnav from 'common/components/subnav'
import PageHeading from 'common/components/page-heading'
import { Tier3Nav, T3NavItem } from 'common/components'

const cleanRouteUrl = url => {
  if (url === '/') {
    return './'
  } else {
    return url.slice(-2) === '/*' ? url.slice(0, -2) : url
  }
}

interface RouteOptions {
  defaultRoute?: string
}

export const buildSubnavAndRoutes = (routes, opts: RouteOptions = {}) => ({
  title,
  ...props
}) => {
  const { plan } = useProfileService()
  const { isAdmin } = useAuth()

  const subnav = (
    <Subnav>
      {routes
        .filter(({ excludeFromNav = false }) => !excludeFromNav)
        .filter(route => {
          const check = route.check ? route.check : () => true
          return check({ props, isAdmin, plan })
        })
        .map((route, index, collection) => {
          return (
            <Subnav.Item
              key={index}
              exact={route.exact}
              to={cleanRouteUrl(route.url)}
              last={index === collection.length - 1}
            >
              {typeof route.name === 'function' ? route.name(props) : route.name}
            </Subnav.Item>
          )
        })}
    </Subnav>
  )

  const router = (
    <Router primary={false}>
      {routes
        .filter(route => {
          const check = route.check ? route.check : () => true
          return check({ props, isAdmin, plan })
        })
        .map((route, key) => (
          <route.component {...props} key={key} path={route.url} />
        ))}
      {opts.defaultRoute ? (
        <Redirect noThrow default from='.' to={opts.defaultRoute} />
      ) : null}
    </Router>
  )

  return (
    <>
      <PageHeading title={title}>{subnav}</PageHeading>

      {router}
    </>
  )
}

export const updateQueryParams = (location, queryParams) => {
  const { pathname } = location
  const newQueryString = queryString.stringify(queryParams)

  return isEmpty(queryParams) ? pathname : `${pathname}?${newQueryString}`
}

export const buildTier3SubnavAndRoutes = routes => props => {
  const { plan } = useProfileService()
  const { isAdmin } = useAuth()

  const subnav = (
    <Tier3Nav>
      {routes
        .filter(({ excludeFromNav = false }) => !excludeFromNav)
        .filter(route => {
          const check = route.check ? route.check : () => true
          return check({ props, isAdmin, plan })
        })
        .map(({ component, url, ...route }, key) => (
          // @ts-ignore
          <T3NavItem key={key} to={cleanRouteUrl(url)} {...route} />
        ))}
    </Tier3Nav>
  )

  const router = (
    <Router primary={false}>
      {routes
        .filter(route => {
          const check = route.check ? route.check : () => true
          return check({ props, isAdmin, plan })
        })
        .map(({ component: Component, ...route }, key) => (
          <Component {...props} key={key} path={route.url} />
        ))}
    </Router>
  )

  return (
    <div className='flex flex-row'>
      <div className='flex-1'>{subnav}</div>

      <div className='flex-3'>{router}</div>
    </div>
  )
}

export const updateQueryAndFetch = (location, navigate, query) => {
  const updatedPath = updateQueryParams(location, query)
  navigate(updatedPath, { replace: true })
}
