import React, { FC } from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import { Plan } from 'common/types'
import { formatDollars } from 'common/utils'
import { Button, ButtonContainer, LinkButton } from 'common/components'
import Datetime from 'common/components/datetime'

const CREATE_UPDATE_CHECKOUT_SESSION_MUTATION = gql`
  mutation {
    createUpdateCheckoutSession {
      checkoutSessionId
      setupIntentId
    }
  }
`

interface SubscribedBillingPageProps {
  plan: Plan
  plans: object
}

const SubscribedBillingPage: FC<SubscribedBillingPageProps> = ({ plan, plans }) => {
  const [createUpdateCheckoutSession] = useMutation(
    CREATE_UPDATE_CHECKOUT_SESSION_MUTATION
  )

  const Stripe = (window as any).Stripe
  const stripe = Stripe
    ? Stripe(process.env.REACT_APP_STRIPE_API_PUBLISHABLE_KEY)
    : null

  const handleChangeSubscription = async () => {
    // @ts-ignore
    window.stripe = stripe
    const {
      data: {
        createUpdateCheckoutSession: { checkoutSessionId }
      }
    } = await createUpdateCheckoutSession()

    const { error } = stripe.redirectToCheckout({ sessionId: checkoutSessionId })
    if (error) {
      // TODO-2: better error handling
      console.error(error)
    }
  }

  const nextInvoiceAmount = plans[plan.planType][plan.billingCycle].price

  return (
    <>
      <p>
        You are subscribed to the <strong>{plan.planType.toLowerCase()}</strong> plan
        billed <strong>{plan.billingCycle.toLowerCase()}</strong>.{' '}
        {plan.autoRenew ? (
          <>
            Your plan renews on{' '}
            <strong>
              <Datetime datetime={plan.expiry} />
            </strong>{' '}
            and your card will be charged{' '}
            <strong>{formatDollars(nextInvoiceAmount)}</strong> on that date.
          </>
        ) : (
          <>
            Your subscription ends on{' '}
            <strong>
              <Datetime datetime={plan.expiry} />
            </strong>{' '}
            and your plan <strong>will not</strong> auto-renew.
          </>
        )}
      </p>
      {plan.autoRenew ? (
        <>
          <p>
            To upgrade or downgrade your plan (or change your billing frequency), please{' '}
            <a className='link-primary' href='mailto:support@markers.ai'>
              contact support
            </a>
            .
          </p>
          <ButtonContainer left>
            <Button variant='secondary-blue' onClick={handleChangeSubscription}>
              Update billing info
            </Button>
            <LinkButton to='./cancel' variant='secondary-red'>
              Cancel subscription
            </LinkButton>
          </ButtonContainer>
        </>
      ) : (
        <>
          <p>
            To re-activate your subscription, please{' '}
            <a className='link-primary' href='mailto:support@markers.ai'>
              contact support
            </a>
            .
          </p>
        </>
      )}
    </>
  )
}

export default SubscribedBillingPage
