import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { LocationProvider } from '@reach/router'

import TopNav from 'common/components/top-nav'
import ContentContainer from 'common/components/content-container'
import Footer from 'common/components/footer'
import TrialBanner from 'common/components/trial-banner'

import { LocationHookProvider } from 'common/location'
import { useAuth, AuthProvider } from 'auth'
import { useProfileService, ProfileProvider } from 'common/profile'
import { FlashProvider } from 'common/flash'

import { client } from './client'
import history from './history'
import { AppRoutes, LoginRoutes, ExpiredRoutes } from './routes'

// import 'tachyons/css/tachyons.css'
import './app.css'

if (process.env.NODE_ENV === 'production') {
  let hasShownAnalyticsError = false
  history.listen(function () {
    try {
      // @ts-ignore
      const { analytics } = window
      analytics.page()
    } catch (err) {
      if (!hasShownAnalyticsError) {
        console.error('Unable to track analytics')
        hasShownAnalyticsError = true
      }
    }
  })
}

const AuthenticatedContainer = () => {
  const { isPlanExpired } = useProfileService()

  return (
    <>
      <div className='bg-white pb6'>
        <TopNav />

        <ContentContainer>
          {isPlanExpired ? <ExpiredRoutes /> : <AppRoutes />}

          <TrialBanner />
        </ContentContainer>
      </div>

      <Footer />
    </>
  )
}

const AppContainer = () => {
  const { isAuthenticated } = useAuth()

  return isAuthenticated() ? (
    <ProfileProvider>
      <AuthenticatedContainer />
    </ProfileProvider>
  ) : (
    <LoginRoutes />
  )
}

const App = () => (
  <AuthProvider>
    <ApolloProvider client={client}>
      <LocationProvider history={history}>
        <LocationHookProvider>
          <FlashProvider>
            <AppContainer />
          </FlashProvider>
        </LocationHookProvider>
      </LocationProvider>
    </ApolloProvider>
  </AuthProvider>
)

export default App
