import gql from 'graphql-tag'

// TODO-2: if assigning all documents, ie without documentIds, this needs to receive
//         filter conditions, otherwise it will assign *all* documents for a project

// DESIRED DOCUMENT FILTER PARAMS, TO BE SHARED WITH BATCH ACTIONS AS FRAGMENT
/* filter: {
 *   categoryId: { any: UUID[], all: UUID[], none: Boolean },
 *   assigneeId: { any: UUID[], all: UUID[], none: Boolean },
 *   labelCount: { gt: Number, lt: Number, eq: Number },
 *   external_id: { contains: String }
 * } */

// NOTE: the id-isNull clauses are hacks to allow for conditional filters (by always eval-ing true or false)
export const DOCUMENTS_LISTING_QUERY = gql`
  query DocumentListingQuery(
    $datasetId: UUID!
    $first: Int
    $offset: Int
    $categoryId: UUID = "00000000-0000-0000-0000-000000000000"
    $isUsingCategoryId: Boolean = false
    $annotationId: UUID = "00000000-0000-0000-0000-000000000000"
    $isUsingAnnotationId: Boolean = false
  ) {
    documents(
      condition: { datasetId: $datasetId }
      filter: {
        and: [
          {
            or: [
              { id: { isNull: $isUsingCategoryId } }
              { categoryIds: { anyEqualTo: $categoryId } }
            ]
          }
          {
            or: [
              { id: { isNull: $isUsingAnnotationId } }
              { annotationIds: { anyEqualTo: $annotationId } }
            ]
          }
        ]
      }
      first: $first
      offset: $offset
    ) {
      nodes {
        nodeId
        id
        externalId
        tasks {
          nodes {
            assignee {
              nodeId
              id
              name
            }
          }
        }
        labels(filter: { parentLabelId: { isNull: true } }) {
          totalCount
        }
        labelCount
      }
      totalCount
    }
  }
`

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation DeleteDocumentMutation($documentId: UUID!) {
    deleteDocumentById(input: { id: $documentId }) {
      deletedDocumentId
    }
  }
`

// TODO-3: combine both delete incomplete tasks mutations into single mutation that takes optional documentId list
export const DELETE_ALL_INCOMPLETE_TASKS_MUTATION = gql`
  mutation DeleteAllIncompleteTasks($projectId: UUID!) {
    deleteAllIncompleteTasks(input: { projectId: $projectId }) {
      clientMutationId
    }
  }
`

export const DELETE_INCOMPLETE_TASKS_MUTATION = gql`
  mutation DeleteIncompleteTasks($documentIds: [UUID]!) {
    deleteIncompleteTasks(input: { documentIds: $documentIds }) {
      clientMutationId
    }
  }
`
