import isNil from 'lodash/isNil'

export const elementJoin = (elements, separator = ', ') => {
  return !elements || elements.length === 0
    ? ''
    : elements.reduce((prev, curr) => [prev, separator, curr])
}

export const preventDefaultEvent = fn => event => {
  event.preventDefault()
  fn()
}

export const confirmAction = confirmationText => action => {
  if (window.confirm(confirmationText)) {
    action()
  }
}

export const cleanGraphQLErrorMessage = msg => {
  // NOTE: keep an eye on https://github.com/apollographql/apollo-feature-requests/issues/46
  return msg.replace(/^GraphQL error: /, '')
}

export const formatDollars = (value, { precision = 0 } = {}) =>
  isNaN(value) || isNil(value)
    ? '-'
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }).format(value)
