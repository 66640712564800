import React, { FC } from 'react'
import 'twin.macro'

import { Table, TableBody, Row, Cell } from 'common/components'

import { Config, TaskAction } from '../types'

import TaskControlsContainer from './task-controls-container'

const FragmentControls: FC<{
  labels: any[],
  action: TaskAction,
  config: Config
}> = ({ labels, action, config }) => {
  const countForCategory = cat => {
    const filteredAnnotations = labels.filter(a => a.categoryId === cat.id)
    return filteredAnnotations.length
  }

  const label = {
    [TaskAction.Create]: 'Highlight text to assign a sequence label.',
    [TaskAction.Review]: 'Please accept or reject the candidate sequence labels.'
    // [TaskActions.View]: 'Viewing sequence labels.',
  }[action]

  return (
    <TaskControlsContainer label={label}>
      <Table>
        <TableBody>
          {config.fragmentCategories.map(cat => (
            <Row key={cat.id}>
              <Cell noPadding>
                <div
                  tw='inline-block mr-2 w-4 h-4 rounded align-middle'
                  style={{ backgroundColor: cat.properties.color }}
                />
                <span tw='leading-3 align-middle'>{cat.name}</span>
              </Cell>

              <Cell noPadding last>
                {countForCategory(cat)}
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </TaskControlsContainer>
  )
}

export default FragmentControls
