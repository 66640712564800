import React from 'react'
import classNames from 'classnames'

import ContentContainer from './content-container'
import {
  isDocumentationEnabled,
  isBlogEnabled,
  isSupportEnabled
} from '../../feature-flags'

const websiteDomain =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : 'https://markers.ai'

const FooterLink = ({ to, external = false, className = null, children }) => (
  <a
    href={external ? to : `${websiteDomain}/${to}`}
    className={classNames(['link underline-hover pointer black', className])}
  >
    {children}
  </a>
)

const FooterItem = props => <li className='mb1' {...props} />

const Footer = () => (
  <ContentContainer className='pt3 pt4-ns pb3 pb6-ns ph3 ph0-l lh-copy'>
    <div className='flex flex-column flex-row-ns'>
      <div className='flex-1'>
        <h4 className='mb3 ttu b gray f6'>Product</h4>
        <ul className='list pl0'>
          <FooterItem>
            <FooterLink to='/#features' external>
              Features
            </FooterLink>
          </FooterItem>
          <FooterItem>
            <FooterLink to='/#pricing' external>
              Pricing
            </FooterLink>
          </FooterItem>
        </ul>
      </div>
      <div className='flex-1'>
        <ul className='list pl0'>
          <h4 className='mb3 ttu b gray f6'>Resources</h4>
          {isBlogEnabled && (
            <FooterItem>
              <FooterLink to='/blog'>Blog</FooterLink>
            </FooterItem>
          )}
          <FooterItem>
            <FooterLink to='/data-policy'>Data Policy</FooterLink>
          </FooterItem>
          {isDocumentationEnabled && (
            <FooterItem>
              <FooterLink to='/docs'>Documentation</FooterLink>
            </FooterItem>
          )}
          {isSupportEnabled && (
            <FooterItem>
              <FooterLink to='/support'>Support</FooterLink>
            </FooterItem>
          )}
        </ul>
      </div>
      <div className='flex-2'>
        <h4 className='mb3 ttu b gray f6'>Contact</h4>
        <p>
          Have questions? Drop us a line at{' '}
          <a className='black' href='mailto:hello@markers.ai'>
            hello@markers.ai
          </a>{' '}
          or on Twitter at{' '}
          <a href='https://twitter.com/markers_ai' className='black'>
            @markers_ai
          </a>
          .
        </p>
      </div>
    </div>
  </ContentContainer>
)

export default Footer
