import React from 'react'
import 'twin.macro'

interface ProgressBarProps {
  percent: number
  className?: string
}

const ProgressBar = ({ percent }: ProgressBarProps) => (
  <div tw='bg-gray-300 h-1'>
    <div tw='bg-blue-500 h-full' style={{ width: `${percent}%` }} />
  </div>
)

export default ProgressBar
