import React from 'react'

import { buildSubnavAndRoutes } from 'common/route-utils'

import Profile from './pages/profile'
import Team from './pages/team'

const SettingsSubnavAndRoutes = buildSubnavAndRoutes(
  [
    { name: 'Profile', default: true, url: 'profile', component: Profile },
    {
      name: 'Team',
      url: 'team/*',
      component: Team,
      check: ({ isAdmin }) => isAdmin()
    }
  ],
  { defaultRoute: '/settings/profile' }
)
// NOTE: @reach/router Redirect supports relative paths in `master`, but a new version hasn't been published
//       see https://github.com/reach/router/pull/193

const Settings = props => <SettingsSubnavAndRoutes {...props} title='Settings' />

export default Settings
