import React from 'react'
import 'twin.macro'

const PageHeading = ({ title, children = null, subhead = null }) => {
  const Title = typeof title === 'function' ? title : () => <>{title}</>

  return (
    <div tw='flex flex-row justify-between mb-4 items-baseline'>
      <div tw='flex-auto'>
        <h1 tw='text-3xl font-bold'>
          <Title />
        </h1>
        {subhead && <div>{subhead}</div>}
      </div>
      {children}
    </div>
  )
}

export default PageHeading
