import { useQuery, useMutation } from '@apollo/react-hooks'

import {
  NEXT_INCOMPLETE_TASK_QUERY,
  SKIP_TASK_MUTATION,
  CREATE_LABEL_MUTATION
} from './queries'

const useAnnotatorData = ({ projectId, cycleId }) => {
  const { data: queryData, loading, error } = useQuery(NEXT_INCOMPLETE_TASK_QUERY, {
    variables: { projectId, cycleId }
  })

  const [createLabel] = useMutation(CREATE_LABEL_MUTATION, {
    refetchQueries: [
      {
        query: NEXT_INCOMPLETE_TASK_QUERY,
        variables: { projectId, cycleId }
      }
    ]
  })

  const [skipTask] = useMutation(SKIP_TASK_MUTATION, {
    refetchQueries: [
      {
        query: NEXT_INCOMPLETE_TASK_QUERY,
        variables: { projectId, cycleId }
      }
    ]
  })

  let data
  if (queryData) {
    const task = queryData.nextIncompleteTask.nodes[0]
    data = {
      project: queryData.projectById,
      task: task,
      document: task.document,
      progress: queryData.taskProgress,
      currentUserProfile: queryData.currentUserProfile
    }
  } else {
    data = null
  }

  return {
    data,
    loading,
    error,
    createLabel,
    skipTask
  }
}

export default useAnnotatorData
