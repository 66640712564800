import React from 'react'
import { useMutation } from '@apollo/react-hooks'

import { useAuth } from 'auth'
import { FlashError } from 'common/flash'
import Callout from 'common/components/callout'

import RegistrationForm from './registration-form'
import { REGISTER_TENANT_MUTATION } from '../queries'

// TODO-2: reconcile duplication between Register and RegisterWithToken (ie, probably combine the two)

const Register = ({ navigate }) => {
  const { login } = useAuth()
  const [registrationError, setRegistrationError] = React.useState(null)
  const [registerNewUser] = useMutation(REGISTER_TENANT_MUTATION)

  const handleRegistration = async values => {
    try {
      const result = await registerNewUser({
        variables: {
          input: {
            userName: values.name,
            userEmail: values.email,
            userPassword: values.password,
            tenantName: values.teamName
          }
        }
      })

      const {
        data: {
          registerTenant: { jwtToken: authToken }
        }
      } = result

      if (authToken) {
        login(authToken)
        navigate('/', { state: { flash: 'You have successfully signed up!' } })
      }
    } catch (err) {
      console.error(err)
      setRegistrationError(err)
    }
  }

  return (
    <div className='measure center'>
      <Callout>
        If you're joining an existing team, please follow the invite link from the
        invitation email.
      </Callout>

      {registrationError && (
        <FlashError>
          There was a problem creating your account. Please contact your team admin.
        </FlashError>
      )}

      <h3>Registration</h3>

      <RegistrationForm onSubmit={handleRegistration} />
    </div>
  )
}

export default Register
