import React from 'react'
import { Router } from '@reach/router'
import { useQuery } from '@apollo/react-hooks'

import { buildSubnavAndRoutes } from 'common/route-utils'

import ExistingUserPage from './pages/existing-user-page'
import NewUserPage from './pages/new-user-page'
import UsersPage from './pages/users'
import ActiveInvitations from './pages/active-invitations'
import { TEAM_SUMMARY_QUERY } from './queries'

const UserRoutes = props => (
  <Router primary={false}>
    <UsersPage {...props} exact path='/' />
    <ExistingUserPage {...props} path='/:userId' />
  </Router>
)

const InvitationRoutes = props => (
  <Router primary={false}>
    <ActiveInvitations {...props} exact path='/' />
    <NewUserPage {...props} path='/new' />
  </Router>
)

const UsersSubnavAndRoutes = buildSubnavAndRoutes(
  [
    {
      name: ({ teamSize }) => `People (${teamSize})`,
      url: 'users/*',
      component: UserRoutes
    },
    {
      name: ({ invitationCount }) => `Invitations (${invitationCount})`,
      url: 'invitations/*',
      component: InvitationRoutes
    }
  ],
  { defaultRoute: '/team/users' }
)
// NOTE: @reach/router Redirect supports relative paths in `master`, but a new version hasn't been published
//       see https://github.com/reach/router/pull/193

const UsersIndex = props => {
  const { loading, error, data } = useQuery(TEAM_SUMMARY_QUERY)

  if (loading) return <div>loading...</div>
  if (error) return <div>error: {error.message}</div>

  const invitationCount = data.activeInvitations.nodes.length // TODO-3: this is a kludgy way to retrieve just a count of active invitations
  const teamSize = data.users.totalCount

  return (
    <UsersSubnavAndRoutes
      {...props}
      teamSize={teamSize}
      invitationCount={invitationCount}
      title='Team'
    />
  )
}

export default UsersIndex
