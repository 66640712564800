import { useContext } from 'react'

import FlashContext from './context'

const useFlash = () => {
  const { getFlashMessage, setFlashMessage, clearFlashMessage } = useContext(
    FlashContext
  )

  return {
    setFlashMessage,
    getFlashMessage,
    clearFlashMessage
  }
}

export default useFlash
