import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { useMutation } from '@apollo/react-hooks'

import { PageHeader } from 'common/components'

import WebhookForm from '../components/form'
import { WEBHOOK_LISTING_QUERY, CREATE_WEBHOOK_MUTATION } from '../queries'

interface INewWebhookPage extends RouteComponentProps {
  project: any
}

const NewWebhookPage = (props: INewWebhookPage) => {
  const { navigate, project } = props

  const [createWebhook] = useMutation(CREATE_WEBHOOK_MUTATION, {
    refetchQueries: [
      { query: WEBHOOK_LISTING_QUERY, variables: { projectId: project.id } }
    ]
  })

  const handleSaveWebhook = async values => {
    await createWebhook({ variables: { ...values, projectId: project.id } })
    navigate('..', { state: { flash: 'Webhook was successfully created.' } })
  }

  return (
    <>
      <PageHeader title='New webhook' />
      <WebhookForm onSubmit={handleSaveWebhook} />
    </>
  )
}

export default NewWebhookPage
