import React from 'react'
import { Manager, Reference, Popper } from 'react-popper'

import { Button } from 'common/components'

import FilterTag from './filter-tag'

const FilterControls = ({
  currentFilters,
  labelMap,
  isOpen,
  onOpenModal,
  onRemoveFilter,
  filtersModal: FiltersModal
}) => {
  const areFiltersActive = !!Object.keys(currentFilters).find(k => !!currentFilters[k])

  return (
    <div className='flex items-center justify-end'>
      <div className='flex-0'>
        <span className='ttu f6 mr2 b mid-gray'>Filters</span>
      </div>
      <div className='dib bg-white b--light-gray ba pv2 ph2 gray mr2 lh-copy'>
        {areFiltersActive ? (
          <>
            {Object.keys(currentFilters)
              .map((k, index) =>
                currentFilters[k] ? (
                  <FilterTag
                    key={index}
                    name={labelMap[k] || k}
                    value={currentFilters[k].name}
                    onRemove={() => onRemoveFilter(k)}
                  />
                ) : null
              )
              .filter(Boolean)}
          </>
        ) : (
          <span className='pointer' onClick={onOpenModal}>
            No active filters
          </span>
        )}
      </div>
      <div className='flex-0'>
        {isOpen ? (
          <Manager>
            <Reference>
              {({ ref }) => (
                <Button ref={ref} variant='tertiary-blue' onClick={onOpenModal}>
                  Modify
                </Button>
              )}
            </Reference>
            {/*
          // @ts-ignore */}
            <Popper placement='bottom-end' component='div' modifiers={{}}>
              {({ ref, style, placement }) => (
                <span
                  // @ts-ignore
                  ref={ref}
                  // zIndex to appear over form elements, e.g. react-select
                  style={{ ...style, zIndex: 2 }}
                  data-placement={placement}
                >
                  <FiltersModal />
                </span>
              )}
            </Popper>
          </Manager>
        ) : (
          <Button variant='tertiary-blue' onClick={onOpenModal}>
            Modify
          </Button>
        )}
      </div>
    </div>
  )
}

export default FilterControls
