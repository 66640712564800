import React from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import get from 'lodash/get'

import { useAuth } from 'auth'
import { FlashError } from 'common/flash'
import Callout from 'common/components/callout'

import RegistrationForm from './registration-form'
import { LOAD_INVITATION_QUERY, REGISTER_TEAM_MEMBER_MUTATION } from '../queries'

const RegisterWithToken = ({ inviteToken, navigate }) => {
  const { login } = useAuth()
  const [registrationError, setRegistrationError] = React.useState(null)
  const { data, loading, error } = useQuery(LOAD_INVITATION_QUERY, {
    variables: { token: inviteToken }
  })
  const [registerTeamMember] = useMutation(REGISTER_TEAM_MEMBER_MUTATION)

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error...</div>
  }

  const invitation = get(data, 'lookupToken.properties')

  const handleRegister = async values => {
    try {
      const result = await registerTeamMember({
        variables: {
          input: {
            name: values.name,
            email: values.email,
            password: values.password,
            tokenValue: inviteToken
          }
        }
      })
      const {
        data: {
          registerTeamMember: { jwtToken: authToken }
        }
      } = result

      if (authToken) {
        login(authToken)
        navigate('/', { state: { flash: 'You have successfully signed up!' } })
      }
    } catch (err) {
      console.error(err)
      setRegistrationError(err)
    }
  }

  return (
    <div className='measure center'>
      {invitation ? (
        <>
          <Callout className='mb4'>
            <>
              {invitation.inviter_name} has invited you to join the team:{' '}
              <span className='b'>{invitation.team_name}</span>.
            </>
          </Callout>
          {registrationError && (
            <FlashError>
              There was a problem creating your account. Please contact your team admin.
            </FlashError>
          )}
          <RegistrationForm
            email={invitation && invitation.recipient_email}
            onSubmit={handleRegister}
            isInvitation={true}
          />
        </>
      ) : (
        <>
          <FlashError>
            Could not find a valid invitation from the provided token. It's possible the
            token has expired (tokens are valid for 3 days). Please contact your team
            admin to send you a new invitation.
          </FlashError>
        </>
      )}
    </div>
  )
}

export default RegisterWithToken
