export enum ProductType {
  INDIE = 'INDIE',
  TEAM = 'TEAM',
  TRIAL = 'TRIAL'
}

export enum BillingCycle {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  TRIAL = 'TRIAL'
}

export interface PlanQuota {
  admin: number
  members: number
  labels: number
  documents: number
}

export interface Plan {
  planType: ProductType
  billingCycle: BillingCycle
  expiry: Date
  planQuota: PlanQuota
  autoRenew: boolean
}
