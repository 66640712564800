import React, { useState, FC } from 'react'
import classNames from 'classnames'
import { Manager, Reference, Popper } from 'react-popper'

import { useProfileService } from 'common/profile'

import { Link } from './index'
import TopNavMenuDropdown from './top-nav-menu-dropdown'

const TopNavLink = ({ exact = false, ...props }) => (
  <Link
    getProps={({ isCurrent, isPartiallyCurrent }) => ({
      className: classNames([
        'link underline-hover dark-gray f6 f5-ns dib mr4 ml3 pv1',
        (exact ? isCurrent : isPartiallyCurrent) && 'b bb bw2 b--primary-color'
      ])
    })}
    {...props}
  />
)

const UserAvatar: FC<any> = React.forwardRef<any>(({ user }: any, ref) => {
  const initials = user.name
    .split(' ')
    .slice(0, 2)
    .map(x => x.charAt(0).toUpperCase())
    .join('')
  const avatarColors = {
    0: 'bg-dark-green',
    1: 'bg-dark-pink',
    2: 'bg-dark-blue',
    3: 'bg-purple',
    4: 'bg-dark-red'
  }
  const colorForAvatar =
    avatarColors[parseInt(user.id.slice(-2), 16) % Object.keys(avatarColors).length]

  return (
    <div
      data-cy='user-avatar'
      ref={ref}
      className={classNames([
        'dib w2-5 h2-5 br-100 white pointer tc flex items-center justify-center b ba',
        colorForAvatar
      ])}
    >
      {initials}
    </div>
  )
})

const Brand = () => (
  <Link
    className='link b no-underline f6 f4-ns dib mr6 flex items-center lh-solid black'
    to='/'
  >
    <img
      alt=''
      src={require('./markers-logo-32px.png')}
      style={{ width: 26, height: 26 }}
    />
    <span className='ml2'>markers.ai</span>
  </Link>
)

const TopNavProfileLink = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { profile } = useProfileService()

  return (
    <>
      {isOpen ? (
        <Manager>
          <Reference>{({ ref }) => <UserAvatar ref={ref} user={profile} />}</Reference>
          {/*
          // @ts-ignore */}
          <Popper placement='bottom-end' component='div' modifiers={{}}>
            {({ ref, style, placement }) => (
              <TopNavMenuDropdown
                style={style}
                ref={ref}
                placement={placement}
                onClickOutside={() => setIsOpen(false)}
                currentUser={profile}
              />
            )}
          </Popper>
        </Manager>
      ) : (
        <span onClick={() => setIsOpen(true)}>
          <UserAvatar user={profile} />
        </span>
      )}
    </>
  )
}

const TopNav = () => {
  const { profile, plan, isPlanExpired } = useProfileService()
  if (!(plan && profile)) {
    return null
  }

  return (
    <nav className='dt mw8 w8-l center border-box pv3 flex flex-row items-center'>
      <div className='dtc v-mid w-75 flex flex-1 items-center'>
        <Brand />
        {/* <TopNavLink exact to='/'>
          Dashboard
        </TopNavLink> */}
        {!isPlanExpired && (
          <>
            <TopNavLink to='/projects'>Projects</TopNavLink>
            <TopNavLink to='/team'>Team</TopNavLink>
            <TopNavLink to='/settings'>Settings</TopNavLink>
          </>
        )}
      </div>
      <div className='dtc'>
        <TopNavProfileLink />
      </div>
    </nav>
  )
}

export default TopNav
