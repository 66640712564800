import { FormikHelpers } from 'formik'

interface SubmitHandlerOpts<TValues> {
  shouldResetForm?: boolean
  errors?: (err: any) => Record<string, string>
  reshape?: (values: TValues) => any
  unmountsAfterSubmit?: boolean // to prevent setting state on unmounted component
}

export const buildSubmitHandler = <TValues,>(
  onSave: (values: TValues) => Promise<void> | void,
  { shouldResetForm = false, errors, reshape, unmountsAfterSubmit = false }: SubmitHandlerOpts<TValues> = {}
) =>
  async (formValues: TValues, { setSubmitting, resetForm, setErrors }: FormikHelpers<unknown>) => {
    setSubmitting(true)
    try {
      const values = reshape ? reshape(formValues) : formValues
      await onSave(values)
      shouldResetForm && resetForm()
      !unmountsAfterSubmit && setSubmitting(false)
    } catch (err) {
      errors ? setErrors(errors(err)) : setErrors(err.errors)
      setSubmitting(false)
    }
  }
