import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { useAuth } from 'auth'
import { LinkButton } from 'common/components'
import Datetime from 'common/components/datetime'

import { ACTIVE_INVITATIONS_QUERY } from '../queries'
import DataTable from 'common/components/data-table'

interface ActiveInvitation {
  expiry: Date
  createdAt: Date
  recipient: string
}

const ConnectedActiveInvitations = () => {
  const { isAdmin } = useAuth()
  const { data, loading, error } = useQuery(ACTIVE_INVITATIONS_QUERY)

  if (loading) return <div>loading...</div>
  if (error) return <div>{error.message}</div>

  const activeInvitations: ActiveInvitation[] = data.activeInvitations.nodes.map(
    ({ expiry, createdAt, properties }) => ({
      expiry,
      createdAt,
      recipient: properties.recipient_email
    })
  )

  return (
    <>
      <DataTable
        data={activeInvitations}
        columns={[
          {
            Header: 'Email',
            accessor: 'recipient'
          },
          {
            Header: 'Date sent',
            accessor: ({ createdAt }: ActiveInvitation) => (
              <Datetime human datetime={createdAt} />
            )
          },
          {
            Header: 'Expires',
            accessor: ({ expiry }: ActiveInvitation) => (
              <Datetime human datetime={expiry} />
            ),
            // @ts-ignore
            headerProps: { className: 'tr' },
            cellProps: { className: 'tr' }
          }
        ]}
      />

      {isAdmin() && (
        <div className='mt4 tr'>
          <LinkButton to='./new'>Invite new user to team</LinkButton>
        </div>
      )}
    </>
  )
}

export default ConnectedActiveInvitations
