import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

export enum StepStatus {
  complete = 'complete',
  incomplete = 'incomplete',
  active = 'active'
}

const TrailItem = ({ number, title, onClick, status = StepStatus.incomplete }) => {
  const circleClasses = classNames({
    'w2 h2 br-100 center mb2': true,
    'bg-light-green': status === StepStatus.complete,
    'bg-blue': status === StepStatus.active,
    'bg-moon-gray': status === StepStatus.incomplete
  })

  const labelClasses = classNames({
    'w4 f6': true,
    gray: status === StepStatus.complete || status === StepStatus.incomplete,
    'black b': status === StepStatus.active
  })

  const itemClasses = classNames({
    'circle-trail tc flex-0 bw1': true,
    pointer: !!onClick,
    complete: status === StepStatus.complete,
    active: status === StepStatus.active
  })

  return (
    <div className={itemClasses} onClick={onClick}>
      <div className={circleClasses}>
        {status !== StepStatus.complete ? (
          <span className='w-100 h-100 tc white label'>{number}</span>
        ) : (
          <FontAwesomeIcon
            icon={faCheck}
            color='#fff'
            style={{ marginTop: '0.5rem' }}
          />
        )}
      </div>
      <div className={labelClasses}>{title}</div>
    </div>
  )
}

const WizardTrail = ({ steps, onStepSelect }) => {
  return (
    <div className='flex flex-row justify-between'>
      {steps.map((step, index) => (
        <TrailItem
          key={index}
          number={index + 1}
          title={step.title}
          status={step.status}
          onClick={step.state === StepStatus.complete ? onStepSelect : null}
        />
      ))}
    </div>
  )
}

export default WizardTrail
