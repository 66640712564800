import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { Button, ButtonContainer, Link, LinkButton } from 'common/components'
import { confirmAction, elementJoin } from 'common/utils'
import Callout from 'common/components/callout'

import { useAuth } from 'auth'
import { PROJECTS_QUERY } from '../queries'
import NewProjectWizard from 'project/pages/new-project-wizard'
import PageHeading from 'common/components/page-heading'

const PublicProjectsPage = ({ projects, navigate }) => {
  const { isAdmin, getUserId } = useAuth()
  const [isProjectWizardOpen, setProjectWizardOpen] = React.useState(false)
  const handleOpenProjectWizard = () => setProjectWizardOpen(true)
  const handleCloseProjectWizard = () =>
    confirmAction(
      'Are you sure you want to close the create project wizard? You will lose unsaved changes.'
    )(() => setProjectWizardOpen(false))

  return (
    <>
      <PageHeading title='Projects' />

      {projects.length > 0 ? (
        <>
          <table className='w-100 mw8 center mt4' cellSpacing='0'>
            <thead>
              <tr>
                <th className='fw6 tl pb3 pr3 bg-white nowrap'>Name</th>
                <th className='fw6 tl pb3 pr3 bg-white mw-40 nowrap'>Datasets used</th>
                <th />
              </tr>
            </thead>
            <tbody className='lh-copy'>
              {projects.map((project, index) => (
                <React.Fragment key={project.id}>
                  <tr>
                    <td className='pv3 pr3 bt b--black-20'>
                      <Link to={`/projects/${project.id}`}>{project.name}</Link>
                    </td>
                    <td className='pv3 pr3 bt b--black-20'>
                      {elementJoin(
                        project.projectToDatasets.nodes.map(node => (
                          <Link
                            key={node.dataset.id}
                            to={`/projects/${project.id}/documents`}
                          >
                            {node.dataset.name}
                          </Link>
                        ))
                      )}
                    </td>
                    <td className='pv3 bt b--black-20 tr'>
                      <LinkButton
                        to={`/projects/${project.id}/annotate`}
                        variant='primary-blue'
                      >
                        Label
                      </LinkButton>
                    </td>
                  </tr>
                  {false && project.description ? (
                    <tr>
                      <td colSpan={5} className='pb3 bb b--black-20'>
                        <dl>
                          <dt>Description</dt>
                          <dd>{project.description}</dd>
                        </dl>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {isAdmin() && (
            <ButtonContainer>
              <Button onClick={handleOpenProjectWizard}>Create new project</Button>
            </ButtonContainer>
          )}
        </>
      ) : (
        <Callout className='tc pv5 bg-off-white'>
          <p className='mb4'>You do not have any projects.</p>
          {isAdmin() && (
            <ButtonContainer center>
              <Button onClick={handleOpenProjectWizard}>Create new project</Button>
            </ButtonContainer>
          )}
        </Callout>
      )}

      <NewProjectWizard
        navigate={navigate}
        currentUserId={getUserId()}
        isOpen={isProjectWizardOpen}
        onRequestClose={handleCloseProjectWizard}
      />
    </>
  )
}

const ConnectedPublicProjectsPage = props => {
  const { loading, error, data } = useQuery(PROJECTS_QUERY)

  if (loading) return <div>loading...</div>
  if (error) return <div>error</div>

  const projects = data.projects.nodes

  return <PublicProjectsPage {...props} projects={projects} />
}

export default ConnectedPublicProjectsPage
