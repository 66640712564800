import React from 'react'
import { Router, Redirect, RouteComponentProps } from '@reach/router'

import { useLocation } from 'common/location'
import FlashMessages from 'common/flash'

import { LogoutRoute, LoginRoute } from 'auth'
import RegistrationPage from 'users/pages/registration'
// import DashboardRoute from 'dashboard'
import TeamRoutes from 'users'
import ProjectRoutes from 'project/routes'
import SettingsRoutes from 'settings/routes'

const DashboardRoute = ({ navigate }: RouteComponentProps) => {
  // TODO: for now, we just redirect to project listing and preserve the flash message
  const location = useLocation()
  setTimeout(() => navigate('/projects', { state: location.state, replace: true }), 0)
  return null
}

export const AppRoutes = () => (
  <>
    <FlashMessages />

    <Router>
      <LogoutRoute path='/logout' />

      <ProjectRoutes path='/projects/*' />
      <TeamRoutes path='/team/*' />
      <SettingsRoutes path='/settings/*' />
      <DashboardRoute path='/' />
    </Router>
  </>
)

export const LoginRoutes = () => (
  <div className='flex items-center items-start-lh justify-center min-h-100 pa4 pt6-lh'>
    <div className='flex-auto measure bg-white pa4 ba b--moon-gray'>
      <a
        href={
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:8000'
            : 'https://markers.ai'
        }
        className='f6 link underline pointer blue'
      >
        &laquo; back to home page
      </a>

      <div className='flex flex-row items-center mt4 mb4'>
        <div>
          <img
            alt=''
            className='pr4'
            src={require('./auth/pages/markers-logo.png')}
            style={{ width: 64, height: 64 }}
          />
        </div>
        <h1 className='flex-auto ma0 pa0 lh-solid'>markers.ai</h1>
      </div>

      <FlashMessages />

      <Router>
        <LoginRoute path='/' default />
        <RegistrationPage path='/register' />
      </Router>
    </div>
  </div>
)

export const ExpiredRoutes = () => (
  <>
    <FlashMessages />

    <Router>
      <LogoutRoute path='/logout' />

      <SettingsRoutes path='/settings/*' />
      <Redirect to='/settings/team/billing' default noThrow />
    </Router>
  </>
)
