import React from 'react'
import { Formik, Form } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { Button, ButtonContainer } from 'common/components'
import { buildSubmitHandler } from 'common/form-helpers'

const ReviewScreen = ({ project, classes, onSubmit }) => (
  <Formik
    initialValues={{ documentClasses: [], fragmentClasses: [] }}
    onSubmit={buildSubmitHandler(onSubmit)}
  >
    {({ isSubmitting }) => (
      <Form className='mt4'>
        <h3>{project.name}</h3>

        {project.description && <p>{project.description}</p>}

        {!isEmpty(classes) ? (
          <div>
            {!isEmpty(classes.document) && (
              <>
                <h4>Document label classes</h4>
                <ul>
                  {classes.document.map((cls, index) => (
                    <li key={index}>{cls.label}</li>
                  ))}
                </ul>
              </>
            )}

            {!isEmpty(classes.fragment) && (
              <>
                <h4>Fragment label classes</h4>
                <ul>
                  {classes.fragment.map((cls, index) => (
                    <li key={index}>{cls.label}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        ) : (
          <p>
            No document or fragment classes are configured. You'll need to set these up
            before you begin labeling your data.
          </p>
        )}

        <p className='i'>
          Note: You can change the project settings later from the project admin
          screens.
        </p>

        <ButtonContainer>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Create Project'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

export default ReviewScreen
