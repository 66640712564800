import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import get from 'lodash/get'
import FormGroup from 'common/components/form-group'

import { InputField, ReactSelectField } from 'common/components/form'
import { FlashError } from 'common/flash'
import { Button, ButtonContainer } from 'common/components'
import { cleanGraphQLErrorMessage } from 'common/utils'
import { buildSubmitHandler } from 'common/form-helpers'

const userFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('You must provide an email address.')
    .email("This doesn't look like a valid email address.")
})

const ROLES = {
  admin: { value: 'admin', label: 'Admin' },
  member: { value: 'member', label: 'Member' }
}

const DEFAULT_ROLE = ROLES.member

const UserForm = ({ user = {}, isEditing, onSubmit, adminCount }: any) => {
  const isLastAdmin = adminCount === 1 && user.permissions.role === 'admin'

  return (
    <Formik
      initialValues={{
        name: get(user, 'name', ''),
        email: get(user, 'email', ''),
        role: get(ROLES, get(user, 'permissions.role'), DEFAULT_ROLE),
        form: null // TODO-3: this is hack around formik-related type errors
      }}
      validationSchema={userFormSchema}
      onSubmit={buildSubmitHandler(onSubmit, {
        reshape: ({ role, ...values }) => {
          return isEditing
            ? { variables: { id: user.id, permissions: { role: role.value }, ...values } }
            : { variables: { input: { inviteeEmail: values.email } } }
        },
        errors: (err) => ({ form: cleanGraphQLErrorMessage(err.message) })
      })}
    >
      {({
        handleChange,
        setFieldValue,
        touched,
        values,
        errors = {},
        isSubmitting
      }) => (
        <Form>
          {errors.form && <FlashError>{errors.form}</FlashError>}

          <FormGroup
            title='Basic Information'
            description={
              isEditing
                ? 'These fields will be visible to everyone on the team.'
                : 'Recipient will be sent an email with a link to join your team.'
            }
            noBorder
          >
            {isEditing && (
              <InputField
                label='Name'
                name='name'
                onChange={handleChange}
                touched={touched.name}
                value={values.name}
                error={errors.name}
                helperText='This will be visible to everybody on the team.'
              />
            )}

            <InputField
              label='Email'
              name='email'
              onChange={handleChange}
              touched={touched.email}
              value={values.email}
              error={errors.email}
            />

            {isEditing && (
              <ReactSelectField
                label='Role'
                name='role'
                onChange={role => setFieldValue('role', role)}
                touched={touched.email}
                options={Object.values(ROLES)}
                value={values.role}
                error={errors.role}
                disabled={isLastAdmin}
                helperText={
                  isLastAdmin &&
                  "Projects must have at least one admin and this user is the project's only admin."
                }
              />
            )}
          </FormGroup>

          <ButtonContainer>
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting
                ? 'Submitting...'
                : isEditing
                ? 'Update team member'
                : 'Send invitation'}
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
