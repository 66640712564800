import React from 'react'
import { Formik, Form } from 'formik'

import { Button, ButtonContainer } from 'common/components'
import { ReactSelectField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

interface SummaryFiltersFormProps {
  activeCycle: string
  cycles: [unknown]
  onClearFilters(): unknown
  onSaveFilters(unknown): unknown
}

const SummaryFiltersForm = ({
  activeCycle,
  cycles,
  onClearFilters,
  onSaveFilters
}: SummaryFiltersFormProps & any) => (
  <Formik
    initialValues={{ cycle: activeCycle }}
    onSubmit={buildSubmitHandler(onSaveFilters)}
    enableReinitialize={true}
  >
    {({ values, setFieldValue, isSubmitting }) => (
      <Form>
        <h3 className='mt0'>Filters</h3>
        <ReactSelectField
          label='Cycle'
          name='cycle'
          placeholder='All cycles'
          value={values.cycle}
          onChange={val => setFieldValue('cycle', val)}
          options={cycles}
          selectProps={{
            isClearable: true,
            getOptionLabel: opt => opt.name,
            getOptionValue: opt => opt.id
          }}
          containerProps={{ className: 'mb2' }}
        />

        <ButtonContainer>
          <Button
            onClick={onClearFilters}
            disabled={isSubmitting}
            variant='secondary-red'
          >
            Clear filters
          </Button>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Applying filters...' : 'Apply filters'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

export default SummaryFiltersForm
