import gql from 'graphql-tag'

export const USERS_QUERY = gql`
  query {
    users {
      nodes {
        nodeId
        id
        name
        email
        permissions
        lastLogin
      }
    }
  }
`

// TODO-3: create real project memberships
export const PROJECT_MEMBERS_QUERY = gql`
  query {
    users {
      nodes {
        nodeId
        id
        name
      }
    }
  }
`

export const DELETE_TEAM_MEMBER_MUTATION = gql`
  mutation DeleteTeamMember($id: UUID!) {
    deleteTeamMember(input: { userId: $id }) {
      user {
        nodeId
        id
        email
      }
    }
  }
`

export const ACTIVE_INVITATIONS_QUERY = gql`
  query {
    activeInvitations {
      nodes {
        expiry
        properties
        createdAt
      }
    }
  }
`

export const TEAM_SUMMARY_QUERY = gql`
  query {
    users {
      totalCount
    }
    activeInvitations {
      nodes {
        expiry
      }
    }
  }
`

export const LOAD_INVITATION_QUERY = gql`
  query LookupInvitation($token: String!) {
    lookupToken(tokenValue: $token) {
      properties
    }
  }
`

export const REGISTER_TEAM_MEMBER_MUTATION = gql`
  mutation RegisterTeamMember($input: RegisterTeamMemberInput!) {
    registerTeamMember(input: $input) {
      jwtToken
    }
  }
`

export const REGISTER_TENANT_MUTATION = gql`
  mutation RegisterTenant($input: RegisterTenantInput!) {
    registerTenant(input: $input) {
      jwtToken
    }
  }
`
