import React from 'react'
import 'twin.macro'

const Item = ({ ...props }) => <li tw='mb-3' {...props} />
const El = ({ ...props }) => <div tw='mb-3' {...props} />

export const ELEMENT_MAP = {
  title: 'p',
  subtitle: 'p',
  paragraph: 'p',
  list: 'ol',
  item: Item,
  references: 'p',
  notes: 'p',
  p: 'p',
  unknown: El
}
