import React from 'react'

import CopyToClipboard from 'common/components/copy-to-clipboard'

const makePythonSnippet = (apiKey, projectId) => {
  // TODO-3: move app-like config into centralized location
  const apiUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://app.markers.ai'
  const apiUrlFragment =
    apiUrl !== 'https://app.markers.ai' ? `, api_url='${apiUrl}'` : ''
  const pythonSnippet = `from markers import Markers

markers = Markers(api_key='${apiKey}'${apiUrlFragment})
project = markers.load_project('${projectId}')`
  return pythonSnippet
}

const ProjectCodeSnippet = ({ apiKey, projectId }) => {
  const pythonSnippet = makePythonSnippet(apiKey, projectId)

  return (
    <>
      <div className='tr'>
        <CopyToClipboard value={pythonSnippet} visibleText />
      </div>
      <pre
        className='db f6 pa2 bg-near-white ba b--light-gray'
        style={{ overflowX: 'auto' }}
      >
        {pythonSnippet}
      </pre>
    </>
  )
}

export default ProjectCodeSnippet
