import React, { FC } from 'react'

import { Button, Link, ModalDialog, PageHeading } from 'common/components'

import { Config, Document, Project, Task, Metadata, TaskAction } from '../types'
import AnnotationInstructions from './annotation-instructions'
import TaskProgressBar from './task-progress-bar'
import LabelInterface from './label-interface'

const ProjectLabelInterface: FC<{
  project: Project,
  document: Document,
  task: Task,
  metadata: Metadata
  onSkipTask: Function
  createLabel: Function
  createReviewLabel: Function
}> = ({ document, project, task, metadata, onSkipTask, createLabel, createReviewLabel }) => {
  const { progress } = metadata
  const [areInstructionsVisible, setInstructionsVisible] = React.useState(false)

  const renderers: Record<TaskAction, () => JSX.Element> = {
    [TaskAction.Create]: () => (
      <>
        Labeling:{' '}
        <Link to='..' tw='text-black no-underline hover:underline'>
          {project.name}
        </Link>
      </>
    ),
    [TaskAction.Review]: () => (
      <>
        Reviewing:{' '}
        <Link to='..' tw='text-black no-underline hover:underline'>
          {project.name}
        </Link>
      </>
    )
  }

  const config: Config = {
    fragmentCategories: project.fragmentCategories.nodes,
    documentCategories: project.documentCategories.nodes,
  }

  return (
    <>
      <PageHeading
        title={renderers[task.action]()}
        subhead={
          <Button variant='tertiary-gray' onClick={() => setInstructionsVisible(true)}>
            View instructions
          </Button>
        }
      >
        <div tw='w-1/3'>
          <TaskProgressBar
            complete={progress.complete + progress.skipped}
            total={progress.total}
          />
        </div>
      </PageHeading>

      <LabelInterface
        config={config}
        task={task}
        document={document}
        onSkipTask={onSkipTask}
        createLabel={createLabel}
        createReviewLabel={createReviewLabel}
      />

      <ModalDialog
        isOpen={areInstructionsVisible}
        onRequestClose={() => setInstructionsVisible(false)}
      >
        <AnnotationInstructions
          project={project}
          onClose={() => setInstructionsVisible(false)}
        />
      </ModalDialog>
    </>
  )
}

export default ProjectLabelInterface
