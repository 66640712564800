import React from 'react'
import tw, { styled } from 'twin.macro'
import classNames from 'classnames'

export const TableHeader = ({ children }) => (
  <thead>
    <tr>{children}</tr>
  </thead>
)

export const HeaderCell = ({
  className = '',
  noPadding = false,
  secondary = false,
  ...restProps
}) => (
  <th
    className={classNames([
      'tl pv2',
      !noPadding && 'ph3',
      secondary ? 'normal' : 'ttu f6',
      className
    ])}
    {...restProps}
  />
)

export const TableBody = ({ children, ...rest }) => <tbody {...rest}>{children}</tbody>

export const Row = ({ isSelected = false, children, ...rest }) => (
  <tr
    className={classNames([
      'bt b--black-10 hover-bg-washed-blue',
      isSelected && 'bg-washed-yellow'
    ])}
    {...rest}
  >
    {children}
  </tr>
)

interface CellProps {
  noPadding?: boolean
  last?: boolean
}
export const Cell = styled.td.attrs<CellProps>(
  ({ noPadding = false, last = false }) => ({ noPadding, last })
)<CellProps>`
  ${tw`py-2`}
  ${({ noPadding }) => !noPadding && tw`px-3`}
`

export const Table = tw.table`border-collapse py-2 px-3 w-full`
