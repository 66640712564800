import React from 'react'
import 'twin.macro'
import * as yup from 'yup'
import { Formik, Form } from 'formik'

import { Button, ButtonContainer } from 'common/components'
import { InputField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

const RegistrationForm = ({ isInvitation = false, onSubmit, email = null }) => (
  <Formik
    initialValues={{
      email: email || '',
      name: '',
      password: '',
      teamName: ''
    }}
    validationSchema={registrationFormSchema}
    onSubmit={buildSubmitHandler(onSubmit)}
  >
    {({ handleChange, touched, values, errors = {}, isSubmitting }) => (
      <Form>
        <InputField
          label='Name'
          name='name'
          onChange={handleChange}
          touched={touched.name}
          value={values.name}
          error={errors.name}
          helperText='This will be visible to everyone on your team.'
          containerProps={{ className: 'mb3' }}
        />
        <InputField
          label='Email'
          name='email'
          onChange={handleChange}
          disabled={isInvitation}
          touched={touched.email}
          value={values.email}
          error={errors.email}
          helperText={
            isInvitation &&
            'You can change this on your profile page after registration.'
          }
          containerProps={{ className: 'mb3' }}
        />
        <InputField
          label='Password'
          name='password'
          type='password'
          onChange={handleChange}
          touched={touched.password}
          value={values.password}
          error={errors.password}
          containerProps={{ className: 'mb3' }}
        />
        {!isInvitation && (
          <InputField
            label='Team name'
            name='teamName'
            onChange={handleChange}
            touched={touched.teamName}
            value={values.teamName}
            error={errors.teamName}
          />
        )}
        <ButtonContainer>
          <Button tw='w-full' type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Sign up'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

const registrationFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('You must supply a name of some sort (you can always change this).'),
  email: yup
    .string()
    .email("This doesn't look like a valid email address.")
    .required("You can't sign up without an email!"),
  password: yup
    .string()
    .required("You can't sign up without a password!")
    .min(8, 'Your password must be at least 8 characters.')
})

export default RegistrationForm
