import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { InputField, ReactSelectField } from 'common/components/form'
import { Button, ButtonContainer, Code } from 'common/components'
import { buildSubmitHandler } from 'common/form-helpers'

const WEBHOOK_EVENTS = [{ value: 'cycle_complete', label: 'cycle_complete' }]

const webhookValidationSchema = yup.object().shape({
  payloadUrl: yup
    .string()
    .required('You must provide a payload URL to receive the webhook data.'),
  event: yup.string().required()
})

const WebhookForm = ({ webhook = null, onSubmit }) => (
  <Formik
    initialValues={{
      payloadUrl: get(webhook, 'payloadUrl') || '',
      secret: get(webhook, 'secret') || '',
      event:
        WEBHOOK_EVENTS.find(x => x.value === get(webhook, 'event')) ||
        WEBHOOK_EVENTS[0]
    }}
    onSubmit={buildSubmitHandler(onSubmit, {
      reshape: ({ event, secret, ...restValues }) => ({
        ...restValues,
        secret: isEmpty(secret) ? undefined : secret,
        event: event.value
      })
    })}
    validationSchema={webhookValidationSchema}
  >
    {({ values, touched, handleChange, setFieldValue, errors, isSubmitting }) => (
      <Form>
        <InputField
          name='payloadUrl'
          label='Payload URL'
          placeholder='https://example.com/'
          value={values.payloadUrl}
          touched={touched.payloadUrl}
          error={errors.payloadUrl}
          onChange={handleChange}
        />

        <InputField
          name='secret'
          label='Secret'
          isOptional={true}
          helperText='If configured, this will be used to sign the X-Markers-Signature header.'
          value={values.secret}
          touched={touched.secret}
          error={errors.secret}
          onChange={handleChange}
        />

        <ReactSelectField
          name='event'
          label='Triggering event'
          value={values.event}
          touched={touched.event}
          onChange={event => setFieldValue('event', event)}
          options={WEBHOOK_EVENTS}
          helperText={() => (
            <>
              Currently, only <Code>cycle_complete</Code> events are supported. Please
              see the documentation for details.
            </>
          )}
        />

        <ButtonContainer>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Save webhook'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

export default WebhookForm
