import React from 'react'
// import slug from 'limax' // NOTE: removed for compatibility with create-react-app
import * as yup from 'yup'
import { Formik, Form } from 'formik'

import { Button, ButtonContainer } from 'common/components'
import { InputField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

const ProjectUrl = ({ projectName }) => {
  // TODO: debounce + check uniqueness of slug, updating the UI appropriately when check passes
  //       (or fails and causes the slug to change)
  if (false && projectName) {
    // TODO: hard-coded to simple value to minimize logic in MVP
    // const projectSlug = slug(projectName)
    const projectSlug = 'TODO'
    return (
      <span>
        We'll use this for the project URL:{' '}
        <strong>markers.ai/projects/{projectSlug}</strong>
      </span>
    )
  } else {
    return <span>We'll use this for the project URL.</span>
  }
}

const AddProjectForm = ({ onSubmit }) => (
  <Formik
    initialValues={{ name: '', description: '' }}
    validationSchema={newProjectFormSchema}
    onSubmit={buildSubmitHandler(onSubmit)}
  >
    {({ values, errors = {}, touched, handleChange, isSubmitting }) => (
      <Form className='mt4'>
        <InputField
          label='Project name'
          name='name'
          touched={touched.name}
          value={values.name}
          error={errors.name}
          onChange={handleChange}
          helperText={({ value }) => <ProjectUrl projectName={value} />}
        />

        <InputField
          label='Description'
          name='description'
          onChange={handleChange}
          value={values.description}
          isOptional={true}
        />

        {/* TODO: hook this up using react-select */}
        {/* <InputField
          label="Invite collaborators"
          name="collaborators"
          isOptional={true}
          helperText="Comma-separated list of email addresses. This will send them an email."
        /> */}

        <ButtonContainer>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Saving...' : 'Continue'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

const newProjectFormSchema = yup.object().shape({
  name: yup.string().required('A project name is required.')
})

export default AddProjectForm
