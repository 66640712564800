import React from 'react'
import classNames from 'classnames'
import { distanceInWordsToNow } from 'date-fns'

import { ProductType } from 'common/types'
import { useProfileService } from 'common/profile'
import { useAuth } from 'auth'
import { Link } from './index'

const TrialBanner = () => {
  const { plan, isPlanExpired } = useProfileService()
  const { isAdmin } = useAuth()

  return plan && plan.planType === ProductType.TRIAL ? (
    <div
      className={classNames('pa3 center br--top br1 tc', {
        'bg-primary white': !isPlanExpired,
        'bg-washed-yellow b--gold bt bl br bw1 br2': isPlanExpired
      })}
      style={{ position: 'fixed', bottom: 0, left: 0, right: 0, maxWidth: '80%' }}
    >
      {isPlanExpired ? (
        <>Your trial has expired!</>
      ) : (
        <>You have {distanceInWordsToNow(plan.expiry)} left in your trial.</>
      )}{' '}
      {isAdmin() && (
        <Link
          className={classNames(['b', isPlanExpired ? 'dark-red' : 'white'])}
          to='/settings/team/billing'
        >
          Buy Markers and upgrade today.
        </Link>
      )}
    </div>
  ) : null
}

export default TrialBanner
