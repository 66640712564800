import React from 'react'
import tw from 'twin.macro'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import classNames from 'classnames'

import { tachyonsMerge } from './utils'

export const FieldContainer = ({ containerProps = { className: null }, children }) => {
  const { className, ...restProps } = containerProps
  return (
    <div className={containerProps.className || 'mb4'} {...restProps}>
      {children}
    </div>
  )
}

export const FormError = ({ error }) => (
  <div className='error-color f6-rel'>{error}</div>
)

export const Field = ({
  name = null,
  label = null,
  isOptional = false,
  touched = null,
  error = null,
  children,
  helperText = null,
  disabled = false,
  value = null,
  containerProps = undefined,
  labelClasses = undefined
}) => (
  <FieldContainer containerProps={containerProps}>
    {label && (
      <label
        htmlFor={name}
        tw='font-bold block mb-2'
        className={classNames([
          tachyonsMerge('mb2', labelClasses),
          'b db',
          disabled && 'black-60'
        ])}
      >
        {label} {isOptional && <span className='normal black-60'>(optional)</span>}
      </label>
    )}
    {touched && error && <FormError error={error} />}
    {children}
    {helperText && (
      <small
        id='name-desc'
        className={classNames(['f6 black-80 db mb2 lh-title', disabled && 'black-60'])}
      >
        {typeof helperText === 'function'
          ? helperText({ value, error, touched })
          : helperText}
      </small>
    )}
  </FieldContainer>
)

export const InputField = ({
  label = null,
  name,
  value,
  onChange,
  helperText = undefined,
  touched = null,
  error = null,
  type = 'text',
  disabled = false,
  isOptional = false,
  placeholder = null,
  containerProps = undefined,
  tabIndex = null,
  labelClasses = undefined
}) => (
  <Field
    name={name}
    label={label}
    isOptional={isOptional}
    touched={touched}
    error={error}
    helperText={helperText}
    value={value}
    containerProps={containerProps}
    labelClasses={labelClasses}
  >
    <input
      type={type}
      tw='border border-gray-200'
      className={classNames([
        'input-reset ba b--black-20 pa2 mb2 db w-100 bw1',
        touched && error && 'b--error-color',
        disabled && 'bg-light-gray'
      ])}
      placeholder={placeholder}
      id={name}
      name={name}
      aria-describedby={name}
      disabled={disabled}
      value={value}
      onChange={onChange}
      tabIndex={tabIndex}
    />
  </Field>
)

export const FreeTextField = ({
  label,
  name,
  value,
  onChange,
  helperText = undefined,
  touched,
  error = null,
  disabled = false,
  isOptional = false
}) => (
  <Field
    name={name}
    label={label}
    isOptional={isOptional}
    touched={touched}
    error={error}
    helperText={helperText}
    value={value}
  >
    <textarea
      className={`${touched && error && 'b--error-color'}`}
      tw='border border-gray-200 p-2 w-full block'
      id={name}
      name={name}
      aria-describedby={name}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  </Field>
)

export const DisplayOnlyField = ({
  label,
  name = null,
  value,
  html = false,
  helperText = undefined
}) => (
  <Field name={name} label={label} helperText={helperText} value={value}>
    {html ? <span dangerouslySetInnerHTML={{ __html: value }} /> : value}
  </Field>
)

export const ReactSelectField = ({
  label = null,
  name = null,
  value,
  onChange,
  helperText = undefined,
  touched = null,
  error = null,
  options = [],
  placeholder = null,
  disabled = false,
  isOptional = false,
  selectProps = null,
  containerProps = undefined,
  creatable = false,
  selectWrapper: SelectWrapperComponent = ({ children }) => children
}) => {
  const Component = creatable ? Creatable : Select

  return (
    <Field
      name={name}
      label={label}
      isOptional={isOptional}
      touched={touched}
      disabled={disabled}
      error={error}
      helperText={helperText}
      value={value}
      containerProps={containerProps}
    >
      <SelectWrapperComponent>
        <Component
          className='mb2 react-select'
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          isDisabled={disabled}
          aria-describedby={name}
          {...selectProps}
        />
      </SelectWrapperComponent>
    </Field>
  )
}

interface ICheckbox {
  indeterminate?: any
  checked: boolean
  onChange: any
}

export class Checkbox extends React.Component<ICheckbox> {
  el: any

  componentDidMount () {
    this.el.indeterminate = this.props.indeterminate
  }

  componentDidUpdate (prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.el.indeterminate = this.props.indeterminate
    }
  }

  render () {
    const { checked, onChange } = this.props
    return (
      <input
        type='checkbox'
        ref={el => (this.el = el)}
        checked={checked}
        onChange={onChange}
      />
    )
  }
}

export const RadioGroup = ({ label = null, children }) => {
  return <Field label={label}>{children}</Field>
}

export const RadioOption = ({
  title,
  description = null,
  name,
  value,
  onChange,
  disabled = false,
  isSelected = false,
  children = null
}) => {
  const MaybeDescriptionComponent = description
  const MaybeTitleComponent = title

  return (
    <label tw='block' css={disabled ? tw`cursor-not-allowed` : tw`cursor-pointer`}>
      <div
        tw='flex flex-row border py-2 mb-3 items-center'
        css={
          disabled
            ? tw`text-gray-500`
            : isSelected
            ? tw`bg-blue-50 border-blue-200`
            : tw`hover:bg-blue-50`
        }
      >
        <div tw='flex-none mx-2 leading-none'>
          <input
            type='radio'
            name={name}
            value={value}
            disabled={disabled}
            checked={isSelected}
            onChange={onChange}
            tw='w-5 h-5'
          />
        </div>
        <div tw='flex-1'>
          {typeof title === 'string' ? title : <MaybeTitleComponent />}
          {description &&
            (typeof description === 'string' ? (
              <div>{description}</div>
            ) : (
              <MaybeDescriptionComponent />
            ))}
          {children && children}
        </div>
      </div>
    </label>
  )
}
