import { Machine, assign } from 'xstate'
import uuid from 'uuid/v4'

import { ComponentAnnotation } from './transforms'

export const parseSelection = () => {
  const sel = window.getSelection()
  if (sel.isCollapsed) { return }

  const selectedText = sel.toString()
  const selectedRange = sel.getRangeAt(0)

  if (selectedRange.startContainer !== selectedRange.endContainer) { return } // disallow labeling across different elements
  if (selectedRange.startContainer.parentNode.nodeName === 'MARK') { return } // disallow labeling inside another label

  const { startOffset, endOffset } = selectedRange
  const parentId = sel.anchorNode.parentElement.id

  let nodeOffset = 0
  for (let i = 0; selectedRange.startContainer !== sel.anchorNode.parentElement.childNodes[i] && i < 10; i += 1) {
    const node = sel.anchorNode.parentElement.childNodes[i]

    // if we have childNodes, we're an AnnotatedElemnt (<mark> containing text node + label <dive>)
    const nodeTextContent = node.childNodes.length > 0 ? node.childNodes[0].textContent : node.textContent
    nodeOffset += nodeTextContent.length
  }
  
  const annotation: ComponentAnnotation = {
    id: uuid(),
    selectedText,
    categoryId: null,
    selector: {
      elementId: parentId,
      offset: nodeOffset + startOffset,
      length: endOffset - startOffset
    }
  }

  return annotation
}

export const annotatorMachine = Machine<{ pendingAnnotation: ComponentAnnotation }>({
  id: 'interface',
  initial: 'idle',
  context: {
    pendingAnnotation: null
  },
  states: {
    idle: {
      entry: assign({ pendingAnnotation: null }),
      on: {
        mousedown: 'ready' // TODO NEXT: transitioning to ready state is preventing click from bubbling
      },
    },
    ready: {
      on: {
        mousemove: 'dragging',
        mouseup: 'idle',
        reset: 'idle',
      },
    },
    dragging: {
      on: {
        mouseup: 'parsing',
        reset: 'idle',
      }
    },
    parsing: {
      entry: 'setPendingAnnotation',
      always: [
        { target: 'defining', cond: (ctx) => !!ctx.pendingAnnotation },
        { target: 'idle', cond: (ctx) => !ctx.pendingAnnotation },
      ]
    },
    defining: {
      on: {
        cancel: {
          actions: assign({ pendingAnnotation: null }),
          target: 'idle'
        },
        save: {
          actions: 'addAnnotation',
          target: 'idle',
        },
        reset: 'idle',
      }
    }
  }
}, {
  actions: {
    setPendingAnnotation: () => console.log('not implemented: setPendingAnnotation'),
    addAnnotation: () => console.log('not implemented: addAnnotation')
  }
})
