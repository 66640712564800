import React from 'react'

import { Button, ButtonContainer, PageHeader } from 'common/components'
import ModalDialog from 'common/components/modal-dialog'

import ProjectCodeSnippet from './project-code-snippet'

const NewProjectConfirmationModal = ({
  isOpen,
  onRequestClose,
  newProjectId,
  apiKey
}) => {
  return (
    <ModalDialog isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className='flex flex-column'>
        <div className='flex-1 lh-copy'>
          <PageHeader title='Project created successfully' />
          <p>
            Your project has been created. Get started quickly in a Jupyter notebook
            with the following snippet:
          </p>
          <ProjectCodeSnippet projectId={newProjectId} apiKey={apiKey} />
          <p>
            Your API key can also be read from the{' '}
            <span className='code bg-light-gray'>MARKERS_API_KEY</span> environment
            variable instead of being passed in as an argument (e.g. by launching your
            notebook via{' '}
            <span className='code bg-light-gray'>
              MARKERS_API_KEY={apiKey} jupyter notebook
            </span>
            ).
          </p>
        </div>
        <ButtonContainer>
          <Button onClick={onRequestClose}>Continue</Button>
        </ButtonContainer>
      </div>
    </ModalDialog>
  )
}

export default NewProjectConfirmationModal
