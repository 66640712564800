import React from 'react'

const useAnnotationState = (initialAnnotations = []) => {
  const [annotations, setAnnotations] = React.useState(initialAnnotations)
  const [hasChanges, setHasChanges] = React.useState(false)

  const addAnnotation = annotation => {
    setHasChanges(true)
    setAnnotations([...annotations, annotation])
  }

  const removeAnnotation = annotation => {
    setHasChanges(true)
    setAnnotations(
      annotations.filter(a => {
        return !(
          a.start === annotation.start &&
          a.end === annotation.end &&
          a.categoryId === annotation.categoryId
        )
      })
    )
  }

  const resetAnnotations = () => {
    setAnnotations(initialAnnotations)
    setHasChanges(false)
  }

  return {
    annotations,
    hasChanges,
    addAnnotation,
    removeAnnotation,
    setAnnotations, // TODO-3: figure out how to remove this and handle through other functions
    resetAnnotations
  }
}

export default useAnnotationState
