import React, { FC } from 'react'
import onClickOutside from 'react-onclickoutside'

import { Link } from './index'
import { isDocumentationEnabled, isSupportEnabled } from '../../feature-flags'
import { getRoleStringFromPermissions, useAuth } from 'auth'

const MenuLink = ({ to, name, onClick }) => (
  <li>
    <Link
      to={to}
      onClick={onClick}
      className='no-underline underline-hover pointer black'
    >
      {name}
    </Link>
  </li>
)

interface TopNavMenuDropdownProps {
  currentUser: {
    name: string
    permissions: any
  }
  onClickOutside: (arg0: any) => void
}

const TopNavMenuDropdown: FC<TopNavMenuDropdownProps> = ({
  currentUser,
  onClickOutside
}) => {
  const { isAdmin } = useAuth()

  const areResourcesAvailable = isDocumentationEnabled || isSupportEnabled

  // @ts-ignore
  TopNavMenuDropdown.handleClickOutside = onClickOutside

  return (
    <div className='bg-white br2 mt2 nowrap w5 ba b--moon-gray shadow-3 lh-copy'>
      <div className='bg-near-white b--moon-gray bb bw1 ph3 pv2'>
        <h3 className='ma0 f5 b dark-gray'>{currentUser.name}</h3>
        <h4 className='ma0 f5 normal gray'>
          {getRoleStringFromPermissions(currentUser.permissions)}
        </h4>
      </div>
      <div className='ph3 pv2'>
        <ul className='list pa0 mt0 mb3'>
          <MenuLink onClick={onClickOutside} to='/settings/profile' name='Profile' />
          {isAdmin() && (
            <MenuLink
              onClick={onClickOutside}
              to='/settings/team'
              name='Team settings'
            />
          )}
        </ul>
        {areResourcesAvailable && (
          <ul className='list pa0 mt0 mb3'>
            <MenuLink onClick={onClickOutside} to='/docs' name='Documentation' />
            <MenuLink onClick={onClickOutside} to='/support' name='Support' />
          </ul>
        )}
        <ul className='list pa0 mt0 mb2'>
          <MenuLink onClick={onClickOutside} to='/logout' name='Sign out' />
        </ul>
      </div>
    </div>
  )
}

const clickOutsideConfig = {
  // @ts-ignore
  handleClickOutside: () => TopNavMenuDropdown.handleClickOutside
}

const ConnectedTopNavMenuDropdown = onClickOutside(
  TopNavMenuDropdown,
  clickOutsideConfig
)

interface Props {
  placement: any
  style: any
  onClickOutside: any
  currentUser: any
}

const TopNavMenuDropdownWithRef = React.forwardRef(
  ({ placement, style, onClickOutside, currentUser }: Props, ref) => (
    <span
      // @ts-ignore
      ref={ref}
      // zIndex to appear over form elements, e.g. react-select
      style={{ ...style, zIndex: 2 }}
      data-placement={placement}
    >
      <ConnectedTopNavMenuDropdown
        onClickOutside={onClickOutside}
        currentUser={currentUser}
      />
    </span>
  )
)

export default TopNavMenuDropdownWithRef
