export const tachyonsMerge = (defaults, overrides) => {
  // TODO-3: find a better way or figure out how to memoize, this is inefficient to run at every render
  if (!overrides) {
    return defaults
  }

  const classesByFamily = {
    pv: 'pv1 pv2 pv3'.split(' '),
    ph: 'ph1 ph2 ph3'.split(' '),
    mb: 'mb1 mb2 mb3'.split(' '),
    color: 'black blue white'.split(' ')
  }

  const defaultClasses = defaults.split(' ')
  const overrideClasses = overrides.split(' ')
  const allClasses = [...defaultClasses, ...overrideClasses]

  const clashes = Object.keys(classesByFamily).reduce((acc, k) => {
    // if family classes intersect allClasses length > 1, we have specificity issues
    const intersection = classesByFamily[k].filter(x => allClasses.includes(x))
    if (intersection.length > 1) {
      return [...acc, k]
    } else {
      return acc
    }
  }, [])

  if (clashes.length > 0) {
    const mergedClasses = defaultClasses
      .reduce((acc, cls) => {
        const foundClash = clashes.find(f => classesByFamily[f].includes(cls))
        return !!foundClash ? acc : [...acc, cls]
      }, [])
      .concat(overrideClasses)
    return mergedClasses.join(' ')
  } else {
    return allClasses.join(' ')
  }
}
