import React from 'react'
import 'twin.macro'

const TaskControlsContainer = ({ label, children }) => (
  <>
    <p tw='text-sm font-bold mb-3'>{label}</p>

    {children}
  </>
)

export default TaskControlsContainer
