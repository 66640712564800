import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { PageHeader } from 'common/components'
import ModalDialog from 'common/components/modal-dialog'

import ProjectBasicInfoForm from '../components/project-wizard/basic-info-form'
import ProjectLabelConfigForm from '../components/project-wizard/label-config-form'
import ProjectReviewScreen from '../components/project-wizard/review-screen'
import WizardTrail, { StepStatus } from '../components/wizard-trail'
import {
  CREATE_CATEGORY as CREATE_CATEGORY_MUTATION,
  CATEGORY_TYPES,
  CREATE_PROJECT_MUTATION,
  GENERATE_API_KEY_MUTATION
} from '../queries'

const NewProjectWizard = ({ isOpen, onRequestClose, createProject }) => {
  const [projectData, setProjectData] = React.useState({
    project: {},
    classes: null
  })

  const isBasicInfoComplete = !isEmpty(projectData.project)
  const isLabelConfigComplete = !isNil(projectData.classes)

  const activeStep =
    (!isBasicInfoComplete && 'basic-info') ||
    (!isLabelConfigComplete && 'label-config') ||
    'review'

  const determineStateOf = (step, isComplete) =>
    step === activeStep
      ? 'active'
      : isComplete
      ? StepStatus.complete
      : StepStatus.incomplete

  const setProjectInfo = project => setProjectData({ ...projectData, project })

  const setLabelConfig = ({ documentClasses, fragmentClasses }) => {
    const classes =
      isEmpty(documentClasses) && isEmpty(fragmentClasses)
        ? {}
        : {
            document: documentClasses,
            fragment: fragmentClasses
          }

    setProjectData({ ...projectData, classes })
  }

  const renderFormForStep = step => {
    switch (activeStep) {
      case 'basic-info':
        return <ProjectBasicInfoForm onSubmit={setProjectInfo} />
      case 'label-config':
        return <ProjectLabelConfigForm onSubmit={setLabelConfig} />
      case 'review':
        return (
          <ProjectReviewScreen
            project={projectData.project}
            classes={projectData.classes}
            onSubmit={() => createProject(projectData)}
          />
        )
    }
  }

  return (
    <div className='measure-wide center'>
      <ModalDialog isOpen={isOpen} onRequestClose={onRequestClose}>
        <PageHeader title='Create new project' />

        <WizardTrail
          steps={[
            {
              key: 'basic-info',
              title: 'Basic Info',
              status: determineStateOf('basic-info', isBasicInfoComplete)
            },
            {
              key: 'label-config',
              title: 'Label Config',
              status: determineStateOf('label-config', isLabelConfigComplete)
            },
            {
              key: 'review',
              title: 'Review',
              status: determineStateOf('review', false)
            }
          ]}
          onStepSelect={step => console.log('selected step', step)}
        />

        {renderFormForStep(activeStep)}
      </ModalDialog>
    </div>
  )
}

const ConnectedNewProjectWizard = ({
  navigate,
  isOpen,
  onRequestClose,
  currentUserId,
  ...props
}) => {
  const [createProject] = useMutation(CREATE_PROJECT_MUTATION)
  const [createClass] = useMutation(CREATE_CATEGORY_MUTATION)
  const [createApiKey] = useMutation(GENERATE_API_KEY_MUTATION)

  const handleCreateProject = async variables => {
    // TODO-3: this should be all in one transaction/mutation

    try {
      const { classes } = variables
      const defaultTaskType = !isEmpty(classes)
        ? [
            classes.document.length > 0 && 'classification',
            classes.fragment.length > 0 && 'fragment'
          ].filter(Boolean)
        : null
      const settings = defaultTaskType ? { default_task_type: defaultTaskType } : {}

      const result = await createProject({
        variables: { ...variables.project, settings }
      })
      const newProject = result.data.createProject.project

      for (let cat of classes.document) {
        await createClass({
          variables: {
            projectId: newProject.id,
            type: CATEGORY_TYPES.DOCUMENT,
            name: cat.value
          }
        })
      }

      for (let cat of classes.fragment) {
        await createClass({
          variables: {
            projectId: newProject.id,
            type: CATEGORY_TYPES.FRAGMENT,
            name: cat.value,
            properties: { color: '#adf' }
          }
        })
      }

      const apiKeyResult = await createApiKey({
        variables: { projectId: newProject.id, userId: currentUserId }
      })
      const apiKey = apiKeyResult.data.generateApiKeyForCurrentTenant.apiKey.key

      navigate(`/projects/${newProject.id}`, {
        state: { newProjectId: newProject.id, apiKey }
      })
    } catch (err) {
      console.error(err)
      // TODO-2: error handling
    }
  }

  return (
    <NewProjectWizard
      createProject={handleCreateProject}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      {...props}
    />
  )
}

export default ConnectedNewProjectWizard
