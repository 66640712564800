import React from 'react'
import { Formik, Form } from 'formik'

import { allowModifyOfReviewLabels } from '../../feature-flags'

import { Button, ButtonContainer } from 'common/components'
import { FormError } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

import { Config, Task, TaskAction } from '../types'

import FragmentControls from './fragment-controls'
import ClassificationControls from './classification-controls'
interface DocumentControlsProps {
  config: Config
  task: Task
  labels: any[]
  onSkip(arg0: any): any
  onSave(arg0: any): any
  onReject(): any
  hasChanges: boolean
  resetChanges: any
  error: any
}

const DocumentControls = ({
  config,
  task,
  error,
  labels,
  onSkip: handleSkip,
  onSave,
  onReject: handleReject,
  hasChanges = false,
  resetChanges = () => {}
}: DocumentControlsProps) => (
  <Formik
    initialValues={{
      documentClass: task.label
        ? task.label.categoryId
        : task.externalLabel
        ? task.externalLabel.contents.categoryId
        : ''
    }}
    onSubmit={buildSubmitHandler(onSave, { shouldResetForm: true })}
    enableReinitialize={true}
  >
    {({ values, handleChange, resetForm, dirty }) => (
      <Form>
        {error && <FormError error={error} />}

        {task.type.includes('classification') && (
          <ClassificationControls config={config} action={task.action} values={values} onChange={handleChange} />
        )}

        {task.type.includes('fragment') && (
          <FragmentControls config={config} action={task.action} labels={labels} />
        )}

        {task.action === TaskAction.Review ? (
          <ReviewControlButtons
            onReject={handleReject}
            onSkip={handleSkip}
            hasChanges={allowModifyOfReviewLabels && (hasChanges || dirty)}
            onReset={() => {
              resetForm() // reset document classification
              resetChanges() // reset annotations
            }}
          />
        ) : (
          <CreateControlButtons onReset={resetForm} onSkip={handleSkip} />
        )}
      </Form>
    )}
  </Formik>
)

const ReviewControlButtons = ({ hasChanges = false, onReject, onReset, onSkip }) => (
  <>
    <ButtonContainer expand>
      <Button type='submit'>
        {allowModifyOfReviewLabels || hasChanges ? 'Modify' : 'Accept'}
      </Button>
      <Button onClick={onReject} variant='primary-red' disabled={hasChanges}>
        Reject
      </Button>
      <Button
        variant='secondary-gray'
        onClick={() => {
          onReset()
          onSkip()
        }}
      >
        Skip
      </Button>
    </ButtonContainer>

    {allowModifyOfReviewLabels && (
      <ButtonContainer>
        <Button variant='tertiary-red' onClick={onReset} disabled={!hasChanges}>
          Reset changes
        </Button>
      </ButtonContainer>
    )}
  </>
)

// TODO-3: is onReset still needed?
const CreateControlButtons = ({ onReset, onSkip }) => (
  <ButtonContainer expand>
    <Button wide type='submit'>
      Save
    </Button>
    <Button
      variant='secondary-gray'
      onClick={() => {
        onReset()
        onSkip()
      }}
    >
      Skip
    </Button>
  </ButtonContainer>
)

export default DocumentControls
