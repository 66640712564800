import React from 'react'
import uniq from 'lodash/uniq'

import { Button, ButtonContainer } from 'common/components'

const PaginationLink = ({ page, disabled = false, children, onClick }) => (
  <Button variant='tertiary-gray' disabled={disabled} onClick={() => onClick(page)}>
    {children}
  </Button>
)

interface Props {
  metadata: {
    currentPage: number
    totalPages: number
  }
  onClick: (arg1: any) => void
}

class Pagination extends React.Component<Props> {
  renderLeftControls = () => {
    const { metadata, onClick } = this.props
    const prev_page = metadata.currentPage - 1
    const hasPrevPage = metadata.currentPage !== 1
    return (
      <PaginationLink
        key={'prev'}
        disabled={!hasPrevPage}
        page={prev_page}
        onClick={onClick}
      >
        prev
      </PaginationLink>
    )
  }

  renderRightControls = () => {
    const { metadata, onClick } = this.props
    const prev_page = metadata.currentPage + 1
    const hasNextPage = metadata.currentPage < metadata.totalPages
    return (
      <PaginationLink
        key={'next'}
        disabled={!hasNextPage}
        page={prev_page}
        onClick={onClick}
      >
        next
      </PaginationLink>
    )
  }

  renderPageList = () => {
    const { metadata, onClick } = this.props

    const currentPage = metadata.currentPage
    let pages = []
    pages = pages.concat(1)
    pages = pages.concat(currentPage - 1)
    pages = pages.concat(currentPage)
    pages = pages.concat(currentPage + 1)
    pages = pages.concat(metadata.totalPages)

    pages = uniq(pages.filter(x => x >= 1 && x <= metadata.totalPages))

    const pageElements = pages.reduce(
      (acc, page, index) => [
        ...acc,
        page - pages[index - 1] > 1 ? <span key={`ellipsis-${index}`}>...</span> : null,
        metadata.currentPage === page ? (
          <span key={page} className='b'>
            {page}
          </span>
        ) : (
          <PaginationLink key={page} page={page} onClick={onClick}>
            {page}
          </PaginationLink>
        )
      ],
      []
    )

    return pageElements
  }

  render () {
    return (
      <ButtonContainer>
        {this.renderLeftControls()}
        {this.renderPageList()}
        {this.renderRightControls()}
      </ButtonContainer>
    )
  }
}

export default Pagination
