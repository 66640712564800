import React from 'react'
import onClickOutside from 'react-onclickoutside'

import { FilterControls, makeFiltersDropdown, useFilters } from './filters'
import SummaryFiltersForm from './summary-filters-form'

const SummaryFiltersDropdown = onClickOutside(makeFiltersDropdown(SummaryFiltersForm))

const ProjectSummaryFilters = ({ cycles, query, onChange }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const options = {
    cycle: cycles
  }

  const labelMap = { cycle: 'Cycle' }

  const { currentFilters, removeFilter, clearFilters } = useFilters(
    options,
    onChange,
    query
  )

  const handleSaveFilters = args => {
    const { cycle } = args
    let filters: any = {}
    if (cycle) {
      filters.cycle = cycle.id
    }

    onChange(filters)
    setIsOpen(false)
  }

  const handleClearFilters = () => {
    clearFilters()
    setIsOpen(false)
  }

  return (
    <FilterControls
      isOpen={isOpen}
      onOpenModal={() => setIsOpen(true)}
      currentFilters={currentFilters}
      onRemoveFilter={removeFilter}
      labelMap={labelMap}
      filtersModal={() => (
        <SummaryFiltersDropdown
          onClickOutside={() => setIsOpen(false)}
          onClearFilters={handleClearFilters}
          onSaveFilters={handleSaveFilters}
          activeCycle={currentFilters.cycle}
          cycles={options.cycle}
        />
      )}
    />
  )
}

export default ProjectSummaryFilters
