import React from 'react'
import { Router } from '@reach/router'

import WebhooksIndex from './pages'
import EditWebhookPage from './pages/edit-webhook'
import NewWebhookPage from './pages/new-webhook'

const WebhookRoutes = props => (
  <Router primary={false}>
    <NewWebhookPage {...props} path='new' />
    <EditWebhookPage {...props} path=':webhookId' />
    <WebhooksIndex {...props} default path='/' />
  </Router>
)

export default WebhookRoutes
