import React, { FC } from 'react'
import { Link as RRLink } from '@reach/router'
import classNames from 'classnames'

import { tachyonsMerge } from './utils'

export { default as Button } from './button'
export { default as ButtonContainer } from './button-container'
export { default as LinkButton } from './link-button'
export { default as DataTable } from './data-table'
export { TableHeader, HeaderCell, TableBody, Row, Cell, Table } from './table'
export { default as PageHeading } from './page-heading'
export { default as Pagination } from './pagination'
export { default as ModalDialog } from './modal-dialog'
export { default as IndeterminateCheckbox } from './indeterminate-checkbox'

interface PageHeaderProps {
  className?: string
  title: string
}

export const PageHeader: FC<PageHeaderProps> = ({
  className = '',
  title,
  children
}) => {
  const classes = classNames(['mb3 pb1 bb flex items-center', className])

  return (
    <div className={classes}>
      <h3 className='mt0 f5 pb0 mb0 flex-1'>{title}</h3>
      {children}
    </div>
  )
}

export const Link = ({ className = '', ...props }) => (
  // @ts-ignore
  <RRLink
    className={tachyonsMerge('link underline pointer blue', className)}
    {...props}
  />
)

export const Tier3Nav = ({ children }) => (
  <ul className='list ph0 mr4 mv0'>{children}</ul>
)

export const T3NavItem = ({ to, name, exact = false }) => {
  const activeClasses = 'b--primary-color bg-near-white' // : 'b--white'
  const inactiveClasses = 'b--white'

  return (
    <li className='mb2'>
      <RRLink
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          const className = classNames([
            'ph3 pv2 db bl bw2 black no-underline underline-hover',
            (exact ? isCurrent : isPartiallyCurrent) ? activeClasses : inactiveClasses
          ])
          return { className }
        }}
        to={to}
      >
        {name}
      </RRLink>
    </li>
  )
}

export const Code = props => <code className='f7 pa1 bg-near-white' {...props} />

export const Badge = ({ type = 'success', ...restProps }) => {
  const classes = classNames({
    'ph2 pv1 bw1 ba br-pill': true,
    'bg-washed-green green': type === 'success',
    'bg-washed-error-color error-color': type === 'error'
  })
  return <span className={classes} {...restProps} />
}

export const Tag = ({ className = 'bg-near-white', style = null, children }) => (
  <span className={classNames(['dib ph2 pv1 mr3', className])} style={style}>
    {children}
  </span>
)
