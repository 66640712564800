import React from 'react'
import classNames from 'classnames'

const calloutClasses = 'ba b--moon-gray bg-near-white ph3 pv3 mb3 lh-copy'

const Callout = ({ className = '', ...props }) => (
  <div className={classNames([calloutClasses, className])} {...props} />
)

export default Callout
