import React, {
  useEffect,
  useContext,
  createContext,
  useReducer,
  Dispatch
} from 'react'
import jwtDecode from 'jwt-decode'
import get from 'lodash/get'

import { authInstance } from './service'

interface AuthState {
  authToken?: string
  permissions?: object
  tokenExpiry?: number
  userId?: string
}

type AuthAction = { type: 'LOGIN'; payload: { auth: AuthState } } | { type: 'LOGOUT' }

interface Context {
  state: AuthState
  dispatch: Dispatch<AuthAction>
}

const AuthContext = createContext({} as Context)

export const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext)

  const isAuthenticated = () => !!state.authToken
  const getUserId = () => state.userId
  const getPermissions = () => state.permissions
  const getRole = () => get(state.permissions, 'role')
  const isAdmin = () => getRole() === 'admin'

  const login = (authToken: string) => {
    authInstance.login(authToken)
    dispatch({ type: 'LOGIN', payload: { auth: authFromToken(authToken) } })
  }

  const logout = apolloClient => {
    authInstance.logout(apolloClient)
    dispatch({ type: 'LOGOUT' })
  }

  return {
    isAuthenticated,
    getUserId,
    getPermissions,
    getRole,
    isAdmin,
    login,
    logout
  }
}

const authReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'LOGIN':
      return action.payload.auth
    case 'LOGOUT':
      return emptyAuthState
    default:
      return state
  }
}

const emptyAuthState: AuthState = {
  authToken: null,
  permissions: null,
  tokenExpiry: null,
  userId: null
}

const authFromToken = authToken => {
  const decoded = jwtDecode(authToken)

  return {
    authToken,
    userId: decoded.user_id,
    permissions: {
      role: decoded.app_role
    },
    tokenExpiry: decoded.exp
  }
}

export const AuthProvider = props => {
  const [state, dispatch] = useReducer(authReducer, emptyAuthState)

  useEffect(() => {
    // NOTE: this could be an expired token
    const authToken = window.localStorage.getItem('authToken')

    if (authToken) {
      dispatch({ type: 'LOGIN', payload: { auth: authFromToken(authToken) } })
    }

    if (!authInstance.afterLogoutHook) {
      authInstance.afterLogoutHook = () => dispatch({ type: 'LOGOUT' })
    }
  }, [])

  return <AuthContext.Provider {...props} value={{ state, dispatch }} />
}

export { default as LogoutRoute } from './pages/logout'
export { default as LoginRoute } from './pages/login'

export { getRoleStringFromPermissions } from './utils'
