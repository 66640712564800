import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { useParams } from 'common/location'
import { useFlash } from 'common/flash'

import ProjectLabelInterface from './components/project-label-interface'
import useAnnotatorData from './use-annotator-data'
interface AnnotatorPageProps extends RouteComponentProps {
  projectId?: string
}

const AnnotatorPage = ({ projectId, navigate }: AnnotatorPageProps) => {
  const { setFlashMessage } = useFlash()
  const params = useParams()

  const { data, loading, error, createLabel, skipTask } = useAnnotatorData({
    projectId,
    cycleId: params.cycle
  })

  if (loading) return <div>loading...</div>
  if (error) return <div>error: {error.message}</div>

  const { project, task, document, progress, currentUserProfile } = data

  if (!document) {
    navigate(`/projects/${projectId}`, {
      state: {
        flash: params.cycle
          ? `You have no tasks remaining for cycle: ${params.cycle}`
          : `You have no tasks remaining for this project.`
      }
    })
    return null
  }

  // TODO-3: remove currentUserProfile, instead use current_user_id() in a postgres function
  const { id: creatorId } = currentUserProfile
  const cycleId = task.cycleId

  const handleCreateLabel = async values => {
    await createLabel({
      variables: {
        projectId,
        cycleId,
        creatorId,
        taskId: task.id,
        ...values
      }
    })
    setFlashMessage('Label saved successfully.')
  }

  const handleCreateReviewLabel = async values => {
    const { documentId } = values

    const contents = {
      accept: { result: 'accept' },
      reject: { result: 'reject' },
      modify: values.contents
    }[values.contents.result]

    await createLabel({
      variables: {
        projectId,
        cycleId,
        creatorId,
        taskId: task.id,
        documentId,
        taskAction: 'review',
        parentLabelId: task.externalLabel.id,
        contents: contents
      }
    })
  }

  const handleSkipTask = async () => {
    await skipTask({ variables: { taskId: task.id } })
    setFlashMessage('Task skipped successfully.')
  }

  return (
    <ProjectLabelInterface
      project={project}
      task={task}
      document={document}
      metadata={{ progress }}
      onSkipTask={handleSkipTask}
      createLabel={handleCreateLabel}
      createReviewLabel={handleCreateReviewLabel}
    />
  )
}

export default AnnotatorPage
