import React, { FC, useMemo } from 'react'
import 'twin.macro'
import isEmpty from 'lodash/isEmpty'

import { Button, ButtonContainer } from 'common/components'

import useAnnotationState from '../use-annotation-state'
import Annotator from './annotator'
import DocumentControls from './document-controls'
import validateTask from './validate-task'

import { Task, Config, TaskAction } from '../types'
interface Props {
  task: Task
  config: Config
  document: any
  // TODO: convert createLabel and createReviewLabel into onCompleteTask
  createLabel: Function
  createReviewLabel: Function
  onSkipTask: Function
  sidebarTop?: Function
  onClose?: Function
}

export const LabelInterface: FC<Props> = ({
  task,
  config,
  document,
  onSkipTask,
  createLabel,
  createReviewLabel,
  sidebarTop: SidebarTop = () => null,
  onClose = null
}: Props) => {
  const initialAnnotations = useMemo(() => {
    if (task.action === TaskAction.Review) {
      return task.externalLabel ? task.externalLabel.contents.annotations : []
    } else {
      return task.label ? task.label.annotations : []
    }
  }, [task])

  const {
    annotations,
    hasChanges,
    addAnnotation,
    removeAnnotation,
    setAnnotations,
    resetAnnotations
  } = useAnnotationState(initialAnnotations)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    setAnnotations(initialAnnotations)
  }, [setAnnotations, initialAnnotations])

  const handleSkipTask = () => {
    onSkipTask()
    resetInterface()
  }

  const resetInterface = () => {
    setError(null)
    resetAnnotations()
  }

  const handleSaveLabel = async values => {
    const { documentClass: categoryId } = values
    const [isValid, validationError] = validateTask(task, categoryId, annotations)
    if (!isValid) {
      setError(validationError)
      return
    }

    const mutations: Record<TaskAction, Function> = {
      [TaskAction.Create]: () => {
        createLabel({
          documentId: document.id,
          contents: {
            categoryId,
            annotations: isEmpty(annotations) ? null : annotations
          }
        })
      },
      [TaskAction.Review]: () => {
        createReviewLabel({
          documentId: document.id,
          contents: {
            result: hasChanges ? 'modify' : 'accept',
            categoryId,
            annotations
          }
        })
      }
    }

    mutations[task.action]()

    resetInterface()
  }

  const handleRejectLabel = async () => {
    createReviewLabel({
      documentId: document.id,
      contents: { result: 'reject' }
    })
    resetInterface()
  }

  return (
    <div tw='flex bg-gray-50 border border-gray-200 p-3' data-cy='widget'>
      <div tw='flex-2'>
        <Annotator
          annotations={annotations}
          categories={config.fragmentCategories}
          onAddAnnotation={addAnnotation}
          onRemoveAnnotation={removeAnnotation}
          readOnly={task.action !== TaskAction.Create}
        >
          {document.contents}
        </Annotator>
      </div>

      {/* TODO-3: make this max-height ~400 and position sticky */}
      <div tw='flex-1 flex flex-col ml-4'>
        <div tw='flex-1'>
          <div tw='bg-white border border-gray-200 p-3 shadow rounded'>
            <SidebarTop />
            <DocumentControls
              config={config}
              task={task}
              labels={annotations}
              error={error}
              onSkip={handleSkipTask}
              onSave={handleSaveLabel}
              hasChanges={hasChanges as boolean}
              resetChanges={resetAnnotations}
              onReject={handleRejectLabel}
            />
          </div>
        </div>
        {onClose && (
          <div tw='flex-none'>
            <ButtonContainer>
              <Button variant='secondary-red' onClick={() => onClose()}>
                Exit Labeling
              </Button>
            </ButtonContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default LabelInterface
