import React, { forwardRef } from 'react'
import { useTable, useRowSelect, usePagination, Column } from 'react-table'

import { elementJoin } from 'common/utils'
import {
  Button,
  Cell,
  HeaderCell,
  IndeterminateCheckbox,
  ModalDialog,
  Pagination,
  Row,
  Table,
  TableBody
} from 'common/components'
import { PAGE_SIZE } from 'common/pagination'

export interface DataTableRef {
  toggleAllRowsSelected: (areSelected: boolean) => void
}

type ModalComponentWrapper<T> = (
  data: T,
  handleCloseModal: () => void
) => React.ReactNode

export interface RowAction<T> {
  label?: string
  handler?: (arg1: T) => void
  check?: (arg1: T) => boolean
  modalComponent?: ModalComponentWrapper<T>
  component?: React.ComponentType
}

export interface OnSelectionChangeArgs {
  state: 'none' | 'some' | 'page' | 'all'
  selectedRowIds: string[]
}

export interface TableAction<T> {
  label: string
  handler?: (arg1: T) => void
  modalComponent?: ModalComponentWrapper<T>
}

interface Props<T extends object> {
  data: T[]
  columns: Column<T>[]
  metadata?: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
  isSelectable?: boolean
  rowActions?: RowAction<T>[]
  tableActions?: TableAction<T>[]
  fetchData?: (page: number) => void
  onSelectionChange?: (args: OnSelectionChangeArgs) => void
}

const DataTable = <T extends object>(
  {
    data,
    columns,
    metadata,
    rowActions = [],
    tableActions,
    isSelectable = false,
    onSelectionChange = () => {},
    fetchData = () => {}
  }: Props<T>,
  ref
) => {
  // NOTE: memoizing columns causes a bug
  // eslint-disable-next-line
  const rtColumns = React.useMemo(() => columns, [])

  React.useImperativeHandle(ref, () => ({
    toggleAllRowsSelected
  }))

  const [areAllMatchingSelected, setAreAllMatchingSelected] = React.useState(false)

  const handleSelectAllMatchingSearch = () => {
    if (areAllMatchingSelected) {
      setAreAllMatchingSelected(false)
      // NOTE: onSelectionChange is handled by react-table stateReducer
    } else {
      setAreAllMatchingSelected(true)
      onSelectionChange({ state: 'all', selectedRowIds: [] })
    }
  }

  const [modalComponent, setModalComponent] = React.useState<ModalComponentWrapper<T>>()
  const [modalRecord, setModalRecord] = React.useState<T>()
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  type HandleOpenModal = {
    modalComponent: ModalComponentWrapper<T>
    modalRecord: T
  }
  const handleOpenModal = ({ modalComponent, modalRecord }: HandleOpenModal) => {
    setModalComponent(() => modalComponent)
    setModalRecord(modalRecord)
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalComponent(null)
    setModalRecord(null)
    setIsModalOpen(false)
  }

  const renderRowActions = (row: T) => {
    const actionComponents = rowActions
      .map((action, index) => {
        const { label, check, handler, modalComponent, component } = action

        if (typeof check !== 'undefined' && !check(row)) {
          return null
        }

        if (typeof component === 'undefined') {
          const handleClick = modalComponent
            ? modalRecord => handleOpenModal({ modalComponent, modalRecord })
            : handler

          return (
            <Button
              variant='tertiary-blue'
              key={index}
              onClick={() => handleClick(row)}
            >
              {label}
            </Button>
          )
        } else {
          const ActionComponent = component
          return <ActionComponent key={index} {...row} />
        }
      })
      .filter(Boolean)

    return elementJoin(actionComponents, ' / ')
  }

  const renderActions = () => {
    if (!tableActions) {
      return null
    }

    return tableActions.map((action, id) => {
      const { label, handler, modalComponent } = action
      const handleClick = modalComponent
        ? (modalRecord: T) => handleOpenModal({ modalComponent, modalRecord })
        : handler
      return (
        <p key={id}>
          {/*
          // TODO: fix this
          // @ts-ignore */}
          <Button variant='tertiary-blue' onClick={handleClick}>
            {label}
          </Button>
        </p>
      )
    })
  }

  const stateReducer = (state, _action, _prevState) => {
    const { selectedRowIds: rowIdsObj } = state
    const { pageSize } = state
    const selectedRowIds = Object.keys(rowIdsObj)
      .reduce((acc, id) => [...acc, data[id]], [])
      .map(row => row.id)
    const selectionState =
      selectedRowIds.length === 0
        ? 'none'
        : selectedRowIds.length === pageSize
        ? 'page'
        : 'some'

    if (Object.keys(rowIdsObj).length === 0) {
      setAreAllMatchingSelected(false)
    }

    onSelectionChange({ state: selectionState, selectedRowIds })
    return state
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    prepareRow,
    // @ts-ignore
    isAllRowsSelected,
    // @ts-ignore
    toggleAllRowsSelected,
    // @ts-ignore
    state: { selectedRowIds }
  } = useTable<T>(
    {
      columns: rtColumns,
      data,
      // @ts-ignore: workaround of react-table paginated type
      initialState: { pageSize: PAGE_SIZE },
      stateReducer
    },
    useRowSelect,
    usePagination,
    hooks => {
      hooks.visibleColumns.push(columns =>
        [
          isSelectable && {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns,
          rowActions.length > 0 && {
            id: 'actions',
            Header: () => <div className='tr'>Actions</div>,
            Cell: ({ row }) => (
              <div className='tr'>{renderRowActions(row.original)}</div>
            )
          }
        ].filter(Boolean)
      )
    }
  )

  const currentPageSize = data.length
  const hasActions = rowActions.length > 0

  const canSelectAcrossPages = false // TODO-3: to-be-fixed with batch actions + multi-page selections

  return (
    <>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <HeaderCell
                  noPadding={index === 0 || index === headerGroup.headers.length - 1}
                  // @ts-ignore
                  {...column.getHeaderProps(column.headerProps)}
                  key={index}
                >
                  {column.render('Header')}
                </HeaderCell>
              ))}
            </tr>
          ))}
          {canSelectAcrossPages &&
            (Object.keys(selectedRowIds).length === PAGE_SIZE || isAllRowsSelected) && (
              <tr>
                <HeaderCell
                  secondary
                  colSpan={
                    headerGroups[0].headers.length +
                    (hasActions ? 1 : 0) +
                    (isSelectable ? 1 : 0)
                  }
                  className='tc'
                >
                  {!areAllMatchingSelected ? (
                    <>
                      All {currentPageSize} items on this page are selected.{' '}
                      <Button
                        variant='tertiary-blue'
                        onClick={handleSelectAllMatchingSearch}
                      >
                        Select all items that match this search
                      </Button>
                    </>
                  ) : (
                    <>
                      {metadata ? (
                        <>
                          All {metadata.totalCount} items in this search are selected.{' '}
                        </>
                      ) : (
                        <>All items in this search are selected. </>
                      )}
                      <Button
                        variant='tertiary-red'
                        onClick={() => {
                          toggleAllRowsSelected(false)
                          handleSelectAllMatchingSearch()
                        }}
                      >
                        Clear selection
                      </Button>
                    </>
                  )}
                </HeaderCell>
              </tr>
            )}
        </thead>
        <TableBody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <Row {...row.getRowProps()} isSelected={row.isSelected}>
                {row.cells.map((cell, index) => (
                  <Cell
                    noPadding={index === 0 || index === row.cells.length - 1}
                    {...cell.getCellProps(cell.column.cellProps)}
                    key={index}
                  >
                    {cell.render('Cell')}
                  </Cell>
                ))}
              </Row>
            )
          })}
        </TableBody>
      </Table>

      {metadata && <Pagination metadata={metadata} onClick={fetchData} />}

      {renderActions()}

      {modalComponent && (
        <ModalDialog isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          {modalComponent(modalRecord, handleCloseModal)}
        </ModalDialog>
      )}
    </>
  )
}

export default forwardRef(DataTable)
