import gql from 'graphql-tag'

import { CATEGORY_FIELDS_FRAGMENT } from 'project/queries'

export const NEXT_INCOMPLETE_TASK_QUERY = gql`
  query NextIncompleteTask(
    $projectId: UUID!
    $cycleId: String
    $externalDocumentIds: [String]
  ) {
    nextIncompleteTask(
      projectId: $projectId
      cycleId: $cycleId
      externalDocumentIds: $externalDocumentIds
      first: 1
    ) {
      nodes {
        nodeId
        id
        cycleId
        type
        action
        priority
        document {
          id
          externalId
          contents
        }
        externalLabel {
          id
          contents
        }
      }
    }
    projectById(id: $projectId) {
      name
      instructions
      documentCategories: categories(condition: { type: DOCUMENT }) {
        nodes {
          ...documentCategoryFields
        }
      }
      fragmentCategories: categories(condition: { type: FRAGMENT }) {
        nodes {
          ...annotationCategoryFields
        }
      }
    }
    taskProgress(
      projectId: $projectId
      cycleId: $cycleId
      externalDocumentIds: $externalDocumentIds
    ) {
      complete
      incomplete
      skipped
      total
    }
    currentUserProfile {
      nodeId
      id
    }
  }
  ${CATEGORY_FIELDS_FRAGMENT}
`

export const SKIP_TASK_MUTATION = gql`
  mutation SkipTask($taskId: UUID!) {
    updateTaskById(input: { id: $taskId, patch: { status: SKIPPED } }) {
      clientMutationId
    }
  }
`

export const CREATE_LABEL_MUTATION = gql`
  mutation CreateLabel(
    $creatorId: UUID!
    $projectId: UUID!
    $cycleId: String!
    $documentId: UUID!
    $taskId: UUID!
    $contents: JSON!
    $taskAction: String
    $parentLabelId: UUID
  ) {
    createLabel(
      input: {
        label: {
          creatorId: $creatorId
          projectId: $projectId
          cycleId: $cycleId
          taskId: $taskId
          documentId: $documentId
          contents: $contents
          taskAction: $taskAction
          parentLabelId: $parentLabelId
        }
      }
    ) {
      label {
        id
        documentId
      }
    }
    updateTaskById(input: { id: $taskId, patch: { status: COMPLETE } }) {
      task {
        assigneeId
        documentId
        status
      }
    }
  }
`
