import React from 'react'
import 'twin.macro'
import * as yup from 'yup'
import { Formik, Form } from 'formik'

import { Button } from 'common/components'
import { FlashError } from 'common/flash'
import { InputField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

const LoginForm = ({ onSubmit, status }) => (
  <Formik
    initialValues={{ email: '', password: '' }}
    validationSchema={loginFormSchema}
    onSubmit={buildSubmitHandler(onSubmit)}
  >
    {({ handleChange, touched, values, errors = {}, isSubmitting }) => (
      <Form>
        {status.error && <FlashError>{status.error}</FlashError>}

        <InputField
          placeholder='email'
          name='email'
          onChange={handleChange}
          touched={touched.email}
          value={values.email}
          error={errors.email}
          tabIndex={1}
          containerProps={{ className: 'mb3' }}
        />
        <InputField
          placeholder='password'
          name='password'
          type='password'
          onChange={handleChange}
          touched={touched.password}
          value={values.password}
          error={errors.password}
          tabIndex={2}
          containerProps={{ className: 'mb3' }}
        />
        <Button tw='w-full' type='submit' disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Log in'}
        </Button>
      </Form>
    )}
  </Formik>
)

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .email("This doesn't look like a valid email address.")
    .required("You can't login without an email!"),
  password: yup.string().required("You can't login without a password!")
})

export default LoginForm
