import React from 'react'

const ControlBar = ({ buttons: Buttons, filters: Filters }) => (
  <div
    className='flex justify-between items-center bg-off-white b--light-gray ba bw1 pv2 ph3 mb3'
    style={{ marginTop: -16 }}
  >
    <div className='flex-1'>
      <Buttons />
    </div>
    <div className='flex-1'>
      <Filters />
    </div>
  </div>
)

export default ControlBar
