import { Link } from '@reach/router'
import tw, { styled } from 'twin.macro'

import { SharedButtonProps, sharedButtonStyles } from './button'

interface LinkButtonProps extends SharedButtonProps {
  to: string | object
}

const LinkButton = styled(Link)<LinkButtonProps>(({ variant = 'primary-blue' }) => [
  tw`px-4 py-2 transition-colors inline-block rounded`,
  sharedButtonStyles[variant]
])

export default LinkButton
