import React from 'react'
import { Formik, Form } from 'formik'

import { Button, ButtonContainer } from 'common/components'
import { ReactSelectField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

const DropdownIndicator = () => null

const LabelConfigForm = ({ onSubmit }) => (
  <Formik
    initialValues={{ documentClasses: [], fragmentClasses: [] }}
    onSubmit={buildSubmitHandler(onSubmit)}
  >
    {({ values, errors = {}, touched, isSubmitting, setFieldValue }) => (
      <Form className='mt4'>
        <ReactSelectField
          creatable
          label='Document label classes'
          name='documentClasses'
          placeholder='Tab-separated list of document classes...'
          touched={touched.documentClasses}
          value={values.documentClasses}
          error={errors.documentClasses}
          onChange={value => setFieldValue('documentClasses', value)}
          selectProps={{
            isMulti: true,
            components: { DropdownIndicator }
          }}
          helperText='Useful for classification tasks.'
        />

        <ReactSelectField
          creatable
          label='Fragment label classes'
          name='fragmentClasses'
          placeholder='Tab-separated list of fragment classes...'
          touched={touched.fragmentClasses}
          value={values.fragmentClasses}
          error={errors.fragmentClasses}
          onChange={value => setFieldValue('fragmentClasses', value)}
          selectProps={{
            isMulti: true,
            components: { DropdownIndicator }
          }}
          helperText='Useful for named-entity recognition (NER) tasks.'
        />

        <ButtonContainer>
          <Button type='submit' disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Continue'}
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

export default LabelConfigForm
