import React from 'react'
import Dropzone from 'react-dropzone'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

import { Button } from 'common/components'
import Callout from 'common/components/callout'

import { DOCUMENTS_LISTING_QUERY } from '../queries'
import { PROJECT_QUERY } from '../../queries'
import FileList from '../components/file-list'

const CREATE_DOCUMENT = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      document {
        id
        externalId
      }
    }
  }
`

interface Props {
  onUploadStart: (arg0: any) => void
  createDocument: (arg0: any) => void
  dataset: { id: string }
  uploadedFiles: unknown[]
}

class DocumentsUploadPage extends React.Component<Props> {
  handleDroppedFile = async (acceptedFiles = []) => {
    const { onUploadStart, createDocument, dataset } = this.props
    if (acceptedFiles.length === 0) {
      window.alert('Maximum allowed filesize is 64 kilobytes.')
      return
    }

    if (acceptedFiles.length > 50) {
      window.alert('A maximum of 50 files may be uploaded at a time.')
      return
    }

    acceptedFiles.forEach(file => {
      onUploadStart(file)
      createDocument({
        variables: {
          input: {
            document: {
              externalId: file.name,
              datasetId: dataset.id,
              contents: file
            }
          }
        }
      })
    })
  }

  render () {
    const { uploadedFiles } = this.props

    return (
      <div className='flex'>
        <div className='flex-1'>
          <Callout>
            <Dropzone
              accept='text/plain'
              maxSize={65536}
              onDrop={this.handleDroppedFile}
              className='db'
              activeClassName='o-60'
            >
              <div className='f4 bg-white pv6 ph4 tc'>
                <div className='f3'>
                  <FontAwesomeIcon icon={faUpload} />
                </div>
                <p>
                  Drag files here or{' '}
                  <Button variant='tertiary-gray' onClick={() => {}}>
                    browse
                  </Button>
                  .
                </p>
              </div>
            </Dropzone>
          </Callout>
        </div>
        <div className='flex-1'>
          <FileList files={uploadedFiles} />
        </div>
      </div>
    )
  }
}

const ConnectedDocumentsUploadPage = props => {
  const { project } = props
  const dataset = project.projectToDatasets.nodes[0].dataset
  const [uploadedFiles, setUploadedFiles] = React.useState([])

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    refetchQueries: [
      { query: PROJECT_QUERY, variables: { projectId: project.id } },
      { query: DOCUMENTS_LISTING_QUERY, variables: { datasetId: dataset.id } }
    ]
  })

  const handleUploadStart = file => {
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.concat({ name: file.name, isUploading: true })
    )
  }

  const handleCreateDocument = async values => {
    const { data } = await createDocument(values)
    const {
      createDocument: { document }
    } = data
    setUploadedFiles(uploadedFiles => {
      const index = uploadedFiles.findIndex(f => f.name === document.externalId)
      return index !== -1
        ? [
            ...uploadedFiles.slice(0, index),
            { ...uploadedFiles[index], isUploading: false },
            ...uploadedFiles.slice(index + 1)
          ]
        : null
    })
  }

  return (
    <DocumentsUploadPage
      {...props}
      dataset={dataset}
      createDocument={handleCreateDocument}
      onUploadStart={handleUploadStart}
      uploadedFiles={uploadedFiles}
    />
  )
}

export default ConnectedDocumentsUploadPage
