import React from 'react'
import get from 'lodash/get'
import { RouteComponentProps } from '@reach/router'

import { useLocation } from 'common/location'
import { buildSubnavAndRoutes } from 'common/route-utils'

import useProjectData from './use-project-data'
import DocumentRoutes from './documents'
import ProjectSummary from './pages/project-summary'
import LabelListing from './pages/label-listing'
import ProjectSettings from './settings'
import NewProjectConfirmationModal from './components/new-project-confirmation-modal'

const ProjectSubnavAndRoutes = buildSubnavAndRoutes([
  { name: 'Summary', exact: true, url: '/', component: ProjectSummary },
  {
    name: 'Documents',
    url: 'documents/*',
    component: DocumentRoutes
  },
  {
    name: 'Labels',
    url: 'labels/*',
    component: LabelListing
  },
  { name: 'Settings', url: 'settings/*', component: ProjectSettings }
])
interface ConnectedProjectPageProps extends RouteComponentProps {
  projectId?: string
}

const ConnectedProjectPage = ({ projectId, navigate }: ConnectedProjectPageProps) => {
  const location = useLocation()
  const { data, loading, error } = useProjectData(projectId)

  if (loading) return <div>loading...</div>
  if (error) return <div>error</div>

  const { project } = data

  // NOTE: when instantiating app on project url (e.g. from widget's project.open_app()), location.state is undefined
  const newProjectId = get(location, 'state.newProjectId')
  const apiKey = get(location, 'state.apiKey')
  const isNewProjectConfirmationOpen = !!(newProjectId && apiKey)

  const handleCloseProjectConfirmation = () => {
    navigate('.', { state: {}, replace: true }) // clear location state so that we can dismiss modal
  }

  return (
    <>
      <NewProjectConfirmationModal
        apiKey={apiKey}
        newProjectId={newProjectId}
        isOpen={isNewProjectConfirmationOpen}
        onRequestClose={handleCloseProjectConfirmation}
      />

      <ProjectSubnavAndRoutes project={project} title={project.name} />
    </>
  )
}

export default ConnectedProjectPage
