import React, { FC } from 'react'
import Modal, { Props as RMProps } from 'react-modal'

try {
  Modal.setAppElement('#root')
} catch (err) {
  console.info(
    'Could not mount modal dialog to element #root, environment assumed to be Jupyter.'
  )
}

interface Props extends RMProps {
  modalStyle?: object
}

const ModalDialog: FC<Props> = ({ modalStyle = {}, ...props }) => (
  <Modal
    style={{
      content: {
        top: 120,
        width: 600,
        height: 500,
        left: '50%',
        transform: 'translate(-50%)',
        ...modalStyle
      }
    }}
    {...props}
  />
)

export default ModalDialog
