import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import get from 'lodash/get'

import { PageHeader } from 'common/components'
import Annotator from 'annotator/components/annotator'
import { ReactSelectField } from 'common/components/form'

const formatLabelForDisplay = label => ({
  value: label.id,
  label: `${label.creator.name} - ${label.createdAt}`,
  ...label
})

class DocumentPage extends React.Component<any, any> {
  state = { selectedLabel: null }

  static getDerivedStateFromProps (props, state) {
    const { document } = props
    if (document.labels.nodes.length === 1) {
      return {
        selectedLabel: formatLabelForDisplay(document.labels.nodes[0])
      }
    } else {
      return null
    }
  }

  handleLabelSelect = selectedLabel => this.setState({ selectedLabel })

  annotatorById = userId => {
    const { annotators } = this.props
    return annotators.find(a => a.id === userId)
  }

  get labelOptions () {
    const { document } = this.props
    return document.labels.nodes.map(formatLabelForDisplay)
  }

  renderDocumentCategory = categoryId => {
    const {
      project: { documentCategories }
    } = this.props
    const foundCategory = documentCategories.nodes.find(c => c.id === categoryId)

    if (!foundCategory) {
      // NOTE: this is not helpful if task type != classification
      // console.error('Could not find documentCategory with id', categoryId)
    } else {
      return <span className='pa2 bg-primary white'>{foundCategory.name}</span>
    }
  }

  render () {
    const { document, project } = this.props
    const { annotationCategories } = project
    const { selectedLabel } = this.state
    const annotations: any[] = get(selectedLabel, 'contents.annotations') || []

    return (
      <>
        <PageHeader title={`Viewing document: ${document.externalId}`} />

        <div className='flex'>
          <div className='flex-2'>
            <Annotator
              annotations={annotations}
              categories={annotationCategories.nodes}
              readOnly={true}
            >
              {document.contents}
            </Annotator>
          </div>

          <div className='flex-1 ml4'>
            <p className='mb4'>
              This document has been annotated {document.labels.nodes.length}{' '}
              {document.labels.nodes.length === 1 ? 'time' : 'times'}.
            </p>

            <ReactSelectField
              label='View annotations'
              name='classification'
              placeholder='Select classification'
              onChange={this.handleLabelSelect}
              value={selectedLabel}
              disabled={document.labels.nodes.length === 0}
              helperText='Select the user annotation to view labels overlaid on the document.'
              options={this.labelOptions}
              selectProps={{ isClearable: true }}
            />

            {selectedLabel && (
              <>
                <h4>Document category</h4>
                {this.renderDocumentCategory(selectedLabel.contents.categoryId)}
              </>
            )}
          </div>
        </div>
      </>
    )
  }
}

const DOCUMENT_QUERY = gql`
  query DocumentQuery($documentId: UUID!) {
    documentById(id: $documentId) {
      id
      externalId
      contents
      metadata
      labels {
        nodes {
          id
          creator {
            id
            name
          }
          contents
          createdAt
        }
      }
    }
  }
`

const ConnectedDocumentPage = ({ project, annotators, documentId }) => {
  const { data, loading, error } = useQuery(DOCUMENT_QUERY, {
    variables: { documentId }
  })

  if (error) {
    return <div>error...</div>
  }
  if (loading) {
    return <div>loading... </div>
  }

  return (
    <DocumentPage
      project={project}
      annotators={annotators}
      document={data.documentById}
    />
  )
}

export default ConnectedDocumentPage
