import React, { FC } from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
}

const ContentContainer: FC<Props> = ({ className = '', children }) => (
  <section className={classNames(['mw8 w8-l center', className])}>{children}</section>
)

export default ContentContainer
