import React, { ReactNode } from 'react'
import classNames from 'classnames'

interface FormGroupProps {
  title: string
  description?: string | React.ReactNode
  noBorder?: boolean
}

const FormGroup: React.FC<FormGroupProps> = ({
  title,
  description = '',
  noBorder = false,
  children
}) => {
  const classes = classNames([
    'flex flex-row pb3 mb3 mt4',
    !noBorder && 'bb bw1 b--light-gray'
  ])

  return (
    <div className={classes}>
      <div className='flex-1 mr3'>
        <h3 className='mt0'>{title}</h3>
        {description && <p className='lh-title'>{description}</p>}
      </div>
      <div className='flex-2 ml3'>{children}</div>
    </div>
  )
}

export default FormGroup
