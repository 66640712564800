import React from 'react'
import ProgressMeter from 'common/components/progress-meter'

const TaskProgressBar = props => {
  const { complete, total } = props
  const textSummary = (
    <>
      {complete} complete, {total - complete} remaining
    </>
  )
  return <ProgressMeter {...props} title='Progress' textSummary={textSummary} />
}

export default TaskProgressBar
