import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import tw from 'twin.macro'
import chroma from 'chroma-js'
import { usePopper } from 'react-popper'
import uuid from 'uuid/v4'

import { Button } from 'common/components'
import { ELEMENT_MAP } from './utils'
import AddAnnotationPopup from '../add-annotation-popup' // TODO: pass this in as props?

interface SelectedTextProps {
  children: any
}

const SelectedText = React.forwardRef(
  ({ children }: SelectedTextProps, ref: React.Ref<HTMLElement>) => (
    <mark
      ref={ref}
      // 0 padding is required to override jupyter notebook style
      tw='border-b-2 p-0'
      style={{ background: '#ffe8b7', borderBottomColor: '#ffc751' }}
    >
      {children}
    </mark>
  )
)

const Annotation = ({ children, category, onRemove, readOnly }) => (
  <mark
    tw='p-0 border-b-2 relative'
    css={{
      '& > .label': tw`hidden`,
      '&:hover > .label': tw`inline`
    }}
    style={{
      background: chroma(category.properties.color).brighten(0.2),
      borderColor: chroma(category.properties.color).darken(0.6)
    }}
  >
    {children}
    <Label value={category.name} onRemove={onRemove} readOnly={readOnly} />
  </mark>
)

const Label = ({ value, onRemove, readOnly }) => {
  return (
    <div
      className='label'
      tw='absolute left-0 p-1 text-sm leading-none h-5 whitespace-nowrap'
      style={{ top: -17, backgroundColor: 'inherit', color: 'inherit' }}
    >
      {value}
      {!readOnly && (
        <Button
          variant='tertiary-gray'
          tw='ml-2'
          onClick={onRemove}
          onMouseDown={e => e.stopPropagation()} // necessary to prevent xstate machine from transitioning and intercepting click event
        >
          x
        </Button>
      )}
    </div>
  )
}

const AnnotatedElement = ({
  type,
  id,
  value,
  annotations,
  categories,
  onAddAnnotation,
  onRemoveAnnotation,
  onCancelAnnotation,
  readOnly
}) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles } = usePopper(referenceElement, popperElement) // TODO: do we care about attributes from this?

  let { contents } = annotations
    .sort((a, b) => a.selector.offset - b.selector.offset)
    .reduce(
      ({ lastPos, contents }, annotation) => {
        if (annotation.selector.offset > lastPos) {
          contents = contents.concat(value.slice(lastPos, annotation.selector.offset))
        }

        const category =
          annotation.categoryId &&
          categories.find(cat => cat.id === annotation.categoryId)

        const annotatedText = value.slice(
          annotation.selector.offset,
          annotation.selector.offset + annotation.selector.length
        )

        contents = contents.concat(
          annotation.categoryId ? (
            <Annotation
              key={uuid()}
              category={category}
              onRemove={() => onRemoveAnnotation(annotation)}
              readOnly={readOnly}
            >
              {annotatedText}
            </Annotation>
          ) : (
            <React.Fragment key='pending'>
              <SelectedText ref={setReferenceElement}>
                {annotatedText}
              </SelectedText>
              {ReactDOM.createPortal(
                <AddAnnotationPopup
                  ref={setPopperElement}
                  style={{ ...styles.popper, zIndex: 2 }} // z-index to ensure labels don't appear over the popup
                  categories={categories}
                  onSave={onAddAnnotation}
                  onCancel={onCancelAnnotation}
                />,
                document.querySelector('#popup-portal') // TODO: pass a ref?
              )}
            </React.Fragment>
          )
        )

        lastPos = annotation.selector.offset + annotation.selector.length
        return { lastPos, contents }
      },
      { lastPos: 0, contents: [] }
    )

  const lastAnnotation = annotations[annotations.length - 1]
  contents = contents.concat(
    value.slice(lastAnnotation.selector.offset + lastAnnotation.selector.length)
  )

  const Tag = ELEMENT_MAP[type] || ELEMENT_MAP['unknown']

  return <Tag id={id}>{contents}</Tag>
}

export default AnnotatedElement
