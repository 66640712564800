import React, { FC } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { FlashError } from 'common/flash'
import { Button, ButtonContainer } from 'common/components'
import { InputField, FreeTextField } from 'common/components/form'
import { buildSubmitHandler } from 'common/form-helpers'

interface Props {
  onSubmit: (arg0: any) => void
  onCancel: (arg0: any) => void
  data?: {
    id: string
    name: string
    properties?: { color: string }
    description: string
  }
  formError?: string
}

const AnnotationCategoryForm: FC<Props> = ({
  onSubmit,
  data = {},
  formError,
  onCancel
}) => (
  <Formik
    initialValues={{
      name: data.name ?? '',
      color: data.properties?.color ?? '',
      description: data.description ?? ''
    }}
    validationSchema={annotationValidationSchema}
    onSubmit={buildSubmitHandler(onSubmit, { reshape: (values) => ({ ...values, id: data.id }) })}
  >
    {({ handleChange, touched, values, errors = {}, isSubmitting }) => (
      <Form>
        <h3 className='mt0'>{isEmpty(data) ? 'Add' : 'Edit'} annotation category</h3>
        {formError && <FlashError>{formError}</FlashError>}

        <InputField
          label='Name'
          name='name'
          value={values.name}
          onChange={handleChange}
          touched={touched.name}
          error={errors.name}
        />

        <InputField
          label='Color'
          name='color'
          value={values.color}
          onChange={handleChange}
          touched={touched.color}
          error={errors.color}
        />

        <FreeTextField
          label='Description'
          name='description'
          value={values.description}
          onChange={handleChange}
          touched={touched.description}
          error={errors.description}
        />

        <ButtonContainer>
          <Button variant='secondary-red' onClick={onCancel}>
            Cancel
          </Button>
          <Button type='submit' disabled={isSubmitting}>
            Save annotation category
          </Button>
        </ButtonContainer>
      </Form>
    )}
  </Formik>
)

const annotationValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  color: yup.string().required('Color is required.') // TODO: add hex color validator
})

export default AnnotationCategoryForm
