import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { useParams } from 'common/location'
import { Link } from 'common/components'

import Register from '../components/register'
import RegisterWithToken from '../components/register-with-token'

const RegistrationPage = ({ navigate }: RouteComponentProps) => {
  const { invite: inviteToken } = useParams()

  return (
    <>
      {!inviteToken ? (
        <Register navigate={navigate} />
      ) : (
        <RegisterWithToken inviteToken={inviteToken} navigate={navigate} />
      )}
      <p className='mt4'>
        Change your mind? Head back to <Link to='/'>the login page</Link>.
      </p>
    </>
  )
}

export default RegistrationPage
