import React from 'react'
import { distanceInWordsToNow, isPast, format } from 'date-fns'

const Datetime = ({ datetime, human = false }) =>
  human ? (
    <abbr title={format(datetime, 'dddd DD MMMM YYYY h:mma')}>
      {isPast(datetime) ? (
        <>{distanceInWordsToNow(datetime)} ago</>
      ) : (
        <>in {distanceInWordsToNow(datetime)}</>
      )}
    </abbr>
  ) : (
    <>{format(datetime, 'MMMM DD, YYYY')}</>
  )

export default Datetime
