import React, { FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useQuery, useMutation } from '@apollo/react-hooks'
import get from 'lodash/get'

import { useAuth } from 'auth'
import { Code, DataTable, Link, LinkButton, PageHeader, Badge } from 'common/components'
import { confirmAction } from 'common/utils'

import { WEBHOOK_LISTING_QUERY, DELETE_WEBHOOK_MUTATION } from '../queries'

interface Webhook {
  id: string
  payloadUrl: string
  status: string
  event: string
}

interface Props extends RouteComponentProps {
  project: any
}

const WebhooksIndex: FC<Props> = ({ project }) => {
  const { isAdmin } = useAuth()
  const { data, loading, error } = useQuery(WEBHOOK_LISTING_QUERY, {
    variables: { projectId: project.id }
  })
  const [deleteWebhook] = useMutation(DELETE_WEBHOOK_MUTATION, {
    refetchQueries: [
      { query: WEBHOOK_LISTING_QUERY, variables: { projectId: project.id } }
    ]
  })

  if (loading) {
    return <div>loading...</div>
  }
  if (error) {
    return <div>error</div>
  }

  const handleDeleteWebhook = async webhookId => {
    await deleteWebhook({ variables: { id: webhookId } })
  }

  const webhooks = data.webhooks.nodes.map(webhook => {
    const { webhookDeliveries, ...rest } = webhook
    const [latestDelivery] = webhookDeliveries.nodes
    const statusCode = get(latestDelivery, 'latestStatus')
    return {
      ...rest,
      status: !statusCode
        ? 'n/a'
        : statusCode >= 200 && statusCode < 300
        ? 'ok'
        : 'error'
    }
  })

  return (
    <>
      <PageHeader title='Existing webhooks' />

      <DataTable
        data={webhooks}
        columns={[
          {
            Header: 'Payload URL',
            accessor: ({ id, payloadUrl }: Webhook) => (
              <Link to={`./${id}`}>{payloadUrl}</Link>
            )
          },
          {
            Header: 'Status',
            accessor: ({ status }: Webhook) => (
              <Badge
                type={status === 'n/a' ? '' : status === 'error' ? 'error' : 'success'}
              >
                {status}
              </Badge>
            )
          },
          {
            Header: 'Event',
            accessor: ({ event }: Webhook) => <Code>{event}</Code>
          }
        ]}
        rowActions={
          isAdmin()
            ? [
                { component: ({ id }: any) => <Link to={`./${id}`}>Edit</Link> },
                {
                  label: 'Delete',
                  handler: ({ id }: Webhook) =>
                    confirmAction('Are you sure you want to delete this webhook?')(() =>
                      handleDeleteWebhook(id)
                    )
                }
              ]
            : []
        }
      />

      <div className='mt4 tr'>
        <LinkButton to='./new'>Create new webhook</LinkButton>
      </div>
    </>
  )
}

export default WebhooksIndex
