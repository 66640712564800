import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import maxBy from 'lodash/maxBy'
import classNames from 'classnames'

import {
  Table,
  TableBody,
  Row,
  Cell,
  TableHeader,
  HeaderCell,
  Link
} from 'common/components'
import { useLocation, useParams } from 'common/location'
import { updateQueryAndFetch } from 'common/route-utils'
import { LinkButton } from 'common/components'
import Tile from 'common/components/tile'
import ProgressBar from 'common/components/progress-bar'
import SlabStats from 'common/components/slab-stats'
import CopyToClipboard from 'common/components/copy-to-clipboard'
import Datetime from 'common/components/datetime'

import { IFilterParam } from '../components/filters'

import { PROJECT_SUMMARY_QUERY } from '../queries'
import { Percentage } from '../components/completion-percentage'
import ProjectSummaryFilters from '../components/summary-filters'
import ControlBar from '../components/control-bar'

interface FilterVariables {
  cycleId?: string
  cycleFilter?: { id: IFilterParam }
}

const buildVariables = params => {
  let filter: FilterVariables = {}

  if (params.cycle) {
    filter.cycleId = params.cycle
    filter.cycleFilter = { id: { equalTo: params.cycle } }
  }

  return filter
}

const ProjectSummary = ({ navigate, project }) => {
  const location = useLocation()
  const params = useParams()

  const variables = {
    projectId: project.id,
    ...buildVariables(params)
  }
  const { loading, error, data } = useQuery(PROJECT_SUMMARY_QUERY, { variables })

  if (loading) return <div>loading...</div>
  if (error) return <div>error</div>

  const projectSummary = data.projectById

  const {
    labelStats,
    taskStats,
    documentStats,
    documentCategories,
    annotationCategories
  } = projectSummary
  const { count: documentLabelMax } = maxBy(documentCategories.nodes, c => c.count) || {
    count: null
  }
  const { count: annotationLabelMax } = maxBy(
    annotationCategories.nodes,
    c => c.count
  ) || { count: null }

  const documentLabelTotal = documentCategories.nodes.reduce(
    (acc, dc) => acc + dc.count,
    0
  )
  const annotationLabelTotal = annotationCategories.nodes.reduce(
    (acc, dc) => acc + dc.count,
    0
  )

  const stats = {
    labels: {
      total: labelStats.total
    },
    tasks: {
      complete: taskStats.complete,
      skipped: taskStats.skipped,
      incomplete: taskStats.incomplete
    },
    documents: {
      labeled: documentStats.labeled,
      unassigned: documentStats.unassigned,
      total: documentStats.total
    }
  }

  // TODO-3
  // const SummaryLink = ({ to, className, children }) => (
  //   <Link to={
  //     params.cycle
  //       ? `labels?documentCategory=${label.id}&cycle=${params.cycle}`
  //       : `labels?documentCategory=${label.id}`
  //   }>{children}</Link>
  // )

  return (
    <>
      <ControlBar
        buttons={() => (
          <>
            {/* TODO: mr3 class */}
            <LinkButton to={`./annotate`}>Label</LinkButton>
            {/* TODO: mr3 class */}
            <LinkButton to={`./documents/upload`}>Upload documents</LinkButton>
          </>
        )}
        filters={() => (
          <ProjectSummaryFilters
            cycles={project.cycles.nodes.map(c => ({ id: c.id, name: c.id }))}
            query={params}
            onChange={filters => {
              const params = filters || {}
              updateQueryAndFetch(location, navigate, params)
            }}
          />
        )}
      />

      <Tile>
        <div className='flex flex-row items-center overflow-x-scroll'>
          <SlabStats title='Labels'>
            <SlabStats.Item name='Total'>
              <Link
                to={params.cycle ? `./labels?cycle=${params.cycle}` : './labels'}
                className='black no-underline underline-hover'
              >
                {Number(stats.labels.total).toLocaleString()}
              </Link>
            </SlabStats.Item>
          </SlabStats>

          <SlabStats title='Tasks'>
            <SlabStats.Item name='Complete'>
              {Number(stats.tasks.complete).toLocaleString()}
            </SlabStats.Item>
            <SlabStats.Item name='Skipped'>
              {Number(stats.tasks.skipped).toLocaleString()}
            </SlabStats.Item>
            <SlabStats.Item name='Incomplete'>
              {Number(stats.tasks.incomplete).toLocaleString()}
            </SlabStats.Item>
          </SlabStats>

          <SlabStats title='Documents' last>
            <SlabStats.Item name='Labeled'>
              {Number(stats.documents.labeled).toLocaleString()}
            </SlabStats.Item>
            <SlabStats.Item name='Unassigned'>
              {Number(stats.documents.unassigned).toLocaleString()}
            </SlabStats.Item>
            <SlabStats.Item name='Total'>
              {Number(stats.documents.total).toLocaleString()}
            </SlabStats.Item>
          </SlabStats>
        </div>
      </Tile>

      <div className='flex flex-row mv4'>
        {documentCategories.nodes.length > 0 && (
          <Tile
            className={classNames([
              'flex-1',
              annotationCategories.nodes.length > 0 && 'mr2'
            ])}
            title='Document labels'
            actions={() => (
              <Link to='./settings/classes' className='f6 no-underline underline-hover'>
                modify
              </Link>
            )}
          >
            <Table>
              <TableHeader>
                <HeaderCell noPadding>Name</HeaderCell>
                <HeaderCell className='tr'>Count</HeaderCell>
                <HeaderCell noPadding />
                <HeaderCell noPadding className='tr'>
                  Distribution
                </HeaderCell>
              </TableHeader>
              <TableBody>
                {documentCategories.nodes.map(label => (
                  <Row key={label.id}>
                    <Cell noPadding>
                      <Link
                        to={
                          params.cycle
                            ? `labels?documentCategory=${label.id}&cycle=${params.cycle}`
                            : `labels?documentCategory=${label.id}`
                        }
                      >
                        {label.name}
                      </Link>
                    </Cell>
                    <Cell className='tr'>{label.count}</Cell>
                    <Cell noPadding>
                      <small className='gray'>
                        {documentLabelTotal ? (
                          <Percentage count={label.count} total={documentLabelTotal} />
                        ) : null}
                      </small>
                    </Cell>
                    <Cell noPadding>
                      <ProgressBar percent={(label.count * 100) / documentLabelMax} />
                    </Cell>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </Tile>
        )}

        {annotationCategories.nodes.length > 0 && (
          <Tile
            className='flex-1 ml2'
            title='Annotation labels'
            actions={() => (
              <Link to='./settings/classes' className='f6 no-underline underline-hover'>
                modify
              </Link>
            )}
          >
            <Table>
              <TableHeader>
                <HeaderCell noPadding>Name</HeaderCell>
                <HeaderCell className='tr'>Count</HeaderCell>
                <HeaderCell noPadding />
                <HeaderCell noPadding className='tr'>
                  Distribution
                </HeaderCell>
              </TableHeader>
              <TableBody>
                {annotationCategories.nodes.map(label => (
                  <Row key={label.id}>
                    <Cell noPadding>
                      <Link to={`labels?annotationCategory=${label.id}`}>
                        {label.name}
                      </Link>
                    </Cell>
                    <Cell className='tr'>{label.count}</Cell>
                    <Cell noPadding>
                      <small className='gray'>
                        (<Percentage count={label.count} total={annotationLabelTotal} />
                        )
                      </small>
                    </Cell>
                    <Cell noPadding>
                      <ProgressBar percent={(label.count * 100) / annotationLabelMax} />
                    </Cell>
                  </Row>
                ))}
              </TableBody>
            </Table>
          </Tile>
        )}
      </div>

      <Tile title='Label cycles' className='mb4'>
        <Table>
          <TableHeader>
            <HeaderCell noPadding>ID</HeaderCell>
            <HeaderCell>Created</HeaderCell>
            <HeaderCell className='tr'>Sample Size</HeaderCell>
            <HeaderCell className='tr'>Labels</HeaderCell>
            <HeaderCell className='tr'>Tasks Total / Incomplete</HeaderCell>
            <HeaderCell noPadding className='tr'>
              Actions
            </HeaderCell>
          </TableHeader>
          <TableBody>
            {projectSummary.cycles.nodes.map(cycle => (
              <Row key={cycle.id}>
                <Cell noPadding>
                  <pre className='f6 dib ma0'>{cycle.id}</pre>
                  <CopyToClipboard value={cycle.id} />
                </Cell>
                <Cell>
                  <Datetime human datetime={cycle.createdAt} />
                </Cell>
                <Cell className='tr'>{cycle.documentCount}</Cell>
                <Cell className='tr'>{cycle.labelCount}</Cell>
                <Cell className='tr'>
                  {cycle.tasks.totalCount} <span className='silver'>/</span>{' '}
                  {cycle.incompleteTasks.totalCount}
                </Cell>
                <Cell noPadding className='tr'>
                  <Link to={`./annotate?cycle=${cycle.id}`}>Label</Link>
                </Cell>
              </Row>
            ))}
          </TableBody>
        </Table>
      </Tile>
    </>
  )
}

export default ProjectSummary
